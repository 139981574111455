import { ProtectedRestApi } from "./";
import { Client, ServiceResult } from "../models";
import { ClientCreatePayload, ClientUpdatePayload } from "../payloads";

export class ClientApi extends ProtectedRestApi {
    private static baseUrl = "/api/client";

    public getAll(): Promise<ServiceResult<Client[]>> {
        return this.httpGet(`${ClientApi.baseUrl}/all`) as Promise<ServiceResult<Client[]>>;
    }

    public create(payload: ClientCreatePayload): Promise<ServiceResult<Client>> {
        return this.httpPut(`${ClientApi.baseUrl}`, payload) as Promise<ServiceResult<Client>>;
    }

    public update(id: number, payload: ClientUpdatePayload): Promise<ServiceResult<Client>> {
        return this.httpPost(`${ClientApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<Client>>;
    }

    public delete(id: number): Promise<ServiceResult<number>> {
        return this.httpDelete(`${ClientApi.baseUrl}/${id}`) as Promise<ServiceResult<number>>;
    }
}
