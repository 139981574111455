import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingIndicator, ToastContainer } from "./common";
import { CopierCounterStatementCreatePayload } from '../payloads';
import CopierCounterStatementCard from "./CopierCounterStatementCard";
import { getIndicatorClassFromStatement, getMonthNameFromMonthNumber } from '../utils';
import { CopierCounterStatement, CopierCounterStatementTab } from "../models";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

type CopierCounterStatementsPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface CopierCounterStatementsState {
    result: boolean | null;
    toastOpen: boolean;
    expandedIndexAnnounced: number;
    expandedIndexNotAnnounced: number;
    expandedIndexCurrentMonth: number;
    showAll: boolean;
    showAllSet: boolean;
    yearDropdownOpen: boolean;
    monthDropdownOpen: boolean;
    year: number;
    month: number;
}

class CopierCounterStatements extends React.PureComponent<CopierCounterStatementsPropsType, CopierCounterStatementsState> {
    constructor(props: CopierCounterStatementsPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            expandedIndexAnnounced: NaN,
            expandedIndexNotAnnounced: NaN,
            expandedIndexCurrentMonth: NaN,
            showAll: false,
            showAllSet: false,
            yearDropdownOpen: false,
            monthDropdownOpen: false,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1
        }
    }

    componentWillMount() {
        this.props.getAllCopierContracts();
    }

    componentWillReceiveProps(nextProps: CopierCounterStatementsPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }

        if (!this.state.showAllSet && nextProps.loggedInUser != null && nextProps.technicians.length > 0) {
            const technician = nextProps.technicians.find(x => x.user.id === nextProps.loggedInUser!!.id);
            if (technician != null) {
                this.setState({
                    showAll: technician.displayItemsForAllTechnicians,
                    showAllSet: true
                });

                this.props.getAllCopierCounterStatements(!technician.displayItemsForAllTechnicians);
                this.props.getNotAnnouncedCopierCounterStatements(!technician.displayItemsForAllTechnicians);
            }
        }
    }

    public render() {
        const allStatements = this.props.copierCounterStatements.concat(this.props.notAnnouncedCopierCounterStatements);
        const announcedStatements = this.filterStatements(this.props.copierCounterStatements);
        const notAnnouncedStatements = this.filterStatements(this.props.notAnnouncedCopierCounterStatements);
        const currentMonthStatements = announcedStatements
            .concat(notAnnouncedStatements)
            .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
            .filter(x => new Date(x.createdAt).getFullYear() === this.state.year && new Date(x.createdAt).getMonth() + 1 === this.state.month);

        const years = [...new Set(allStatements.map(statement => new Date(statement.createdAt).getFullYear()))];
        const months = [...new Set(allStatements.filter(statement => new Date(statement.createdAt).getFullYear() === this.state.year).map(statement => new Date(statement.createdAt).getMonth() + 1))];

        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><FontAwesomeIcon icon={["fas", "calculator"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Számlálók</span></h4>

                <div className="d-flex justify-content-between align-items-center mt-4 mb-4">
                    <div className="d-flex justify-content-start">
                        <div className="form-group form-inline my-2">
                            <Dropdown isOpen={this.state.yearDropdownOpen} toggle={this.toggleYearDropdown}>
                                <DropdownToggle className="lansoft-success" caret>
                                    <span className="mr-2">{isNaN(this.state.year) ? "Év" : this.state.year}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {years.sort().map((year, index) =>
                                        <DropdownItem active={this.state.year === year} onClick={() => this.handleYearChange(year)} key={`year-dropdown-item-${index}`}>{year}</DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="form-group form-inline ml-3 my-2">
                            <Dropdown isOpen={this.state.monthDropdownOpen} toggle={this.toggleMonthDropdown}>
                                <DropdownToggle className="lansoft-success w-120-px" caret>
                                    <span className="mr-2">{isNaN(this.state.month) ? "Hónap" : getMonthNameFromMonthNumber(this.state.month)}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {months.sort().map((month, index) =>
                                        <DropdownItem active={this.state.month === month} onClick={() => this.handleMonthChange(month)} key={`month-dropdown-item-${index}`}>{getMonthNameFromMonthNumber(month)}</DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    <div>
                        <div className="form-check mt-3">
                            <input type="checkbox" className="form-check-input" id="inputShowAll" checked={this.state.showAll} onChange={this.handleShowAllChange} />
                            <label className="form-check-label" htmlFor="inputShowAll">Az összes technikus számlálóinak listázása</label>
                        </div>
                    </div>
                </div>

                <div className="tabbable full-width-tabs tickets-tabs">
                    <ul className="nav nav-tabs mb-3 d-flex justify-content-start align-items-center" id="statements-tab-list" role="tablist">
                        <li className="d-flex">
                            <a className="nav-link active" id="announced-tab" data-toggle="pill" href="#announced-statements" role="tab" aria-controls="announced" aria-selected="true">Bejelentett ({announcedStatements.length})</a>
                        </li>
                        <li className="d-flex">
                            <a className="nav-link" id="not-announced-tab" data-toggle="pill" href="#not-announced-statements" role="tab" aria-controls="not-announced" aria-selected="false">Nem bejelentett ({notAnnouncedStatements.length})</a>
                        </li>
                        <li className="d-flex">
                            <a className="nav-link" id="all-tab" data-toggle="pill" href="#all-statements" role="tab" aria-controls="all" aria-selected="false">Minden számláló ({currentMonthStatements.length})</a>
                        </li>
                    </ul>
                </div>
                {this.props.copierContracts.length > 0 && <div className="tab-content" id="tickets-tab-content">
                    <div className="tab-pane fade show active" id="announced-statements" role="tabpanel" aria-labelledby="announced-statements-tab">
                        <ul className="list-group mt-3">
                            {announcedStatements.length === 0 &&
                                <div className="d-flex justify-content-center text-white font-italic mt-4">
                                    <span>Nem található számláló.</span>
                                </div>
                            }
                            {this.props.copierContracts.length > 0 && announcedStatements.map((statement, index) =>
                                <li className="list-group-item-container" key={`statement-list-item-${index}-${statement.contractId}-${statement.id}`}>
                                    <div className={`list-group-item list-margin diagonal-background diagonal-background-${getIndicatorClassFromStatement(statement)}`}>
                                        <CopierCounterStatementCard
                                            contract={this.props.copierContracts.find(x => x.id === statement.contractId)!!}
                                            statement={statement}
                                            expandCollapse={() => this.handleAnnouncedCardExpandCollapseClick(index)}
                                            isExpanded={this.state.expandedIndexAnnounced === index}
                                            createInvoice={file => this.props.createCopierInvoice(statement.contractId, statement.id, file)}
                                            createStatement={payload => this.createStatement(statement.contractId, payload)}
                                            tab={CopierCounterStatementTab.Announced}
                                        />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="not-announced-statements" role="tabpanel" aria-labelledby="not-announced-statements-tab">
                        <ul className="list-group mt-3">
                            {notAnnouncedStatements.length === 0 &&
                                <div className="d-flex justify-content-center text-white font-italic mt-4">
                                    <span>Nem található számláló.</span>
                                </div>
                            }
                            {this.props.copierContracts.length > 0 && notAnnouncedStatements.map((statement, index) =>
                                <li className="list-group-item-container" key={`statement-list-item-${index}-${statement.contractId}-${statement.id}`}>
                                    <div className={`list-group-item list-margin diagonal-background diagonal-background-${getIndicatorClassFromStatement(statement)}`}>
                                        <CopierCounterStatementCard
                                            contract={this.props.copierContracts.find(x => x.id === statement.contractId)!!}
                                            statement={statement}
                                            expandCollapse={() => this.handleNotAnnouncedCardExpandCollapseClick(index)}
                                            isExpanded={this.state.expandedIndexNotAnnounced === index}
                                            createInvoice={file => this.props.createCopierInvoice(statement.contractId, statement.id, file)}
                                            createStatement={payload => this.createStatement(statement.contractId, payload)}
                                            tab={CopierCounterStatementTab.NotAnnounced}
                                        />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="all-statements" role="tabpanel" aria-labelledby="all-statements-tab">
                        <ul className="list-group mt-3">
                            {currentMonthStatements.length === 0 &&
                                <div className="d-flex justify-content-center text-white font-italic mt-4">
                                    <span>Nem található számláló.</span>
                                </div>
                            }
                            {this.props.copierContracts.length > 0 && currentMonthStatements.map((statement, index) =>
                                <li className="list-group-item-container" key={`statement-list-item-${index}-${statement.contractId}-${statement.id}`}>
                                    <div className={`list-group-item list-margin diagonal-background diagonal-background-${getIndicatorClassFromStatement(statement)}`}>
                                        <CopierCounterStatementCard
                                            contract={this.props.copierContracts.find(x => x.id === statement.contractId)!!}
                                            statement={statement}
                                            expandCollapse={() => this.handleCurrentMonthCardExpandCollapseClick(index)}
                                            isExpanded={this.state.expandedIndexCurrentMonth === index}
                                            createInvoice={file => this.props.createCopierInvoice(statement.contractId, statement.id, file)}
                                            createStatement={payload => this.createStatement(statement.contractId, payload)}
                                            tab={CopierCounterStatementTab.CurrentMonth}
                                        />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>}

                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private filterStatements = (statements: CopierCounterStatement[]) => {
        return statements.filter(x => {
            const date = new Date(x.createdAt);
            return date.getFullYear() === this.state.year && date.getMonth() + 1 === this.state.month;
        });
    }

    private toggleYearDropdown = () => {
        this.setState({
            yearDropdownOpen: !this.state.yearDropdownOpen
        });
    }

    private toggleMonthDropdown = () => {
        this.setState({
            monthDropdownOpen: !this.state.monthDropdownOpen
        });
    }

    private handleYearChange = (year: number) => {
        const allStatements = this.props.copierCounterStatements.concat(this.props.notAnnouncedCopierCounterStatements);
        const months = [...new Set(allStatements.filter(statement => new Date(statement.createdAt).getFullYear() === year).map(statement => new Date(statement.createdAt).getMonth() + 1))];
        this.setState({
            year: year,
            month: months.length > 0 ? months[0] : this.state.month
        });
    }

    private handleMonthChange = (month: number) => {
        this.setState({
            month: month
        });
    }

    private createStatement = (contractId: number, payload: CopierCounterStatementCreatePayload) => {
        this.props.createCopierCounterStatement(contractId, payload);
    }

    private handleShowAllChange = (e: any) => {
        this.setState({
            showAll: e.target.checked
        }, () => {
            this.props.getAllCopierCounterStatements(!this.state.showAll);
            this.props.getNotAnnouncedCopierCounterStatements(!this.state.showAll);
        });
    }

    private handleAnnouncedCardExpandCollapseClick = (index: number) => {
        this.setState({
            expandedIndexAnnounced: this.state.expandedIndexAnnounced === index ? NaN : index
        });
    }

    private handleNotAnnouncedCardExpandCollapseClick = (index: number) => {
        this.setState({
            expandedIndexNotAnnounced: this.state.expandedIndexNotAnnounced === index ? NaN : index
        });
    }

    private handleCurrentMonthCardExpandCollapseClick = (index: number) => {
        this.setState({
            expandedIndexCurrentMonth: this.state.expandedIndexCurrentMonth === index ? NaN : index
        });
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(CopierCounterStatements as any);
