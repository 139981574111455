import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import NavMenuTechnician from './NavMenuTechnician';
import authService from '../components/api-authorization/AuthorizeService';
import CookieConsent from "react-cookie-consent";

type LayoutPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>
    & { children?: React.ReactNode };

class Layout extends React.PureComponent<LayoutPropsType, {}> {
    componentWillMount() {
        authService.isAuthenticated().then(authenticated => {
            if (authenticated) {
                this.props.getLoggedInUser();
            }
        });
    }

    render() {
        const user = this.props.loggedInUser;
        const navMenu = user == null ? null : (
            user.isAdmin || user.isTechnician ? <NavMenuTechnician />
                : <NavMenu />
        );

        return (
            <React.Fragment>
                {navMenu}
                <Container>
                    {this.props.children}
                </Container>
                <CookieConsent
                    buttonText="Rendben"
                    style={{ backgroundColor: "rgba(0, 173, 239, 0.8)", marginBottom: "80px" }}
                    buttonStyle={{ backgroundColor: "rgb(41, 221, 209)", color: "white" }}
                >
                    A weboldal használatával beleegyezem a sütik (cookie-k) használatához a felhasználói élmény fokozása érdekében.
                </CookieConsent>
                <footer className="footer border-top text-muted">
                    <div className="container d-flex flex-column justify-content-center align-items-center h-100">
                        <div>&copy; 2021 Lansoft Trade Informatikai Kft. | <a href="https://www.lansoft.hu">www.lansoft.hu</a> | <a href="mailto:mail@lansoft.hu">mail@lansoft.hu</a></div>
                        <div>4025 Debrecen, Külső vásártér 18. | Tel.: +36 52 502-490 | Fax: +36 52 502-492</div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(Layout as any);
