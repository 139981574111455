import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingIndicator, ToastContainer } from "./common";
import { CopierCounterStatementCreatePayload } from "../payloads";
import CopierContractCard from "./CopierContractCard";

type ClientDashboardPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface ClientDashboardState {
    result: boolean | null;
    toastOpen: boolean;
    validationErrors: string[];
    expandedIndex: number;
}

class ClientDashboard extends React.PureComponent<ClientDashboardPropsType, ClientDashboardState> {
    constructor(props: ClientDashboardPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            validationErrors: [],
            expandedIndex: NaN
        }
    }

    componentWillMount() {
        this.props.getMyCopierContracts();
        this.props.getAllClients();
        this.props.getAllTechnicians();
        this.props.getAllCopierTicketTypes();
    }

    componentWillReceiveProps(nextProps: ClientDashboardPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }
    }

    public render() {
        const client = this.props.loggedInUser == null ? null : this.props.clients.find(x => x.users.filter(y => y.id === this.props.loggedInUser!!.id).length > 0);

        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <div className="d-flex justify-content-between my-4">
                    <div>
                        {client != null && <div>
                            <div className="h2">{client.name}</div>
                            <div className="h3">{client.address}</div>
                        </div>}</div>
                    <div className="p-4 border lansoft-border-success">
                        <div>Rendszerüzenetek: 0</div>
                        <div>Aktív hibajegyek: 0</div>
                    </div>
                </div>
                {this.props.copierContracts.length > 0 &&
                    <React.Fragment>
                        <h4 className="mb-4">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={["fas", "file-contract"]} className="mr-2" />
                                <div className="h5 lansoft-underline-success pb-1 mb-0 width-fit-content">
                                    Másolástechnikai szerződések ({this.props.copierContracts.length})
                                </div>
                            </div>
                        </h4>

                        <ul className="list-group mt-3">
                            {this.props.copierContracts.map((contract, index) =>
                                <li className="list-group-item-container" key={`copier-contract-list-item-${index}`}>
                                    <div className="list-group-item list-margin diagonal-background diagonal-background-lansoft-primary">
                                        <CopierContractCard
                                            contract={contract}
                                            createStatement={payload => this.createStatement(contract.id, payload)}
                                            createSupportTicket={payload => this.props.createCopierSupportTicket(contract.id, payload)}
                                            expandCollapse={() => this.handleCardExpandCollapseClick(index)}
                                            isExpanded={this.state.expandedIndex === index}
                                            ticketTypes={this.props.copierTicketTypes}
                                        />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </React.Fragment>
                }

                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private handleCardExpandCollapseClick = (index: number) => {
        this.setState({
            expandedIndex: this.state.expandedIndex === index ? NaN : index
        });
    }

    private createStatement = (contractId: number, payload: CopierCounterStatementCreatePayload) => {
        this.props.createCopierCounterStatement(contractId, payload);
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(ClientDashboard as any);
