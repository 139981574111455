import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { LoadingIndicator, ToastContainer } from "./common";
import { ClientCreatePayload, ClientUpdatePayload, PayloadUserItem } from "../payloads";
import ClientItem from "./ClientItem";

type ClientsPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface ClientsState {
    result: boolean | null;
    toastOpen: boolean;
    editIndex: number;
    deleteIndex: number;
    validationErrors: string[];
    clientId: number;
    clientName: string;
    clientAddress: string;
    clientUser1Id: number;
    clientEmail1: string;
    clientPassword1: string;
    clientUser2Id: number;
    clientEmail2: string;
    clientPassword2: string;
}

class Clients extends React.PureComponent<ClientsPropsType, ClientsState> {
    constructor(props: ClientsPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            editIndex: NaN,
            deleteIndex: NaN,
            validationErrors: [],
            clientId: 0,
            clientName: "",
            clientAddress: "",
            clientUser1Id: 0,
            clientEmail1: "",
            clientPassword1: "",
            clientUser2Id: 0,
            clientEmail2: "",
            clientPassword2: ""
        }
    }

    componentWillMount() {
        this.props.getAllClients();
    }

    componentWillReceiveProps(nextProps: ClientsPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><FontAwesomeIcon icon={["fas", "users"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Ügyfelek {`(${this.props.clients.length})`}</span></h4>

                <div className="d-flex justify-content-end">
                    <Button onClick={this.handleCreateClientClick} color="success" className="lansoft-success">
                        <FontAwesomeIcon icon={["fas", "plus"]} /> Új ügyfél
                    </Button>
                </div>

                <ul className="list-group mt-3 diagonal-background-flat diagonal-background-lansoft-primary position-relative no-left-border-radius">
                    {this.props.clients.map((client, index) =>
                        <li className="list-group-item" key={`client-list-item-${index}`}>
                            <ClientItem
                                client={client}
                                editClient={() => this.handleEditClientClick(index)}
                                deleteClient={() => this.handleDeleteClientClick(index)}
                            />
                        </li>
                    )}
                </ul>

                {this.getEditModal()}
                {this.getDeleteModal()}
                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editIndex) && !this.props.loading && this.props.clients.length > this.state.editIndex} toggle={this.closeEditModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeEditModal}>Ügyfél {this.state.editIndex === -1 ? "létrehozása" : "szerkesztése"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputClientName">Név</label>
                        <input type="text" className="form-control" id="inputClientName" autoComplete="off" placeholder="Név" value={this.state.clientName} onChange={this.handleClientNameChange} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputClientAddress">Cím</label>
                        <input type="text" className="form-control" id="inputClientAddress" autoComplete="off" placeholder="Cím" value={this.state.clientAddress} onChange={this.handleClientAddressChange} />
                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="inputClientEmail1">Felhasználó 1 e-mail címe</label>
                        <input type="text" className="form-control" id="inputClientEmail1" autoComplete="off" placeholder="E-mail cím" value={this.state.clientEmail1} onChange={this.handleClientEmail1Change} />
                    </div>

                    {this.state.editIndex === -1 &&
                        <div className="form-group mt-2">
                            <label htmlFor="inputClientPassword1">Felhasználó 1 jelszava</label>
                            <input type="password" className="form-control" id="inputClientPassword1" autoComplete="off" placeholder="Jelszó" value={this.state.clientPassword1} onChange={this.handleClientPassword1Change} />
                        </div>
                    }

                    <div className="form-group mt-2">
                        <label htmlFor="inputClientEmail2">Felhasználó 2 e-mail címe</label>
                        <input type="text" className="form-control" id="inputClientEmail2" autoComplete="off" placeholder="E-mail cím" value={this.state.clientEmail2} onChange={this.handleClientEmail2Change} />
                    </div>

                    {this.state.editIndex === -1 &&
                        <div className="form-group mt-2">
                            <label htmlFor="inputClientPassword2">Felhasználó 2 jelszava</label>
                            <input type="password" className="form-control" id="inputClientPassword2" autoComplete="off" placeholder="Jelszó" value={this.state.clientPassword2} onChange={this.handleClientPassword2Change} />
                        </div>
                    }

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger text-white lansoft-danger">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`client-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveClientClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteIndex) && !this.props.loading} toggle={this.closeDeleteModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeDeleteModal}>Ügyfél törlése</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan törölni szeretnéd az alábbi ügyfelet?
                        </p>
                        <p>{!isNaN(this.state.deleteIndex) && <strong>{this.props.clients[this.state.deleteIndex].name}</strong>}</p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmDeleteClientClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleCancelEditClick = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleSaveClientClick = () => {
        if (this.state.clientId === 0) {
            const users: PayloadUserItem[] = [{
                name: this.state.clientName,
                email: this.state.clientEmail1,
                password: this.state.clientPassword1
            }];

            if (this.state.clientEmail2.length > 0) {
                users.push({
                    name: this.state.clientName,
                    email: this.state.clientEmail2,
                    password: this.state.clientPassword2
                });
            }

            const payload: ClientCreatePayload = {
                name: this.state.clientName,
                address: this.state.clientAddress,
                users: users
            };

            this.props.createClient(payload);
        } else {
            const users: PayloadUserItem[] = [{
                id: this.state.clientUser1Id,
                name: this.state.clientName,
                email: this.state.clientEmail1,
                password: this.state.clientPassword1
            }];

            if (this.state.clientEmail2.length > 0) {
                users.push({
                    id: this.state.clientUser2Id,
                    name: this.state.clientName,
                    email: this.state.clientEmail2,
                    password: this.state.clientPassword2
                });
            }

            const payload: ClientUpdatePayload = {
                name: this.state.clientName,
                address: this.state.clientAddress,
                users: users
            };

            this.props.updateClient(this.state.clientId, payload);
        }

        this.setState({
            editIndex: NaN
        });
    }

    private handleConfirmDeleteClientClick = () => {
        const client = this.props.clients[this.state.deleteIndex];
        this.props.deleteClient(client.id);
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleClientNameChange = (e: any) => {
        this.setState({
            clientName: e.target.value
        }, () => { this.validate(true); });
    }

    private handleClientAddressChange = (e: any) => {
        this.setState({
            clientAddress: e.target.value
        }, () => { this.validate(true); });
    }

    private handleClientEmail1Change = (e: any) => {
        this.setState({
            clientEmail1: e.target.value
        }, () => { this.validate(true); });
    }

    private handleClientPassword1Change = (e: any) => {
        this.setState({
            clientPassword1: e.target.value
        }, () => { this.validate(true); });
    }

    private handleClientEmail2Change = (e: any) => {
        this.setState({
            clientEmail2: e.target.value
        }, () => { this.validate(true); });
    }

    private handleClientPassword2Change = (e: any) => {
        this.setState({
            clientPassword2: e.target.value
        }, () => { this.validate(true); });
    }

    private closeEditModal = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private closeDeleteModal = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCreateClientClick = () => {
        this.setState({
            editIndex: -1,
            clientId: 0,
            clientName: "",
            clientAddress: "",
            clientUser1Id: 0,
            clientEmail1: "",
            clientPassword1: "",
            clientUser2Id: 0,
            clientEmail2: "",
            clientPassword2: "",
            validationErrors: []
        });
    }

    private handleEditClientClick = (index: number) => {
        const client = this.props.clients[index];
        const user1 = client.users.length > 0 ? client.users[0] : null;
        const user2 = client.users.length > 1 ? client.users[1] : null;

        this.setState({
            editIndex: index,
            clientId: client.id,
            clientName: client.name,
            clientAddress: client.address,
            clientUser1Id: user1 != null ? user1.id : 0,
            clientEmail1: user1 != null ? user1.email : "",
            clientPassword1: "",
            clientUser2Id: user2 != null ? user2.id : 0,
            clientEmail2: user2 != null ? user2.email : "",
            clientPassword2: ""
        }, () => this.validate(true));
    }

    private handleDeleteClientClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.clientName.length === 0) {
            validationErrors.push("A név megadása kötelező.");
        }

        if (this.state.clientAddress.length === 0) {
            validationErrors.push("A cím megadása kötelező.");
        }

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(this.state.clientEmail1.toLowerCase())) {
            validationErrors.push("Érvényes e-mail cím megadása kötelező.");
        }

        if (this.state.clientEmail2.length > 0 && !re.test(this.state.clientEmail2.toLowerCase())) {
            validationErrors.push("Érvényes e-mail cím megadása kötelező.");
        }

        if (this.state.editIndex === -1) {
            const passwords = [this.state.clientPassword1];
            if (this.state.clientEmail2.length > 0) {
                passwords.push(this.state.clientPassword2);
            }

            for (const password of passwords) {
                if (password.length < 6) {
                    validationErrors.push("A jelszónak legalább 6 karakter hosszúnak kell lennie.");
                }

                if (!/[A-Z]/.test(password)) {
                    validationErrors.push("A jelszónak kötelező nagybetűt tartalmaznia.");
                }

                if (!/[a-z]/.test(password)) {
                    validationErrors.push("A jelszónak kötelező kisbetűt tartalmaznia.");
                }

                if (!/[0-9]/.test(password)) {
                    validationErrors.push("A jelszónak kötelező számjegyet tartalmaznia.");
                }
            }
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(Clients as any);
