import { ProtectedRestApi } from "./";
import { CopierDevice, ServiceResult } from "../models";
import { CopierDeviceCreatePayload, CopierDeviceUpdatePayload } from "../payloads";

export class CopierDeviceApi extends ProtectedRestApi {
    private static baseUrl = "/api/copier-device";

    public getAll(): Promise<ServiceResult<CopierDevice[]>> {
        return this.httpGet(`${CopierDeviceApi.baseUrl}/all`) as Promise<ServiceResult<CopierDevice[]>>;
    }

    public create(payload: CopierDeviceCreatePayload): Promise<ServiceResult<CopierDevice>> {
        return this.httpPut(`${CopierDeviceApi.baseUrl}`, payload) as Promise<ServiceResult<CopierDevice>>;
    }

    public update(id: number, payload: CopierDeviceUpdatePayload): Promise<ServiceResult<CopierDevice>> {
        return this.httpPost(`${CopierDeviceApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<CopierDevice>>;
    }

    public delete(id: number): Promise<ServiceResult<number>> {
        return this.httpDelete(`${CopierDeviceApi.baseUrl}/${id}`) as Promise<ServiceResult<number>>;
    }
}
