import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { LoadingIndicator, ToastContainer } from "./common";
import { CopierDeviceCreatePayload, CopierDeviceUpdatePayload } from "../payloads";
import CopierDeviceItem from "./CopierDeviceItem";

type CopierDevicesPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface CopierDevicesState {
    result: boolean | null;
    toastOpen: boolean;
    copierModelDropdownOpen: boolean;
    editIndex: number;
    deleteIndex: number;
    validationErrors: string[];
    copierDeviceId: number;
    copierDeviceModelId: number;
    copierDeviceSerialNumber: string;
}

class CopierDevices extends React.PureComponent<CopierDevicesPropsType, CopierDevicesState> {
    constructor(props: CopierDevicesPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            copierModelDropdownOpen: false,
            editIndex: NaN,
            deleteIndex: NaN,
            validationErrors: [],
            copierDeviceId: 0,
            copierDeviceModelId: 0,
            copierDeviceSerialNumber: "",
        }
    }

    componentWillMount() {
        this.props.getAllCopierModels();
        this.props.getAllCopierDevices();
    }

    componentWillReceiveProps(nextProps: CopierDevicesPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><img src="icons/printer-turquoise.svg" className="nav-menu-custom-icon" /> <span className="lansoft-underline-success">Másolástechnikai gépek {`(${this.props.copierDevices.length})`}</span></h4>

                <div className="d-flex justify-content-end">
                    <Button onClick={this.handleCreateCopierDeviceClick} color="success" className="lansoft-success">
                        <FontAwesomeIcon icon={["fas", "plus"]} /> Új gép
                    </Button>
                </div>

                <ul className="list-group mt-3 diagonal-background-flat diagonal-background-lansoft-primary position-relative no-left-border-radius">
                    {this.props.copierDevices.map((copierDevice, index) =>
                        <li className="list-group-item" key={`copier-device-list-item-${index}`}>
                            <CopierDeviceItem
                                device={copierDevice}
                                editCopierDevice={() => this.handleEditCopierDeviceClick(index)}
                                deleteCopierDevice={() => this.handleDeleteCopierDeviceClick(index)}
                            />
                        </li>
                    )}
                </ul>

                {this.getEditModal()}
                {this.getDeleteModal()}
                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editIndex) && !this.props.loading && this.props.copierDevices.length > this.state.editIndex} toggle={this.closeEditModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeEditModal}>Másolástechnikai gép {this.state.editIndex === -1 ? "létrehozása" : "szerkesztése"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputCopierDeviceSerialNumber">Gyári szám</label>
                        <input type="text" className="form-control" id="inputCopierDeviceSerialNumber" autoComplete="off" placeholder="Gyári szám" value={this.state.copierDeviceSerialNumber} onChange={this.handleCopierDeviceSerialNumberChange} />
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Típus</label>
                        <Dropdown isOpen={this.state.copierModelDropdownOpen} toggle={this.toggleCopierModelDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.copierDeviceModelId === 0 ? "Válassz" : this.props.copierModels.find(x => x.id === this.state.copierDeviceModelId)!!.name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {this.props.copierModels.map((model, index) =>
                                    <DropdownItem active={this.state.copierDeviceModelId === model.id} onClick={() => this.handleCopierDeviceModelChange(model.id)} key={`copier-model-dropdown-item-${index}`}>{model.name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`copierDevice-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveCopierDeviceClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteIndex) && !this.props.loading} toggle={this.closeDeleteModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeDeleteModal}>Másolástechnikai gép törlése</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan törölni szeretnéd az alábbi másolástechnikai gépet?
                        </p>
                        <p>{!isNaN(this.state.deleteIndex) && <strong>{this.props.copierDevices[this.state.deleteIndex].serialNumber}</strong>}</p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmDeleteCopierDeviceClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private toggleCopierModelDropdown = () => {
        this.setState({
            copierModelDropdownOpen: !this.state.copierModelDropdownOpen
        });
    }

    private handleCancelEditClick = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleSaveCopierDeviceClick = () => {
        if (this.state.copierDeviceId === 0) {
            const payload: CopierDeviceCreatePayload = {
                serialNumber: this.state.copierDeviceSerialNumber,
                modelId: this.state.copierDeviceModelId
            };

            this.props.createCopierDevice(payload);
        } else {
            const payload: CopierDeviceUpdatePayload = {
                serialNumber: this.state.copierDeviceSerialNumber,
                modelId: this.state.copierDeviceModelId
            };

            this.props.updateCopierDevice(this.state.copierDeviceId, payload);
        }

        this.setState({
            editIndex: NaN
        });
    }

    private handleConfirmDeleteCopierDeviceClick = () => {
        const copierDevice = this.props.copierDevices[this.state.deleteIndex];
        this.props.deleteCopierDevice(copierDevice.id);
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCopierDeviceSerialNumberChange = (e: any) => {
        this.setState({
            copierDeviceSerialNumber: e.target.value
        }, () => { this.validate(true); });
    }

    private handleCopierDeviceModelChange = (modelId: number) => {
        this.setState({
            copierDeviceModelId: modelId
        }, () => { this.validate(true); });
    }

    private closeEditModal = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private closeDeleteModal = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCreateCopierDeviceClick = () => {
        this.setState({
            editIndex: -1,
            copierDeviceId: 0,
            copierDeviceModelId: 0,
            copierDeviceSerialNumber: "",
            validationErrors: []
        });
    }

    private handleEditCopierDeviceClick = (index: number) => {
        const copierDevice = this.props.copierDevices[index];
        this.setState({
            editIndex: index,
            copierDeviceId: copierDevice.id,
            copierDeviceModelId: copierDevice.model.id,
            copierDeviceSerialNumber: copierDevice.serialNumber
        }, () => this.validate(true));
    }

    private handleDeleteCopierDeviceClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.copierDeviceSerialNumber.length === 0) {
            validationErrors.push("A gyári szám megadása kötelező.");
        }

        if (this.state.copierDeviceModelId === 0) {
            validationErrors.push("A típus kiválasztása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(CopierDevices as any);
