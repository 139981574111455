import { CopierContract, CopierCounterStatement, CopierSupportTicket, CopierTicketKind } from "../models";

export function getCopierContractHistory(contract: CopierContract) {
    const tickets = contract.tickets.filter(x => x.kind !== CopierTicketKind.Scheduled || x.isSolved).map(ticket => {
        return {
            date: new Date(ticket.createdAt),
            ticket: ticket,
            statement: null
        };
    });

    const statements = contract.statements.map(statement => {
        return {
            date: new Date(statement.createdAt),
            ticket: null,
            statement: statement
        }
    });

    let history: { date: Date, ticket: CopierSupportTicket | null, statement: CopierCounterStatement | null }[] = [];
    history = history.concat(tickets).concat(statements);
    history.sort((a, b) => b.date.valueOf() - a.date.valueOf());

    return history;
}
