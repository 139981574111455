import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as LanSoftStore from '../store/LanSoftStore'
import { Navbar } from 'reactstrap';
import './NavMenu.css';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { MenuItem } from "../models";
import NavMenuItem from "./NavMenuItem";
import NavMenuItemGroup from "./NavMenuItemGroup";

type NavMenuProps = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface NavMenuState {
}

const menuItems: MenuItem[] = [
    {
        icon: "file-contract",
        text: "Szerződéseim",
        target: "/my"
    }
];

const profileMenu: MenuItem = {
    icon: "user",
    text: "Profil"
};

const profileMenuItems: MenuItem[] = [
    {
        icon: "envelope",
        text: "E-mail változtatás",
        target: "/Identity/Account/Manage/Email"
    }, {
        icon: "key",
        text: "Jelszóváltoztatás",
        target: "/Identity/Account/Manage/ChangePassword"
    }, {
        icon: "sign-out-alt",
        text: "Kijelentkezés",
        target: ApplicationPaths.LogOut
    }
];

const pixelOffset = 10;

class NavMenu extends React.PureComponent<NavMenuProps, NavMenuState> {
    constructor(props: NavMenuProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 w-100 main-nav-container" light>
                    <div className="w-100 d-flex justify-content-between align-items-center">
                        <div className="ml-3 logo-container">
                            <a href="/">
                                <img className="nav-logo" src="images/logo.png" />
                            </a>
                        </div>
                        <div className="d-flex justify-content-center nav-menu-container mr-5" style={{ paddingBottom: `${pixelOffset}px`, paddingTop: `${pixelOffset * 1.5}px` }}>
                            {menuItems.map((menuItem, index) => {
                                return (
                                    <NavMenuItem
                                        key={`nav-menu-item-${index}`}
                                        menuItem={menuItem}
                                        isLast={true}
                                        className="px-3"
                                        backgroundColor="rgb(41, 221, 209)"
                                    />
                                );
                            })}
                            <NavMenuItemGroup
                                menuItem={profileMenu}
                                items={profileMenuItems}
                                right={true}
                                isLast={true}
                                className="px-3 mr-5"
                                backgroundColor="rgb(33, 64, 154)"
                            />
                        </div>
                        <div className="mx-5 nav-menu-item-link"></div>
                    </div>
                </Navbar>
            </header>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(NavMenu as any);
