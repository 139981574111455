import { ProtectedRestApi } from "./";
import { CopierTicketSolutionType, ServiceResult } from "../models";
import { CopierTicketSolutionTypeCreatePayload, CopierTicketSolutionTypeUpdatePayload } from "../payloads";

export class CopierTicketSolutionTypeApi extends ProtectedRestApi {
    private static baseUrl = "/api/copier-ticket-solution-type";

    public getAll(): Promise<ServiceResult<CopierTicketSolutionType[]>> {
        return this.httpGet(`${CopierTicketSolutionTypeApi.baseUrl}/all`) as Promise<ServiceResult<CopierTicketSolutionType[]>>;
    }

    public create(payload: CopierTicketSolutionTypeCreatePayload): Promise<ServiceResult<CopierTicketSolutionType>> {
        return this.httpPut(`${CopierTicketSolutionTypeApi.baseUrl}`, payload) as Promise<ServiceResult<CopierTicketSolutionType>>;
    }

    public update(id: number, payload: CopierTicketSolutionTypeUpdatePayload): Promise<ServiceResult<CopierTicketSolutionType>> {
        return this.httpPost(`${CopierTicketSolutionTypeApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<CopierTicketSolutionType>>;
    }

    public delete(id: number): Promise<ServiceResult<number>> {
        return this.httpDelete(`${CopierTicketSolutionTypeApi.baseUrl}/${id}`) as Promise<ServiceResult<number>>;
    }
}
