import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import {
    Client, CopierContract, CopierCounterStatement, CopierDevice,
    CopierModel, Invoice, LanSoftUser, ServiceResult,
    Technician, CopierTicketType, CopierTicketSolutionType, CopierSupportTicket
} from "../models";
import {
    AuthApi, ClientApi, CopierContractApi, CopierDeviceApi,
    CopierModelApi, TechnicianApi, CopierTicketTypeApi, CopierTicketSolutionTypeApi,
    CopierCounterStatementApi, CopierSupportTicketApi
} from "../api";
import {
    CopierContractCreatePayload, CopierContractUpdatePayload,
    CopierDeviceCreatePayload, CopierDeviceUpdatePayload,
    CopierModelCreatePayload, CopierModelUpdatePayload,
    TechnicianCreatePayload, TechnicianUpdatePayload,
    ClientCreatePayload, ClientUpdatePayload, CopierCounterStatementCreatePayload,
    CopierTicketTypeCreatePayload, CopierTicketTypeUpdatePayload,
    CopierTicketSolutionTypeCreatePayload, CopierTicketSolutionTypeUpdatePayload, CopierSupportTicketCreatePayload, CopierSupportTicketSolvePayload, CopierScheduledTicketSolvePayload
} from "../payloads";

export interface LanSoftState {
    loggedInUser: LanSoftUser | null;
    copierContracts: CopierContract[];
    copierModelOperationFinished: boolean;
    copierModels: CopierModel[];
    copierContractOperationFinished: boolean;
    copierDevices: CopierDevice[];
    copierDeviceOperationFinished: boolean;
    technicians: Technician[];
    technicianOperationFinished: boolean;
    clients: Client[];
    clientOperationFinished: boolean;
    copierTicketTypes: CopierTicketType[];
    copierTicketTypeOperationFinished: boolean;
    copierTicketSolutionTypes: CopierTicketSolutionType[];
    copierTicketSolutionTypeOperationFinished: boolean;
    copierCounterStatements: CopierCounterStatement[];
    notAnnouncedCopierCounterStatements: CopierCounterStatement[];
    copierCounterStatementOperationFinished: boolean;
    activeCopierSupportTickets: CopierSupportTicket[];
    inactiveCopierSupportTickets: CopierSupportTicket[];
    scheduledCopierSupportTickets: CopierSupportTicket[];
    copierSupportTicketOperationFinished: boolean;
    result: boolean | null;
    loading: number;
}

export interface GetUserRequestAction { type: "GET_USER_REQUEST" }

export interface GetUserResponseAction { type: "GET_USER_RESPONSE", user: LanSoftUser }

export interface GetCopierContractsRequestAction { type: "GET_COPIER_CONTRACTS_REQUEST" }

export interface GetCopierContractsResponseAction { type: "GET_COPIER_CONTRACTS_RESPONSE", result: ServiceResult<CopierContract[]> }

export interface CreateCopierContractRequestAction { type: "CREATE_COPIER_CONTRACT_REQUEST" }

export interface CreateCopierContractResponseAction { type: "CREATE_COPIER_CONTRACT_RESPONSE", result: ServiceResult<CopierContract> }

export interface UpdateCopierContractRequestAction { type: "UPDATE_COPIER_CONTRACT_REQUEST" }

export interface UpdateCopierContractResponseAction { type: "UPDATE_COPIER_CONTRACT_RESPONSE", result: ServiceResult<CopierContract> }

export interface CloseCopierContractRequestAction { type: "CLOSE_COPIER_CONTRACT_REQUEST" }

export interface CloseCopierContractResponseAction { type: "CLOSE_COPIER_CONTRACT_RESPONSE", result: ServiceResult<number> }

export interface DeleteCopierContractRequestAction { type: "DELETE_COPIER_CONTRACT_REQUEST" }

export interface DeleteCopierContractResponseAction { type: "DELETE_COPIER_CONTRACT_RESPONSE", result: ServiceResult<number> }

export interface CreateCopierCounterStatementRequestAction { type: "CREATE_COPIER_COUNTER_STATEMENT_REQUEST" }

export interface CreateCopierCounterStatementResponseAction { type: "CREATE_COPIER_COUNTER_STATEMENT_RESPONSE", contractId: number, result: ServiceResult<CopierCounterStatement> }

export interface CreateCopierInvoiceRequestAction { type: "CREATE_COPIER_INVOICE_REQUEST" }

export interface CreateCopierInvoiceResponseAction { type: "CREATE_COPIER_INVOICE_RESPONSE", statementId: number, result: ServiceResult<Invoice> }

export interface CreateCopierSupportTicketRequestAction { type: "CREATE_COPIER_SUPPORT_TICKET_REQUEST" }

export interface CreateCopierSupportTicketResponseAction { type: "CREATE_COPIER_SUPPORT_TICKET_RESPONSE", contractId: number, result: ServiceResult<CopierSupportTicket> }

export interface SolveCopierSupportTicketRequestAction { type: "SOLVE_COPIER_SUPPORT_TICKET_REQUEST" }

export interface SolveCopierSupportTicketResponseAction { type: "SOLVE_COPIER_SUPPORT_TICKET_RESPONSE", contractId: number, result: ServiceResult<CopierSupportTicket> }

export interface SolveCopierScheduledTicketRequestAction { type: "SOLVE_COPIER_SCHEDULED_TICKET_REQUEST" }

export interface SolveCopierScheduledTicketResponseAction { type: "SOLVE_COPIER_SCHEDULED_TICKET_RESPONSE", contractId: number, result: ServiceResult<CopierSupportTicket> }

export interface ClearCopierContractsAction { type: "CLEAR_COPIER_CONTRACTS" }

export interface GetCopierModelsRequestAction { type: "GET_COPIER_MODELS_REQUEST" }

export interface GetCopierModelsResponseAction { type: "GET_COPIER_MODELS_RESPONSE", result: ServiceResult<CopierModel[]> }

export interface CreateCopierModelRequestAction { type: "CREATE_COPIER_MODEL_REQUEST" }

export interface CreateCopierModelResponseAction { type: "CREATE_COPIER_MODEL_RESPONSE", result: ServiceResult<CopierModel> }

export interface UpdateCopierModelRequestAction { type: "UPDATE_COPIER_MODEL_REQUEST" }

export interface UpdateCopierModelResponseAction { type: "UPDATE_COPIER_MODEL_RESPONSE", result: ServiceResult<CopierModel> }

export interface DeleteCopierModelRequestAction { type: "DELETE_COPIER_MODEL_REQUEST" }

export interface DeleteCopierModelResponseAction { type: "DELETE_COPIER_MODEL_RESPONSE", result: ServiceResult<number> }

export interface ClearCopierModelsAction { type: "CLEAR_COPIER_MODELS" }

export interface GetCopierDevicesRequestAction { type: "GET_COPIER_DEVICES_REQUEST" }

export interface GetCopierDevicesResponseAction { type: "GET_COPIER_DEVICES_RESPONSE", result: ServiceResult<CopierDevice[]> }

export interface CreateCopierDeviceRequestAction { type: "CREATE_COPIER_DEVICE_REQUEST" }

export interface CreateCopierDeviceResponseAction { type: "CREATE_COPIER_DEVICE_RESPONSE", result: ServiceResult<CopierDevice> }

export interface UpdateCopierDeviceRequestAction { type: "UPDATE_COPIER_DEVICE_REQUEST" }

export interface UpdateCopierDeviceResponseAction { type: "UPDATE_COPIER_DEVICE_RESPONSE", result: ServiceResult<CopierDevice> }

export interface DeleteCopierDeviceRequestAction { type: "DELETE_COPIER_DEVICE_REQUEST" }

export interface DeleteCopierDeviceResponseAction { type: "DELETE_COPIER_DEVICE_RESPONSE", result: ServiceResult<number> }

export interface ClearCopierDevicesAction { type: "CLEAR_COPIER_DEVICES" }

export interface GetTechniciansRequestAction { type: "GET_TECHNICIANS_REQUEST" }

export interface GetTechniciansResponseAction { type: "GET_TECHNICIANS_RESPONSE", result: ServiceResult<Technician[]> }

export interface CreateTechnicianRequestAction { type: "CREATE_TECHNICIAN_REQUEST" }

export interface CreateTechnicianResponseAction { type: "CREATE_TECHNICIAN_RESPONSE", result: ServiceResult<Technician> }

export interface UpdateTechnicianRequestAction { type: "UPDATE_TECHNICIAN_REQUEST" }

export interface UpdateTechnicianResponseAction { type: "UPDATE_TECHNICIAN_RESPONSE", result: ServiceResult<Technician> }

export interface DeleteTechnicianRequestAction { type: "DELETE_TECHNICIAN_REQUEST" }

export interface DeleteTechnicianResponseAction { type: "DELETE_TECHNICIAN_RESPONSE", result: ServiceResult<number> }

export interface ClearTechniciansAction { type: "CLEAR_TECHNICIANS" }

export interface GetClientsRequestAction { type: "GET_CLIENTS_REQUEST" }

export interface GetClientsResponseAction { type: "GET_CLIENTS_RESPONSE", result: ServiceResult<Client[]> }

export interface CreateClientRequestAction { type: "CREATE_CLIENT_REQUEST" }

export interface CreateClientResponseAction { type: "CREATE_CLIENT_RESPONSE", result: ServiceResult<Client> }

export interface UpdateClientRequestAction { type: "UPDATE_CLIENT_REQUEST" }

export interface UpdateClientResponseAction { type: "UPDATE_CLIENT_RESPONSE", result: ServiceResult<Client> }

export interface DeleteClientRequestAction { type: "DELETE_CLIENT_REQUEST" }

export interface DeleteClientResponseAction { type: "DELETE_CLIENT_RESPONSE", result: ServiceResult<number> }

export interface ClearClientsAction { type: "CLEAR_CLIENTS" }

export interface GetCopierTicketTypesRequestAction { type: "GET_COPIER_TICKET_TYPES_REQUEST" }

export interface GetCopierTicketTypesResponseAction { type: "GET_COPIER_TICKET_TYPES_RESPONSE", result: ServiceResult<CopierTicketType[]> }

export interface CreateCopierTicketTypeRequestAction { type: "CREATE_COPIER_TICKET_TYPE_REQUEST" }

export interface CreateCopierTicketTypeResponseAction { type: "CREATE_COPIER_TICKET_TYPE_RESPONSE", result: ServiceResult<CopierTicketType> }

export interface UpdateCopierTicketTypeRequestAction { type: "UPDATE_COPIER_TICKET_TYPE_REQUEST" }

export interface UpdateCopierTicketTypeResponseAction { type: "UPDATE_COPIER_TICKET_TYPE_RESPONSE", result: ServiceResult<CopierTicketType> }

export interface DeleteCopierTicketTypeRequestAction { type: "DELETE_COPIER_TICKET_TYPE_REQUEST" }

export interface DeleteCopierTicketTypeResponseAction { type: "DELETE_COPIER_TICKET_TYPE_RESPONSE", result: ServiceResult<number> }

export interface ClearCopierTicketTypesAction { type: "CLEAR_COPIER_TICKET_TYPES" }

export interface GetCopierTicketSolutionTypesRequestAction { type: "GET_COPIER_TICKET_SOLUTION_TYPES_REQUEST" }

export interface GetCopierTicketSolutionTypesResponseAction { type: "GET_COPIER_TICKET_SOLUTION_TYPES_RESPONSE", result: ServiceResult<CopierTicketSolutionType[]> }

export interface CreateCopierTicketSolutionTypeRequestAction { type: "CREATE_COPIER_TICKET_SOLUTION_TYPE_REQUEST" }

export interface CreateCopierTicketSolutionTypeResponseAction { type: "CREATE_COPIER_TICKET_SOLUTION_TYPE_RESPONSE", result: ServiceResult<CopierTicketSolutionType> }

export interface UpdateCopierTicketSolutionTypeRequestAction { type: "UPDATE_COPIER_TICKET_SOLUTION_TYPE_REQUEST" }

export interface UpdateCopierTicketSolutionTypeResponseAction { type: "UPDATE_COPIER_TICKET_SOLUTION_TYPE_RESPONSE", result: ServiceResult<CopierTicketSolutionType> }

export interface DeleteCopierTicketSolutionTypeRequestAction { type: "DELETE_COPIER_TICKET_SOLUTION_TYPE_REQUEST" }

export interface DeleteCopierTicketSolutionTypeResponseAction { type: "DELETE_COPIER_TICKET_SOLUTION_TYPE_RESPONSE", result: ServiceResult<number> }

export interface ClearCopierTicketSolutionTypesAction { type: "CLEAR_COPIER_TICKET_SOLUTION_TYPES" }

export interface GetCopierCounterStatementsRequestAction { type: "GET_COPIER_COUNTER_STATEMENTS_REQUEST" }

export interface GetCopierCounterStatementsResponseAction { type: "GET_COPIER_COUNTER_STATEMENTS_RESPONSE", result: ServiceResult<CopierCounterStatement[]> }

export interface ClearCopierCounterStatementsAction { type: "CLEAR_COPIER_COUNTER_STATEMENTS" }

export interface GetNotAnnouncedCopierCounterStatementsRequestAction { type: "GET_NOT_ANNOUNCED_COPIER_COUNTER_STATEMENTS_REQUEST" }

export interface GetNotAnnouncedCopierCounterStatementsResponseAction { type: "GET_NOT_ANNOUNCED_COPIER_COUNTER_STATEMENTS_RESPONSE", result: ServiceResult<CopierCounterStatement[]> }

export interface ClearNotAnnouncedCopierCounterStatementsAction { type: "CLEAR_NOT_ANNOUNCED_COPIER_COUNTER_STATEMENTS" }

export interface GetActiveCopierSupportTicketsRequestAction { type: "GET_ACTIVE_COPIER_SUPPORT_TICKETS_REQUEST" }

export interface GetActiveCopierSupportTicketsResponseAction { type: "GET_ACTIVE_COPIER_SUPPORT_TICKETS_RESPONSE", result: ServiceResult<CopierSupportTicket[]> }

export interface ClearActiveCopierSupportTicketsAction { type: "CLEAR_ACTIVE_COPIER_SUPPORT_TICKETS" }

export interface GetInactiveCopierSupportTicketsRequestAction { type: "GET_INACTIVE_COPIER_SUPPORT_TICKETS_REQUEST" }

export interface GetInactiveCopierSupportTicketsResponseAction { type: "GET_INACTIVE_COPIER_SUPPORT_TICKETS_RESPONSE", result: ServiceResult<CopierSupportTicket[]> }

export interface ClearInactiveCopierSupportTicketsAction { type: "CLEAR_INACTIVE_COPIER_SUPPORT_TICKETS" }

export interface GetScheduledCopierSupportTicketsRequestAction { type: "GET_SCHEDULED_COPIER_SUPPORT_TICKETS_REQUEST" }

export interface GetScheduledCopierSupportTicketsResponseAction { type: "GET_SCHEDULED_COPIER_SUPPORT_TICKETS_RESPONSE", result: ServiceResult<CopierSupportTicket[]> }

export interface ClearScheduledCopierSupportTicketsAction { type: "CLEAR_SCHEDULED_COPIER_SUPPORT_TICKETS" }

export interface ClearResultAction { type: "CLEAR_RESULT" }

export interface ErrorAction { type: "ERROR", message: string }

export type KnownAction = GetUserRequestAction | GetUserResponseAction
    | GetCopierContractsRequestAction | GetCopierContractsResponseAction
    | CreateCopierContractRequestAction | CreateCopierContractResponseAction
    | UpdateCopierContractRequestAction | UpdateCopierContractResponseAction
    | CloseCopierContractRequestAction | CloseCopierContractResponseAction
    | DeleteCopierContractRequestAction | DeleteCopierContractResponseAction
    | ClearCopierContractsAction
    | CreateCopierCounterStatementRequestAction | CreateCopierCounterStatementResponseAction
    | CreateCopierInvoiceRequestAction | CreateCopierInvoiceResponseAction
    | CreateCopierSupportTicketRequestAction | CreateCopierSupportTicketResponseAction
    | SolveCopierSupportTicketRequestAction | SolveCopierSupportTicketResponseAction
    | SolveCopierScheduledTicketRequestAction | SolveCopierScheduledTicketResponseAction
    | GetCopierModelsRequestAction | GetCopierModelsResponseAction
    | CreateCopierModelRequestAction | CreateCopierModelResponseAction
    | UpdateCopierModelRequestAction | UpdateCopierModelResponseAction
    | DeleteCopierModelRequestAction | DeleteCopierModelResponseAction
    | ClearCopierModelsAction
    | GetCopierDevicesRequestAction | GetCopierDevicesResponseAction
    | CreateCopierDeviceRequestAction | CreateCopierDeviceResponseAction
    | UpdateCopierDeviceRequestAction | UpdateCopierDeviceResponseAction
    | DeleteCopierDeviceRequestAction | DeleteCopierDeviceResponseAction
    | ClearCopierDevicesAction
    | GetTechniciansRequestAction | GetTechniciansResponseAction
    | CreateTechnicianRequestAction | CreateTechnicianResponseAction
    | UpdateTechnicianRequestAction | UpdateTechnicianResponseAction
    | DeleteTechnicianRequestAction | DeleteTechnicianResponseAction
    | ClearTechniciansAction
    | GetClientsRequestAction | GetClientsResponseAction
    | CreateClientRequestAction | CreateClientResponseAction
    | UpdateClientRequestAction | UpdateClientResponseAction
    | DeleteClientRequestAction | DeleteClientResponseAction
    | ClearClientsAction
    | GetCopierTicketTypesRequestAction | GetCopierTicketTypesResponseAction
    | CreateCopierTicketTypeRequestAction | CreateCopierTicketTypeResponseAction
    | UpdateCopierTicketTypeRequestAction | UpdateCopierTicketTypeResponseAction
    | DeleteCopierTicketTypeRequestAction | DeleteCopierTicketTypeResponseAction
    | ClearCopierTicketTypesAction
    | GetCopierTicketSolutionTypesRequestAction | GetCopierTicketSolutionTypesResponseAction
    | CreateCopierTicketSolutionTypeRequestAction | CreateCopierTicketSolutionTypeResponseAction
    | UpdateCopierTicketSolutionTypeRequestAction | UpdateCopierTicketSolutionTypeResponseAction
    | DeleteCopierTicketSolutionTypeRequestAction | DeleteCopierTicketSolutionTypeResponseAction
    | ClearCopierTicketSolutionTypesAction
    | GetCopierCounterStatementsRequestAction | GetCopierCounterStatementsResponseAction
    | ClearCopierCounterStatementsAction
    | GetNotAnnouncedCopierCounterStatementsRequestAction | GetNotAnnouncedCopierCounterStatementsResponseAction
    | ClearNotAnnouncedCopierCounterStatementsAction
    | GetActiveCopierSupportTicketsRequestAction | GetActiveCopierSupportTicketsResponseAction
    | ClearActiveCopierSupportTicketsAction
    | GetInactiveCopierSupportTicketsRequestAction | GetInactiveCopierSupportTicketsResponseAction
    | ClearInactiveCopierSupportTicketsAction
    | GetScheduledCopierSupportTicketsRequestAction | GetScheduledCopierSupportTicketsResponseAction
    | ClearScheduledCopierSupportTicketsAction
    | ClearResultAction | ErrorAction;

const authApi = new AuthApi();
const copierContractApi = new CopierContractApi();
const copierModelApi = new CopierModelApi();
const copierDeviceApi = new CopierDeviceApi();
const technicianApi = new TechnicianApi();
const clientApi = new ClientApi();
const copierTicketTypeApi = new CopierTicketTypeApi();
const copierTicketSolutionTypeApi = new CopierTicketSolutionTypeApi();
const copierCounterStatementApi = new CopierCounterStatementApi();
const copierSupportTicketApi = new CopierSupportTicketApi();

export const actionCreators = {
    getLoggedInUser: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_USER_REQUEST" });
        try {
            const result = await authApi.getLoggedInUser();
            dispatch({ type: "GET_USER_RESPONSE", user: result.data });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    getMyCopierContracts: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_COPIER_CONTRACTS_REQUEST" });
        try {
            const result = await copierContractApi.getMy();
            dispatch({ type: "GET_COPIER_CONTRACTS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    getCopierContractsByClient: (clientId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_COPIER_CONTRACTS_REQUEST" });
        try {
            const result = await copierContractApi.getByClient(clientId);
            dispatch({ type: "GET_COPIER_CONTRACTS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    getCopierContractsByTechnician: (technicianId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_COPIER_CONTRACTS_REQUEST" });
        try {
            const result = await copierContractApi.getByTechnician(technicianId);
            dispatch({ type: "GET_COPIER_CONTRACTS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    getAllCopierContracts: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_COPIER_CONTRACTS_REQUEST" });
        try {
            const result = await copierContractApi.getAll();
            dispatch({ type: "GET_COPIER_CONTRACTS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    getAllCopierContractsIncludingEnded: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_COPIER_CONTRACTS_REQUEST" });
        try {
            const result = await copierContractApi.getAllIncludingEnded();
            dispatch({ type: "GET_COPIER_CONTRACTS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    createCopierContract: (payload: CopierContractCreatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "CREATE_COPIER_CONTRACT_REQUEST" });
        try {
            const result = await copierContractApi.create(payload);
            dispatch({ type: "CREATE_COPIER_CONTRACT_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    updateCopierContract: (id: number, payload: CopierContractUpdatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "UPDATE_COPIER_CONTRACT_REQUEST" });
        try {
            const result = await copierContractApi.update(id, payload);
            dispatch({ type: "UPDATE_COPIER_CONTRACT_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    closeCopierContract: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "CLOSE_COPIER_CONTRACT_REQUEST" });
        try {
            const result = await copierContractApi.close(id);
            dispatch({ type: "CLOSE_COPIER_CONTRACT_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    deleteCopierContract: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "DELETE_COPIER_CONTRACT_REQUEST" });
        try {
            const result = await copierContractApi.delete(id);
            dispatch({ type: "DELETE_COPIER_CONTRACT_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    createCopierCounterStatement: (contractId: number, payload: CopierCounterStatementCreatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "CREATE_COPIER_COUNTER_STATEMENT_REQUEST" });
        try {
            const result = await copierContractApi.createStatement(contractId, payload);
            dispatch({ type: "CREATE_COPIER_COUNTER_STATEMENT_RESPONSE", contractId: contractId, result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    createCopierInvoice: (contractId: number, statementId: number, file: File): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "CREATE_COPIER_INVOICE_REQUEST" });
        try {
            const result = await copierContractApi.createInvoice(contractId, statementId, file);
            dispatch({ type: "CREATE_COPIER_INVOICE_RESPONSE", statementId: statementId, result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    createCopierSupportTicket: (contractId: number, payload: CopierSupportTicketCreatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "CREATE_COPIER_SUPPORT_TICKET_REQUEST" });
        try {
            const result = await copierContractApi.createSupportTicket(contractId, payload);
            dispatch({ type: "CREATE_COPIER_SUPPORT_TICKET_RESPONSE", contractId: contractId, result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    solveCopierSupportTicket: (contractId: number, ticketId: number, payload: CopierSupportTicketSolvePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "SOLVE_COPIER_SUPPORT_TICKET_REQUEST" });
        try {
            const result = await copierContractApi.solveSupportTicket(contractId, ticketId, payload);
            dispatch({ type: "SOLVE_COPIER_SUPPORT_TICKET_RESPONSE", contractId: contractId, result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    solveScheduledCopierSupportTicket: (contractId: number, ticketId: number, payload: CopierScheduledTicketSolvePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "SOLVE_COPIER_SCHEDULED_TICKET_REQUEST" });
        try {
            const result = await copierContractApi.solveScheduledTicket(contractId, ticketId, payload);
            dispatch({ type: "SOLVE_COPIER_SCHEDULED_TICKET_RESPONSE", contractId: contractId, result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearCopierContracts: () => ({ type: "CLEAR_COPIER_CONTRACTS" } as ClearCopierContractsAction),
    getAllCopierModels: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_COPIER_MODELS_REQUEST" });
        try {
            const result = await copierModelApi.getAll();
            dispatch({ type: "GET_COPIER_MODELS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    createCopierModel: (payload: CopierModelCreatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "CREATE_COPIER_MODEL_REQUEST" });
        try {
            const result = await copierModelApi.create(payload);
            dispatch({ type: "CREATE_COPIER_MODEL_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    updateCopierModel: (id: number, payload: CopierModelUpdatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "UPDATE_COPIER_MODEL_REQUEST" });
        try {
            const result = await copierModelApi.update(id, payload);
            dispatch({ type: "UPDATE_COPIER_MODEL_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    deleteCopierModel: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "DELETE_COPIER_MODEL_REQUEST" });
        try {
            const result = await copierModelApi.delete(id);
            dispatch({ type: "DELETE_COPIER_MODEL_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearCopierModels: () => ({ type: "CLEAR_COPIER_MODELS" } as ClearCopierModelsAction),
    getAllCopierDevices: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_COPIER_DEVICES_REQUEST" });
        try {
            const result = await copierDeviceApi.getAll();
            dispatch({ type: "GET_COPIER_DEVICES_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    createCopierDevice: (payload: CopierDeviceCreatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "CREATE_COPIER_DEVICE_REQUEST" });
        try {
            const result = await copierDeviceApi.create(payload);
            dispatch({ type: "CREATE_COPIER_DEVICE_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    updateCopierDevice: (id: number, payload: CopierDeviceUpdatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "UPDATE_COPIER_DEVICE_REQUEST" });
        try {
            const result = await copierDeviceApi.update(id, payload);
            dispatch({ type: "UPDATE_COPIER_DEVICE_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    deleteCopierDevice: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "DELETE_COPIER_DEVICE_REQUEST" });
        try {
            const result = await copierDeviceApi.delete(id);
            dispatch({ type: "DELETE_COPIER_DEVICE_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearCopierDevices: () => ({ type: "CLEAR_COPIER_DEVICES" } as ClearCopierDevicesAction),
    getAllTechnicians: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_TECHNICIANS_REQUEST" });
        try {
            const result = await technicianApi.getAll();
            dispatch({ type: "GET_TECHNICIANS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    createTechnician: (payload: TechnicianCreatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "CREATE_TECHNICIAN_REQUEST" });
        try {
            const result = await technicianApi.create(payload);
            dispatch({ type: "CREATE_TECHNICIAN_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    updateTechnician: (id: number, payload: TechnicianUpdatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "UPDATE_TECHNICIAN_REQUEST" });
        try {
            const result = await technicianApi.update(id, payload);
            dispatch({ type: "UPDATE_TECHNICIAN_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    deleteTechnician: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "DELETE_TECHNICIAN_REQUEST" });
        try {
            const result = await technicianApi.delete(id);
            dispatch({ type: "DELETE_TECHNICIAN_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearTechnicians: () => ({ type: "CLEAR_TECHNICIANS" } as ClearTechniciansAction),
    getAllClients: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_CLIENTS_REQUEST" });
        try {
            const result = await clientApi.getAll();
            dispatch({ type: "GET_CLIENTS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    createClient: (payload: ClientCreatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "CREATE_CLIENT_REQUEST" });
        try {
            const result = await clientApi.create(payload);
            dispatch({ type: "CREATE_CLIENT_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    updateClient: (id: number, payload: ClientUpdatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "UPDATE_CLIENT_REQUEST" });
        try {
            const result = await clientApi.update(id, payload);
            dispatch({ type: "UPDATE_CLIENT_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    deleteClient: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "DELETE_CLIENT_REQUEST" });
        try {
            const result = await clientApi.delete(id);
            dispatch({ type: "DELETE_CLIENT_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearClients: () => ({ type: "CLEAR_CLIENTS" } as ClearClientsAction),
    getAllCopierTicketTypes: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_COPIER_TICKET_TYPES_REQUEST" });
        try {
            const result = await copierTicketTypeApi.getAll();
            dispatch({ type: "GET_COPIER_TICKET_TYPES_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    createCopierTicketType: (payload: CopierTicketTypeCreatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "CREATE_COPIER_TICKET_TYPE_REQUEST" });
        try {
            const result = await copierTicketTypeApi.create(payload);
            dispatch({ type: "CREATE_COPIER_TICKET_TYPE_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    updateCopierTicketType: (id: number, payload: CopierTicketTypeUpdatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "UPDATE_COPIER_TICKET_TYPE_REQUEST" });
        try {
            const result = await copierTicketTypeApi.update(id, payload);
            dispatch({ type: "UPDATE_COPIER_TICKET_TYPE_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    deleteCopierTicketType: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "DELETE_COPIER_TICKET_TYPE_REQUEST" });
        try {
            const result = await copierTicketTypeApi.delete(id);
            dispatch({ type: "DELETE_COPIER_TICKET_TYPE_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearCopierTicketTypes: () => ({ type: "CLEAR_COPIER_TICKET_TYPES" } as ClearCopierTicketTypesAction),
    getAllCopierTicketSolutionTypes: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_COPIER_TICKET_SOLUTION_TYPES_REQUEST" });
        try {
            const result = await copierTicketSolutionTypeApi.getAll();
            dispatch({ type: "GET_COPIER_TICKET_SOLUTION_TYPES_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    createCopierTicketSolutionType: (payload: CopierTicketSolutionTypeCreatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "CREATE_COPIER_TICKET_SOLUTION_TYPE_REQUEST" });
        try {
            const result = await copierTicketSolutionTypeApi.create(payload);
            dispatch({ type: "CREATE_COPIER_TICKET_SOLUTION_TYPE_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    updateCopierTicketSolutionType: (id: number, payload: CopierTicketSolutionTypeUpdatePayload): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "UPDATE_COPIER_TICKET_SOLUTION_TYPE_REQUEST" });
        try {
            const result = await copierTicketSolutionTypeApi.update(id, payload);
            dispatch({ type: "UPDATE_COPIER_TICKET_SOLUTION_TYPE_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    deleteCopierTicketSolutionType: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "DELETE_COPIER_TICKET_SOLUTION_TYPE_REQUEST" });
        try {
            const result = await copierTicketSolutionTypeApi.delete(id);
            dispatch({ type: "DELETE_COPIER_TICKET_SOLUTION_TYPE_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearCopierTicketSolutionTypes: () => ({ type: "CLEAR_COPIER_TICKET_SOLUTION_TYPES" } as ClearCopierTicketSolutionTypesAction),
    getAllCopierCounterStatements: (onlyMine: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_COPIER_COUNTER_STATEMENTS_REQUEST" });
        try {
            const result = await copierCounterStatementApi.getAll(onlyMine);
            dispatch({ type: "GET_COPIER_COUNTER_STATEMENTS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearCopierCounterStatements: () => ({ type: "CLEAR_COPIER_COUNTER_STATEMENTS" } as ClearCopierCounterStatementsAction),
    getNotAnnouncedCopierCounterStatements: (onlyMine: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_NOT_ANNOUNCED_COPIER_COUNTER_STATEMENTS_REQUEST" });
        try {
            const result = await copierCounterStatementApi.getNotAnnounced(onlyMine);
            dispatch({ type: "GET_NOT_ANNOUNCED_COPIER_COUNTER_STATEMENTS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearNotAnnouncedCopierCounterStatements: () => ({ type: "CLEAR_NOT_ANNOUNCED_COPIER_COUNTER_STATEMENTS" } as ClearNotAnnouncedCopierCounterStatementsAction),
    getActiveCopierSupportTickets: (onlyMine: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_ACTIVE_COPIER_SUPPORT_TICKETS_REQUEST" });
        try {
            const result = await copierSupportTicketApi.getActive(onlyMine);
            dispatch({ type: "GET_ACTIVE_COPIER_SUPPORT_TICKETS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearActiveCopierSupportTickets: () => ({ type: "CLEAR_ACTIVE_COPIER_SUPPORT_TICKETS" } as ClearActiveCopierSupportTicketsAction),
    getInactiveCopierSupportTickets: (onlyMine: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_INACTIVE_COPIER_SUPPORT_TICKETS_REQUEST" });
        try {
            const result = await copierSupportTicketApi.getInactive(onlyMine);
            dispatch({ type: "GET_INACTIVE_COPIER_SUPPORT_TICKETS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearInactiveCopierSupportTickets: () => ({ type: "CLEAR_INACTIVE_COPIER_SUPPORT_TICKETS" } as ClearInactiveCopierSupportTicketsAction),
    getScheduledCopierSupportTickets: (onlyMine: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_SCHEDULED_COPIER_SUPPORT_TICKETS_REQUEST" });
        try {
            const result = await copierSupportTicketApi.getScheduled(onlyMine);
            dispatch({ type: "GET_SCHEDULED_COPIER_SUPPORT_TICKETS_RESPONSE", result: result });
        } catch (err) {
            dispatch({ type: "ERROR", message: err });
        }
    },
    clearScheduledCopierSupportTickets: () => ({ type: "CLEAR_SCHEDULED_COPIER_SUPPORT_TICKETS" } as ClearScheduledCopierSupportTicketsAction),
    clearResult: () => ({ type: "CLEAR_RESULT" } as ClearResultAction),
    error: (message: string) => ({ type: "ERROR", message: message } as ErrorAction)
};

export const reducer: Reducer<LanSoftState> = (state: LanSoftState | undefined, incomingAction: Action): LanSoftState => {
    if (state === undefined) {
        return {
            loggedInUser: null,
            copierContracts: [],
            copierContractOperationFinished: false,
            copierModels: [],
            copierModelOperationFinished: false,
            copierDevices: [],
            copierDeviceOperationFinished: false,
            technicians: [],
            technicianOperationFinished: false,
            clients: [],
            clientOperationFinished: false,
            copierTicketTypes: [],
            copierTicketTypeOperationFinished: false,
            copierTicketSolutionTypes: [],
            copierTicketSolutionTypeOperationFinished: false,
            copierCounterStatements: [],
            notAnnouncedCopierCounterStatements: [],
            copierCounterStatementOperationFinished: false,
            activeCopierSupportTickets: [],
            inactiveCopierSupportTickets: [],
            scheduledCopierSupportTickets: [],
            copierSupportTicketOperationFinished: false,
            result: null,
            loading: 0
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "GET_USER_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                loggedInUser: null
            }
        case "GET_USER_RESPONSE":
            return {
                ...state,
                loading: state.loading - 1,
                loggedInUser: action.user
            }
        case "GET_COPIER_CONTRACTS_REQUEST":
            return {
                ...state,
                copierContracts: [],
                loading: state.loading + 1
            }
        case "GET_COPIER_CONTRACTS_RESPONSE":
            return {
                ...state,
                copierContracts: action.result.data,
                loading: state.loading - 1
            }
        case "CREATE_COPIER_CONTRACT_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierContractOperationFinished: false
            }
        case "CREATE_COPIER_CONTRACT_RESPONSE":
            return {
                ...state,
                copierContracts: [action.result.data].concat(state.copierContracts),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierContractOperationFinished: true
            }
        case "UPDATE_COPIER_CONTRACT_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierContractOperationFinished: false
            }
        case "UPDATE_COPIER_CONTRACT_RESPONSE":
            const updatedContract = action.result.data;
            return {
                ...state,
                copierContracts: state.copierContracts.map(contract => {
                    if (contract.id === updatedContract.id) {
                        return updatedContract;
                    }

                    return contract;
                }),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierContractOperationFinished: true
            }
        case "CLOSE_COPIER_CONTRACT_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierContractOperationFinished: false
            }
        case "CLOSE_COPIER_CONTRACT_RESPONSE":
            return {
                ...state,
                copierContracts: state.copierContracts.map(contract => {
                    return contract.id === action.result.data
                        ? {
                            ...contract,
                            endDate: new Date()
                        }
                        : contract
                }),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierContractOperationFinished: true
            }
        case "DELETE_COPIER_CONTRACT_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierContractOperationFinished: false
            }
        case "DELETE_COPIER_CONTRACT_RESPONSE":
            return {
                ...state,
                copierContracts: state.copierContracts.filter(contract => contract.id !== action.result.data),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierContractOperationFinished: true
            }
        case "CREATE_COPIER_COUNTER_STATEMENT_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierContractOperationFinished: false
            }
        case "CREATE_COPIER_COUNTER_STATEMENT_RESPONSE":
            return {
                ...state,
                copierContracts: state.copierContracts.map(contract => {
                    return contract.id === action.contractId
                        ? {
                            ...contract,
                            statements: [action.result.data].concat(contract.statements)
                        }
                        : contract
                }),
                copierCounterStatements: [action.result.data].concat(state.copierCounterStatements),
                notAnnouncedCopierCounterStatements: state.notAnnouncedCopierCounterStatements.filter(x => x.contractId !== action.contractId),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierContractOperationFinished: true
            }
        case "CREATE_COPIER_SUPPORT_TICKET_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierSupportTicketOperationFinished: false
            }
        case "CREATE_COPIER_SUPPORT_TICKET_RESPONSE":
            return {
                ...state,
                copierContracts: state.copierContracts.map(contract => {
                    return contract.id === action.contractId
                        ? {
                            ...contract,
                            tickets: [action.result.data].concat(contract.tickets)
                        }
                        : contract
                }),
                activeCopierSupportTickets: [action.result.data].concat(state.activeCopierSupportTickets),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierSupportTicketOperationFinished: true
            }
        case "SOLVE_COPIER_SUPPORT_TICKET_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierSupportTicketOperationFinished: false
            }
        case "SOLVE_COPIER_SUPPORT_TICKET_RESPONSE":
            return {
                ...state,
                copierContracts: state.copierContracts.map(contract => {
                    return contract.id === action.contractId
                        ? {
                            ...contract,
                            tickets: contract.tickets.map(ticket => {
                                return ticket.id === action.result.data.id ? action.result.data : ticket;
                            })
                        }
                        : contract
                }),
                activeCopierSupportTickets: state.activeCopierSupportTickets.filter(ticket => ticket.id !== action.result.data.id),
                inactiveCopierSupportTickets: [action.result.data].concat(state.inactiveCopierSupportTickets),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierSupportTicketOperationFinished: true
            }
        case "SOLVE_COPIER_SCHEDULED_TICKET_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierSupportTicketOperationFinished: false
            }
        case "SOLVE_COPIER_SCHEDULED_TICKET_RESPONSE":
            return {
                ...state,
                copierContracts: state.copierContracts.map(contract => {
                    return contract.id === action.contractId
                        ? {
                            ...contract,
                            tickets: contract.tickets.map(ticket => {
                                return ticket.id === action.result.data.id ? action.result.data : ticket;
                            })
                        }
                        : contract
                }),
                scheduledCopierSupportTickets: state.scheduledCopierSupportTickets.map(ticket => {
                    return ticket.id === action.result.data.id ? action.result.data : ticket;
                }),
                inactiveCopierSupportTickets: [action.result.data].concat(state.inactiveCopierSupportTickets),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierSupportTicketOperationFinished: true
            }
        case "CREATE_COPIER_INVOICE_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierContractOperationFinished: false
            }
        case "CREATE_COPIER_INVOICE_RESPONSE":
            return {
                ...state,
                copierContracts: state.copierContracts.map(contract => {
                    return {
                        ...contract,
                        statements: contract.statements.map(statement => {
                            return statement.id === action.statementId
                                ? {
                                    ...statement,
                                    invoice: action.result.data
                                }
                                : statement
                        })
                    }
                }),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierContractOperationFinished: true
            }
        case "CLEAR_COPIER_CONTRACTS":
            return {
                ...state,
                copierContracts: []
            }
        case "GET_COPIER_MODELS_REQUEST":
            return {
                ...state,
                copierModels: [],
                loading: state.loading + 1
            }
        case "GET_COPIER_MODELS_RESPONSE":
            return {
                ...state,
                copierModels: action.result.data,
                loading: state.loading - 1
            }
        case "CREATE_COPIER_MODEL_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierModelOperationFinished: false
            }
        case "CREATE_COPIER_MODEL_RESPONSE":
            return {
                ...state,
                copierModels: [action.result.data].concat(state.copierModels),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierModelOperationFinished: true
            }
        case "UPDATE_COPIER_MODEL_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierModelOperationFinished: false
            }
        case "UPDATE_COPIER_MODEL_RESPONSE":
            const updatedModel = action.result.data;
            return {
                ...state,
                copierModels: state.copierModels.map(model => {
                    if (model.id === updatedModel.id) {
                        return updatedModel;
                    }

                    return model;
                }),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierModelOperationFinished: true
            }
        case "DELETE_COPIER_MODEL_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierModelOperationFinished: false
            }
        case "DELETE_COPIER_MODEL_RESPONSE":
            return {
                ...state,
                copierModels: state.copierModels.filter(model => model.id !== action.result.data),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierModelOperationFinished: true
            }
        case "CLEAR_COPIER_MODELS":
            return {
                ...state,
                copierModels: []
            }
        case "GET_COPIER_DEVICES_REQUEST":
            return {
                ...state,
                copierDevices: [],
                loading: state.loading + 1
            }
        case "GET_COPIER_DEVICES_RESPONSE":
            return {
                ...state,
                copierDevices: action.result.data,
                loading: state.loading - 1
            }
        case "CREATE_COPIER_DEVICE_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierDeviceOperationFinished: false
            }
        case "CREATE_COPIER_DEVICE_RESPONSE":
            return {
                ...state,
                copierDevices: [action.result.data].concat(state.copierDevices),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierDeviceOperationFinished: true
            }
        case "UPDATE_COPIER_DEVICE_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierDeviceOperationFinished: false
            }
        case "UPDATE_COPIER_DEVICE_RESPONSE":
            const updatedDevice = action.result.data;
            return {
                ...state,
                copierDevices: state.copierDevices.map(device => {
                    if (device.id === updatedDevice.id) {
                        return updatedDevice;
                    }

                    return device;
                }),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierDeviceOperationFinished: true
            }
        case "DELETE_COPIER_DEVICE_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierDeviceOperationFinished: false
            }
        case "DELETE_COPIER_DEVICE_RESPONSE":
            return {
                ...state,
                copierDevices: state.copierDevices.filter(device => device.id !== action.result.data),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierDeviceOperationFinished: true
            }
        case "CLEAR_COPIER_DEVICES":
            return {
                ...state,
                copierDevices: []
            }
        case "GET_TECHNICIANS_REQUEST":
            return {
                ...state,
                technicians: [],
                loading: state.loading + 1
            }
        case "GET_TECHNICIANS_RESPONSE":
            return {
                ...state,
                technicians: action.result.data,
                loading: state.loading - 1
            }
        case "CREATE_TECHNICIAN_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                technicianOperationFinished: false
            }
        case "CREATE_TECHNICIAN_RESPONSE":
            return {
                ...state,
                technicians: [action.result.data].concat(state.technicians),
                loading: state.loading - 1,
                result: !action.result.hasError,
                technicianOperationFinished: true
            }
        case "UPDATE_TECHNICIAN_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                technicianOperationFinished: false
            }
        case "UPDATE_TECHNICIAN_RESPONSE":
            const updatedTechnician = action.result.data;
            return {
                ...state,
                technicians: state.technicians.map(technician => {
                    if (technician.id === updatedTechnician.id) {
                        return updatedTechnician;
                    }

                    return technician;
                }),
                loading: state.loading - 1,
                result: !action.result.hasError,
                technicianOperationFinished: true
            }
        case "DELETE_TECHNICIAN_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                technicianOperationFinished: false
            }
        case "DELETE_TECHNICIAN_RESPONSE":
            return {
                ...state,
                technicians: state.technicians.filter(technician => technician.id !== action.result.data),
                loading: state.loading - 1,
                result: !action.result.hasError,
                technicianOperationFinished: true
            }
        case "CLEAR_TECHNICIANS":
            return {
                ...state,
                technicians: []
            }
        case "GET_CLIENTS_REQUEST":
            return {
                ...state,
                clients: [],
                loading: state.loading + 1
            }
        case "GET_CLIENTS_RESPONSE":
            return {
                ...state,
                clients: action.result.data,
                loading: state.loading - 1
            }
        case "CREATE_CLIENT_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                clientOperationFinished: false
            }
        case "CREATE_CLIENT_RESPONSE":
            return {
                ...state,
                clients: [action.result.data].concat(state.clients),
                loading: state.loading - 1,
                result: !action.result.hasError,
                clientOperationFinished: true
            }
        case "UPDATE_CLIENT_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                clientOperationFinished: false
            }
        case "UPDATE_CLIENT_RESPONSE":
            const updatedClient = action.result.data;
            return {
                ...state,
                clients: state.clients.map(client => {
                    if (client.id === updatedClient.id) {
                        return updatedClient;
                    }

                    return client;
                }),
                loading: state.loading - 1,
                result: !action.result.hasError,
                clientOperationFinished: true
            }
        case "DELETE_CLIENT_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                clientOperationFinished: false
            }
        case "DELETE_CLIENT_RESPONSE":
            return {
                ...state,
                clients: state.clients.filter(client => client.id !== action.result.data),
                loading: state.loading - 1,
                result: !action.result.hasError,
                clientOperationFinished: true
            }
        case "CLEAR_CLIENTS":
            return {
                ...state,
                clients: []
            }
        case "GET_COPIER_TICKET_TYPES_REQUEST":
            return {
                ...state,
                copierTicketTypes: [],
                loading: state.loading + 1
            }
        case "GET_COPIER_TICKET_TYPES_RESPONSE":
            return {
                ...state,
                copierTicketTypes: action.result.data,
                loading: state.loading - 1
            }
        case "CREATE_COPIER_TICKET_TYPE_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierTicketTypeOperationFinished: false
            }
        case "CREATE_COPIER_TICKET_TYPE_RESPONSE":
            return {
                ...state,
                copierTicketTypes: [action.result.data].concat(state.copierTicketTypes),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierTicketTypeOperationFinished: true
            }
        case "UPDATE_COPIER_TICKET_TYPE_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierTicketTypeOperationFinished: false
            }
        case "UPDATE_COPIER_TICKET_TYPE_RESPONSE":
            const updatedCopierTicketType = action.result.data;
            return {
                ...state,
                copierTicketTypes: state.copierTicketTypes.map(copierTicketType => {
                    if (copierTicketType.id === updatedCopierTicketType.id) {
                        return updatedCopierTicketType;
                    }

                    return copierTicketType;
                }),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierTicketTypeOperationFinished: true
            }
        case "DELETE_COPIER_TICKET_TYPE_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierTicketTypeOperationFinished: false
            }
        case "DELETE_COPIER_TICKET_TYPE_RESPONSE":
            return {
                ...state,
                copierTicketTypes: state.copierTicketTypes.filter(copierTicketType => copierTicketType.id !== action.result.data),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierTicketTypeOperationFinished: true
            }
        case "CLEAR_COPIER_TICKET_TYPES":
            return {
                ...state,
                copierTicketTypes: []
            }
        case "GET_COPIER_TICKET_SOLUTION_TYPES_REQUEST":
            return {
                ...state,
                copierTicketSolutionTypes: [],
                loading: state.loading + 1
            }
        case "GET_COPIER_TICKET_SOLUTION_TYPES_RESPONSE":
            return {
                ...state,
                copierTicketSolutionTypes: action.result.data,
                loading: state.loading - 1
            }
        case "CREATE_COPIER_TICKET_SOLUTION_TYPE_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierTicketSolutionTypeOperationFinished: false
            }
        case "CREATE_COPIER_TICKET_SOLUTION_TYPE_RESPONSE":
            return {
                ...state,
                copierTicketSolutionTypes: [action.result.data].concat(state.copierTicketSolutionTypes),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierTicketSolutionTypeOperationFinished: true
            }
        case "UPDATE_COPIER_TICKET_SOLUTION_TYPE_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierTicketSolutionTypeOperationFinished: false
            }
        case "UPDATE_COPIER_TICKET_SOLUTION_TYPE_RESPONSE":
            const updatedCopierTicketSolutionType = action.result.data;
            return {
                ...state,
                copierTicketSolutionTypes: state.copierTicketSolutionTypes.map(copierTicketSolutionType => {
                    if (copierTicketSolutionType.id === updatedCopierTicketSolutionType.id) {
                        return updatedCopierTicketSolutionType;
                    }

                    return copierTicketSolutionType;
                }),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierTicketSolutionTypeOperationFinished: true
            }
        case "DELETE_COPIER_TICKET_SOLUTION_TYPE_REQUEST":
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                copierTicketSolutionTypeOperationFinished: false
            }
        case "DELETE_COPIER_TICKET_SOLUTION_TYPE_RESPONSE":
            return {
                ...state,
                copierTicketSolutionTypes: state.copierTicketSolutionTypes.filter(copierTicketSolutionType => copierTicketSolutionType.id !== action.result.data),
                loading: state.loading - 1,
                result: !action.result.hasError,
                copierTicketSolutionTypeOperationFinished: true
            }
        case "CLEAR_COPIER_TICKET_SOLUTION_TYPES":
            return {
                ...state,
                copierTicketSolutionTypes: []
            }
        case "GET_COPIER_COUNTER_STATEMENTS_REQUEST":
            return {
                ...state,
                copierCounterStatements: [],
                loading: state.loading + 1
            }
        case "GET_COPIER_COUNTER_STATEMENTS_RESPONSE":
            return {
                ...state,
                copierCounterStatements: action.result.data,
                loading: state.loading - 1
            }
        case "CLEAR_COPIER_COUNTER_STATEMENTS":
            return {
                ...state,
                copierCounterStatements: []
            }
        case "GET_NOT_ANNOUNCED_COPIER_COUNTER_STATEMENTS_REQUEST":
            return {
                ...state,
                notAnnouncedCopierCounterStatements: [],
                loading: state.loading + 1
            }
        case "GET_NOT_ANNOUNCED_COPIER_COUNTER_STATEMENTS_RESPONSE":
            return {
                ...state,
                notAnnouncedCopierCounterStatements: action.result.data,
                loading: state.loading - 1
            }
        case "CLEAR_NOT_ANNOUNCED_COPIER_COUNTER_STATEMENTS":
            return {
                ...state,
                notAnnouncedCopierCounterStatements: []
            }
        case "GET_ACTIVE_COPIER_SUPPORT_TICKETS_REQUEST":
            return {
                ...state,
                activeCopierSupportTickets: [],
                loading: state.loading + 1
            }
        case "GET_ACTIVE_COPIER_SUPPORT_TICKETS_RESPONSE":
            return {
                ...state,
                activeCopierSupportTickets: action.result.data,
                loading: state.loading - 1
            }
        case "CLEAR_ACTIVE_COPIER_SUPPORT_TICKETS":
            return {
                ...state,
                activeCopierSupportTickets: []
            }
        case "GET_INACTIVE_COPIER_SUPPORT_TICKETS_REQUEST":
            return {
                ...state,
                inactiveCopierSupportTickets: [],
                loading: state.loading + 1
            }
        case "GET_INACTIVE_COPIER_SUPPORT_TICKETS_RESPONSE":
            return {
                ...state,
                inactiveCopierSupportTickets: action.result.data,
                loading: state.loading - 1
            }
        case "CLEAR_INACTIVE_COPIER_SUPPORT_TICKETS":
            return {
                ...state,
                inactiveCopierSupportTickets: []
            }
        case "GET_SCHEDULED_COPIER_SUPPORT_TICKETS_REQUEST":
            return {
                ...state,
                scheduledCopierSupportTickets: [],
                loading: state.loading + 1
            }
        case "GET_SCHEDULED_COPIER_SUPPORT_TICKETS_RESPONSE":
            return {
                ...state,
                scheduledCopierSupportTickets: action.result.data,
                loading: state.loading - 1
            }
        case "CLEAR_SCHEDULED_COPIER_SUPPORT_TICKETS":
            return {
                ...state,
                scheduledCopierSupportTickets: []
            }
        case "CLEAR_RESULT":
            return { ...state, result: null };
        case "ERROR":
            return { ...state, result: false, loading: state.loading - 1 };
        default:
            return state;
    }
};
