import { ProtectedRestApi } from "./";
import { CopierModel, ServiceResult } from "../models";
import { CopierModelCreatePayload, CopierModelUpdatePayload } from "../payloads";

export class CopierModelApi extends ProtectedRestApi {
    private static baseUrl = "/api/copier-model";

    public getAll(): Promise<ServiceResult<CopierModel[]>> {
        return this.httpGet(`${CopierModelApi.baseUrl}/all`) as Promise<ServiceResult<CopierModel[]>>;
    }

    public create(payload: CopierModelCreatePayload): Promise<ServiceResult<CopierModel>> {
        return this.httpPut(`${CopierModelApi.baseUrl}`, payload) as Promise<ServiceResult<CopierModel>>;
    }

    public update(id: number, payload: CopierModelUpdatePayload): Promise<ServiceResult<CopierModel>> {
        return this.httpPost(`${CopierModelApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<CopierModel>>;
    }

    public delete(id: number): Promise<ServiceResult<number>> {
        return this.httpDelete(`${CopierModelApi.baseUrl}/${id}`) as Promise<ServiceResult<number>>;
    }
}
