import * as React from 'react';
import { MenuItem } from "../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import HoverDropdown from "./HoverDropdown";

interface NavMenuItemGroupProps {
    menuItem: MenuItem;
    isLast?: boolean;
    items: MenuItem[];
    right?: boolean;
    className?: string;
    backgroundColor?: string;
}

export const NavMenuItemGroup = (props: NavMenuItemGroupProps) => {
    return (
        <React.Fragment>
            <HoverDropdown inNavbar>
                <DropdownToggle className={`nav-link nav-menu-item-link d-flex flex-column align-items-center text-white ${props.className == null ? "" : props.className}`} style={{ backgroundColor: props.backgroundColor == null ? "transparent" : props.backgroundColor }} nav caret>
                    {props.menuItem.customIcon == null && <FontAwesomeIcon icon={["fas", props.menuItem.icon]} className="fa-fw nav-menu-item-icon" />}
                    {props.menuItem.customIcon != null && <img src={props.menuItem.customIcon} className="nav-menu-custom-icon" />}
                    <span>{props.menuItem.text}</span>
                </DropdownToggle>
                <DropdownMenu right={props.right}>
                    {props.items.map((item, index) => {
                        return (
                            <React.Fragment key={`menu-item-group-item-${index}`}>
                                <a className="nav-link nav-menu-item-link d-flex align-items-start align-items-center dropdown-item" role="menuitem" href={item.target}>
                                    {item.customIcon == null && <FontAwesomeIcon icon={["fas", item.icon]} className="fa-fw nav-menu-item-dropdown-icon" />}
                                    {item.customIcon != null && <img src={item.customIcon} className="nav-menu-dropdown-custom-icon" />}
                                    <span className="ml-2">{item.text}</span>
                                </a>
                            </React.Fragment>
                        );
                    })}
                </DropdownMenu>
            </HoverDropdown>
            {!props.isLast && <span className="navbar-vertical-divider" />}
        </React.Fragment>
    );
}

export default NavMenuItemGroup;
