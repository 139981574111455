import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { LoadingIndicator } from "./common";

type MainRedirectPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface MainRedirectState {
    redirectLocation: string | null;
}

class MainRedirect extends React.PureComponent<MainRedirectPropsType, MainRedirectState> {
    constructor(props: MainRedirectPropsType) {
        super(props);

        this.state = {
            redirectLocation: null
        }
    }

    componentWillReceiveProps(nextProps: MainRedirectPropsType) {
        if (this.props.loggedInUser == null && nextProps.loggedInUser != null) {
            const user = nextProps.loggedInUser;
            if (user.isAdmin) {
                this.setState({
                    redirectLocation: "/admin/technicians"
                });
            } else if (user.isTechnician) {
                this.setState({
                    redirectLocation: "/admin/tickets"
                });
            } else {
                this.setState({
                    redirectLocation: "/my"
                });
            }
        }
    }

    public render() {
        if (this.state.redirectLocation == null) {
            return (
                <React.Fragment>
                    <LoadingIndicator show={true} />
                </React.Fragment>
            );
        }

        return <Redirect to={this.state.redirectLocation} />;
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(MainRedirect as any);
