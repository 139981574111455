import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Modal, ModalHeader, ModalBody, Tooltip } from 'reactstrap';
import { LoadingIndicator, ToastContainer } from "./common";
import { TechnicianCreatePayload, TechnicianUpdatePayload } from "../payloads";
import TechnicianItem from "./TechnicianItem";

type TechniciansPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface TechniciansState {
    result: boolean | null;
    toastOpen: boolean;
    editIndex: number;
    deleteIndex: number;
    validationErrors: string[];
    technicianId: number;
    technicianDisplayItemsForAllTechnicians: boolean;
    technicianName: string;
    technicianEmail: string;
    technicianPassword: string;
    technicianDisplayItemsForAllTechniciansTooltipOpen: boolean;
}

class Technicians extends React.PureComponent<TechniciansPropsType, TechniciansState> {
    constructor(props: TechniciansPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            editIndex: NaN,
            deleteIndex: NaN,
            validationErrors: [],
            technicianId: 0,
            technicianDisplayItemsForAllTechnicians: false,
            technicianName: "",
            technicianEmail: "",
            technicianPassword: "",
            technicianDisplayItemsForAllTechniciansTooltipOpen: false
        }
    }

    componentWillReceiveProps(nextProps: TechniciansPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><FontAwesomeIcon icon={["fas", "wrench"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Technikusok {`(${this.props.technicians.length})`}</span></h4>

                <div className="d-flex justify-content-end">
                    <Button onClick={this.handleCreateTechnicianClick} color="success" className="lansoft-success">
                        <FontAwesomeIcon icon={["fas", "plus"]} /> Új technikus
                    </Button>
                </div>

                <ul className="list-group mt-3 diagonal-background-flat diagonal-background-lansoft-primary position-relative no-left-border-radius">
                    {this.props.technicians.map((technician, index) =>
                        <li className="list-group-item" key={`technician-list-item-${index}`}>
                            <TechnicianItem
                                technician={technician}
                                editTechnician={() => this.handleEditTechnicianClick(index)}
                                deleteTechnician={() => this.handleDeleteTechnicianClick(index)}
                            />
                        </li>
                    )}
                </ul>

                {this.getEditModal()}
                {this.getDeleteModal()}
                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editIndex) && !this.props.loading && this.props.technicians.length > this.state.editIndex} toggle={this.closeEditModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeEditModal}>Technikus {this.state.editIndex === -1 ? "létrehozása" : "szerkesztése"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputTechnicianName">Név</label>
                        <input type="text" className="form-control" id="inputTechnicianName" autoComplete="off" placeholder="Név" value={this.state.technicianName} onChange={this.handleTechnicianNameChange} />
                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="inputTechnicianEmail">E-mail cím</label>
                        <input type="text" className="form-control" id="inputTechnicianEmail" autoComplete="off" placeholder="E-mail cím" value={this.state.technicianEmail} onChange={this.handleTechnicianEmailChange} />
                    </div>

                    {this.state.editIndex === -1 &&
                        <div className="form-group mt-2">
                            <label htmlFor="inputTechnicianPassword">Jelszó</label>
                            <input type="password" className="form-control" id="inputTechnicianPassword" autoComplete="off" placeholder="Jelszó" value={this.state.technicianPassword} onChange={this.handleTechnicianPasswordChange} />
                        </div>
                    }

                    <div className="form-check mt-2">
                        <div className="d-flex justify-content-start">
                            <input type="checkbox" className="form-check-input" id="inputTechnicianDisplayItemsForAllTechnicians" checked={this.state.technicianDisplayItemsForAllTechnicians} onChange={this.handleTechnicianDisplayItemsForAllTechniciansChange} />
                            <label className="form-check-label" htmlFor="inputTechnicianDisplayItemsForAllTechnicians">Az összes technikushoz tartozó elem listázásának alapértelmezett értéke</label>
                            <span className="ml-2" id="inputEditTechnicianDisplayItemsForAllTechniciansLabel"><FontAwesomeIcon icon={["fas", "info-circle"]} /></span>
                            <Tooltip placement="top" isOpen={this.state.technicianDisplayItemsForAllTechniciansTooltipOpen} target="inputEditTechnicianDisplayItemsForAllTechniciansLabel" toggle={this.toggleTechnicianDisplayItemsForAllTechniciansTooltip}>
                                Abban az esetben, ha pipát helyezünk a jelölőnégyzetbe, akkor minden nézetben, minden technikushoz tartozó elem listázásra kerül. Üres jelölőnégyzet esetén csak a saját elemeink kerülnek listázásra a különböző nézetekben. A beállítást a későbbiekben is van lehetőségünk módosítani külön-külön minden nézet tetején található jelölőnégyzet segítségével.
                            </Tooltip>
                        </div>
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`technician-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveTechnicianClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteIndex) && !this.props.loading} toggle={this.closeDeleteModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeDeleteModal}>Technikus törlése</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan törölni szeretnéd az alábbi technikust?
                        </p>
                        <p>{!isNaN(this.state.deleteIndex) && <strong>{this.props.technicians[this.state.deleteIndex].name}</strong>}</p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmDeleteTechnicianClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private toggleTechnicianDisplayItemsForAllTechniciansTooltip = () => {
        this.setState({
            technicianDisplayItemsForAllTechniciansTooltipOpen: !this.state.technicianDisplayItemsForAllTechniciansTooltipOpen
        });
    }

    private handleCancelEditClick = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleSaveTechnicianClick = () => {
        if (this.state.technicianId === 0) {
            const payload: TechnicianCreatePayload = {
                displayItemsForAllTechnicians: this.state.technicianDisplayItemsForAllTechnicians,
                user: {
                    name: this.state.technicianName,
                    email: this.state.technicianEmail,
                    password: this.state.technicianPassword
                }
            };

            this.props.createTechnician(payload);
        } else {
            const payload: TechnicianUpdatePayload = {
                displayItemsForAllTechnicians: this.state.technicianDisplayItemsForAllTechnicians,
                user: {
                    name: this.state.technicianName,
                    email: this.state.technicianEmail
                }
            };

            this.props.updateTechnician(this.state.technicianId, payload);
        }

        this.setState({
            editIndex: NaN
        });
    }

    private handleConfirmDeleteTechnicianClick = () => {
        const technician = this.props.technicians[this.state.deleteIndex];
        this.props.deleteTechnician(technician.id);
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleTechnicianDisplayItemsForAllTechniciansChange = (e: any) => {
        this.setState({
            technicianDisplayItemsForAllTechnicians: e.target.checked
        });
    }

    private handleTechnicianNameChange = (e: any) => {
        this.setState({
            technicianName: e.target.value
        }, () => { this.validate(true); });
    }

    private handleTechnicianEmailChange = (e: any) => {
        this.setState({
            technicianEmail: e.target.value
        }, () => { this.validate(true); });
    }

    private handleTechnicianPasswordChange = (e: any) => {
        this.setState({
            technicianPassword: e.target.value
        }, () => { this.validate(true); });
    }

    private closeEditModal = () => {
        this.setState({
            editIndex: NaN
        });
    }

    private closeDeleteModal = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleCreateTechnicianClick = () => {
        this.setState({
            editIndex: -1,
            technicianId: 0,
            technicianName: "",
            technicianEmail: "",
            technicianPassword: "",
            technicianDisplayItemsForAllTechnicians: false,
            validationErrors: []
        });
    }

    private handleEditTechnicianClick = (index: number) => {
        const technician = this.props.technicians[index];
        this.setState({
            editIndex: index,
            technicianId: technician.id,
            technicianName: technician.name,
            technicianEmail: technician.email,
            technicianPassword: "",
            technicianDisplayItemsForAllTechnicians: technician.displayItemsForAllTechnicians
        }, () => this.validate(true));
    }

    private handleDeleteTechnicianClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.technicianName.length === 0) {
            validationErrors.push("A név megadása kötelező.");
        }

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(this.state.technicianEmail.toLowerCase())) {
            validationErrors.push("Érvényes e-mail cím megadása kötelező.");
        }

        if (this.state.editIndex === -1) {
            const password = this.state.technicianPassword;

            if (password.length < 6) {
                validationErrors.push("A jelszónak legalább 6 karakter hosszúnak kell lennie.");
            }

            if (!/[A-Z]/.test(password)) {
                validationErrors.push("A jelszónak kötelező nagybetűt tartalmaznia.");
            }

            if (!/[a-z]/.test(password)) {
                validationErrors.push("A jelszónak kötelező kisbetűt tartalmaznia.");
            }

            if (!/[0-9]/.test(password)) {
                validationErrors.push("A jelszónak kötelező számjegyet tartalmaznia.");
            }
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(Technicians as any);
