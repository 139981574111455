import { ProtectedRestApi } from "./";
import { Technician, ServiceResult } from "../models";
import { TechnicianCreatePayload, TechnicianUpdatePayload } from "../payloads";

export class TechnicianApi extends ProtectedRestApi {
    private static baseUrl = "/api/technician";

    public getAll(): Promise<ServiceResult<Technician[]>> {
        return this.httpGet(`${TechnicianApi.baseUrl}/all`) as Promise<ServiceResult<Technician[]>>;
    }

    public create(payload: TechnicianCreatePayload): Promise<ServiceResult<Technician>> {
        return this.httpPut(`${TechnicianApi.baseUrl}`, payload) as Promise<ServiceResult<Technician>>;
    }

    public update(id: number, payload: TechnicianUpdatePayload): Promise<ServiceResult<Technician>> {
        return this.httpPost(`${TechnicianApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<Technician>>;
    }

    public delete(id: number): Promise<ServiceResult<number>> {
        return this.httpDelete(`${TechnicianApi.baseUrl}/${id}`) as Promise<ServiceResult<number>>;
    }
}
