import { ProtectedRestApi } from "./";
import { CopierContract, CopierCounterStatement, CopierSupportTicket, Invoice, ServiceResult } from "../models";
import { CopierContractCreatePayload, CopierContractUpdatePayload, CopierCounterStatementCreatePayload, CopierScheduledTicketSolvePayload, CopierSupportTicketCreatePayload, CopierSupportTicketSolvePayload } from "../payloads";

export class CopierContractApi extends ProtectedRestApi {
    private static baseUrl = "/api/copier-contract";

    public getMy(): Promise<ServiceResult<CopierContract[]>> {
        return this.httpGet(`${CopierContractApi.baseUrl}/my`) as Promise<ServiceResult<CopierContract[]>>;
    }

    public getByClient(clientId: number): Promise<ServiceResult<CopierContract[]>> {
        return this.httpGet(`${CopierContractApi.baseUrl}/by-client/${clientId}`) as Promise<ServiceResult<CopierContract[]>>;
    }

    public getByTechnician(technicianId: number): Promise<ServiceResult<CopierContract[]>> {
        return this.httpGet(`${CopierContractApi.baseUrl}/by-technician/${technicianId}`) as Promise<ServiceResult<CopierContract[]>>;
    }

    public getAll(): Promise<ServiceResult<CopierContract[]>> {
        return this.httpGet(`${CopierContractApi.baseUrl}/all`) as Promise<ServiceResult<CopierContract[]>>;
    }

    public getAllIncludingEnded(): Promise<ServiceResult<CopierContract[]>> {
        return this.httpGet(`${CopierContractApi.baseUrl}/all-including-ended`) as Promise<ServiceResult<CopierContract[]>>;
    }

    public create(payload: CopierContractCreatePayload): Promise<ServiceResult<CopierContract>> {
        return this.httpPut(`${CopierContractApi.baseUrl}`, payload) as Promise<ServiceResult<CopierContract>>;
    }

    public update(id: number, payload: CopierContractUpdatePayload): Promise<ServiceResult<CopierContract>> {
        return this.httpPost(`${CopierContractApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<CopierContract>>;
    }

    public close(id: number): Promise<ServiceResult<number>> {
        return this.httpPost(`${CopierContractApi.baseUrl}/${id}/close`) as Promise<ServiceResult<number>>;
    }

    public delete(id: number): Promise<ServiceResult<number>> {
        return this.httpDelete(`${CopierContractApi.baseUrl}/${id}`) as Promise<ServiceResult<number>>;
    }

    public createStatement(contractId: number, payload: CopierCounterStatementCreatePayload): Promise<ServiceResult<CopierCounterStatement>> {
        return this.httpPut(`${CopierContractApi.baseUrl}/${contractId}/statement`, payload) as Promise<ServiceResult<CopierCounterStatement>>;
    }

    public createInvoice(contractId: number, statementId: number, file: File): Promise<ServiceResult<Invoice>> {
        const formData = new FormData();
        formData.append('file', file);

        return this.httpPutForm(`${CopierContractApi.baseUrl}/${contractId}/statement/${statementId}/invoice`, formData) as Promise<ServiceResult<Invoice>>;
    }

    public createSupportTicket(contractId: number, payload: CopierSupportTicketCreatePayload): Promise<ServiceResult<CopierSupportTicket>> {
        return this.httpPut(`${CopierContractApi.baseUrl}/${contractId}/support-ticket`, payload) as Promise<ServiceResult<CopierSupportTicket>>;
    }

    public solveSupportTicket(contractId: number, ticketId: number, payload: CopierSupportTicketSolvePayload): Promise<ServiceResult<CopierSupportTicket>> {
        return this.httpPost(`${CopierContractApi.baseUrl}/${contractId}/support-ticket/${ticketId}/solve`, payload) as Promise<ServiceResult<CopierSupportTicket>>;
    }

    public solveScheduledTicket(contractId: number, ticketId: number, payload: CopierScheduledTicketSolvePayload): Promise<ServiceResult<CopierSupportTicket>> {
        return this.httpPost(`${CopierContractApi.baseUrl}/${contractId}/scheduled-ticket/${ticketId}/solve`, payload) as Promise<ServiceResult<CopierSupportTicket>>;
    }
}
