import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopierContract } from "../models";
import { Button } from "reactstrap";

interface CopierContractAdminItemProps {
    className?: string;
    contract: CopierContract;
    editContract?: () => void;
    closeContract?: () => void;
    deleteContract?: () => void;
}

export const CopierContractAdminItem = (props: CopierContractAdminItemProps) => (
    <div className={`d-flex align-items-center justify-content-between ${(props.className != null ? props.className : "")}`}>
        <div className="h5 mb-0 d-flex"><div>{props.contract.contractNumber}</div><div className="ml-2">{props.contract.endDate != null && <FontAwesomeIcon icon={["fas", "lock"]} />}</div></div>
        <div>
            <Button color="warning" className={`lansoft-warning ${props.closeContract == null || props.contract.endDate != null ? " invisible" : ""}`} onClick={props.closeContract} data-toggle="tooltip" data-placement="bottom" title="Lezárás"><FontAwesomeIcon icon={["fas", "lock"]} /></Button>
            <Button color="primary" className={`lansoft-primary ml-3 ${props.editContract == null ? "invisible" : ""}`} onClick={props.editContract} data-toggle="tooltip" data-placement="bottom" title="Szerkesztés"><FontAwesomeIcon icon={["fas", "pencil-alt"]} /></Button>
            <Button color="danger" className={`lansoft-danger ml-3 ${props.deleteContract == null ? " invisible" : ""}`} onClick={props.deleteContract} data-toggle="tooltip" data-placement="bottom" title="Törlés"><FontAwesomeIcon icon={["fas", "trash-alt"]} /></Button>
        </div>
    </div>
);

export default CopierContractAdminItem;
