import * as React from 'react';
import { Technician } from "../models";

interface TechnicianInfoProps {
    technician: Technician;
    systemMessageCount: number;
    activeTicketCount: number;
    scheduledTicketCount: number;
}

export const TechnicianInfo = (props: TechnicianInfoProps) => (
    <div className="container">
        <div className="d-flex align-items-center justify-content-between">
            <div className="h5">Technikus: {props.technician.name}</div>
            <div className="p-3 border lansoft-border-success">
                <div>Rendszerüzenetek: {props.systemMessageCount}</div>
                <div>Aktív hibajegyek: {props.activeTicketCount}</div>
                <div>Ütemezett karbantartás: {props.scheduledTicketCount}</div>
            </div>
        </div>
    </div>
);

export default TechnicianInfo;
