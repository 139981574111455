import * as React from 'react';
import { Toast, ToastBody } from 'reactstrap';

interface ToastContainerProps {
    open: boolean;
    success: boolean;
    color: "primary" | "secondary" | "success" | "danger" | "warning" | "info";
}

export const ToastContainer = ({ open, color, success }: ToastContainerProps) => (
    <div className="toast-container">
        <Toast isOpen={open} className={`toast-${color}`}>
            <ToastBody>
                {success ? "Sikeres művelet." : "Sikertelen művelet."}
            </ToastBody>
        </Toast>
    </div>
);
