import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { LoadingIndicator, ToastContainer } from "./common";
import { CopierContract, CopierSupportTicket, CopierTicketState, Technician } from '../models';
import CopierSupportTicketCard from "./CopierSupportTicketCard";
import ScheduledCopierTicketCard from "./ScheduledCopierTicketCard";
import { getIndicatorClassFromTicket, getMonthNameFromMonthNumber } from "../utils/EnumUtils";
import CopierContractCard from "./CopierContractCard";
import { CopierScheduledTicketSolvePayload, CopierSupportTicketSolvePayload } from '../payloads';

type TicketsPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface TicketsState {
    result: boolean | null;
    toastOpen: boolean;
    expandedIndexActive: number;
    expandedIndexInactive: number;
    expandedIndexScheduled: number;
    showAll: boolean;
    showAllContracts: boolean;
    yearDropdownOpen: boolean;
    monthDropdownOpen: boolean;
    year: number;
    month: number;
    showAllSet: boolean;
    copierContractExpandedIndex: number;
    search: string;
    filteredCopierContracts: CopierContract[];
    currentTechnician: Technician | null;
}

class Tickets extends React.PureComponent<TicketsPropsType, TicketsState> {
    private newTicketTabNavLinkRef: HTMLAnchorElement | null;

    constructor(props: TicketsPropsType) {
        super(props);

        this.newTicketTabNavLinkRef = null;

        this.state = {
            result: null,
            toastOpen: false,
            expandedIndexActive: NaN,
            expandedIndexInactive: NaN,
            expandedIndexScheduled: NaN,
            showAll: false,
            showAllContracts: false,
            yearDropdownOpen: false,
            monthDropdownOpen: false,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            showAllSet: false,
            copierContractExpandedIndex: NaN,
            search: "",
            filteredCopierContracts: [],
            currentTechnician: null
        }
    }

    componentWillMount() {
        this.props.getAllCopierTicketTypes();
        this.props.getAllCopierTicketSolutionTypes();
        this.props.getAllCopierContracts();
    }

    componentWillReceiveProps(nextProps: TicketsPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }

        if (!this.state.showAllSet && nextProps.loggedInUser != null && nextProps.technicians.length > 0) {
            const technician = nextProps.technicians.find(x => x.user.id === nextProps.loggedInUser!!.id);
            if (technician != null) {
                this.setState({
                    showAll: technician.displayItemsForAllTechnicians,
                    showAllContracts: technician.displayItemsForAllTechnicians,
                    showAllSet: true,
                    currentTechnician: technician,
                    filteredCopierContracts: this.filterCopierContracts(nextProps.copierContracts, this.state.search, technician.displayItemsForAllTechnicians, technician.id)
                });

                this.props.getActiveCopierSupportTickets(!technician.displayItemsForAllTechnicians);
                this.props.getInactiveCopierSupportTickets(!technician.displayItemsForAllTechnicians);
                this.props.getScheduledCopierSupportTickets(!technician.displayItemsForAllTechnicians);
            }
        }

        if (nextProps.copierContracts.length !== this.props.copierContracts.length && this.state.currentTechnician != null) {
            this.setState({
                filteredCopierContracts: this.filterCopierContracts(nextProps.copierContracts, this.state.search, this.state.showAllContracts, this.state.currentTechnician!!.id)
            });
        }

        if ((nextProps.activeCopierSupportTickets.length !== this.props.activeCopierSupportTickets.length
            || nextProps.scheduledCopierSupportTickets.length !== this.props.scheduledCopierSupportTickets.length
            || (!this.props.copierContractOperationFinished && nextProps.copierContractOperationFinished)
            || (!this.props.copierCounterStatementOperationFinished && nextProps.copierCounterStatementOperationFinished)
            || (!this.props.copierSupportTicketOperationFinished && nextProps.copierSupportTicketOperationFinished) && this.state.currentTechnician != null)) {
            this.setState({
                filteredCopierContracts: this.filterCopierContracts(nextProps.copierContracts, this.state.search, this.state.showAllContracts, this.state.currentTechnician!!.id)
            });
        }
    }

    public render() {
        const allTickets = this.props.activeCopierSupportTickets.concat(this.props.inactiveCopierSupportTickets).concat(this.props.scheduledCopierSupportTickets);
        const activeTickets = this.filterTickets(this.props.activeCopierSupportTickets);
        const inactiveTickets = this.filterTickets(this.props.inactiveCopierSupportTickets);
        const scheduledTickets = this.filterTickets(this.props.scheduledCopierSupportTickets);
        const years = [...new Set(allTickets.map(ticket => new Date(ticket.createdAt).getFullYear()))];
        const months = [...new Set(allTickets.filter(ticket => new Date(ticket.createdAt).getFullYear() === this.state.year).map(ticket => new Date(ticket.createdAt).getMonth() + 1))];

        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><FontAwesomeIcon icon={["fas", "exclamation-circle"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Hibák</span></h4>

                <div className="d-flex justify-content-between align-items-center mt-4 mb-4">
                    <div className="d-flex justify-content-start">
                        <div className="form-group form-inline my-2">
                            <Dropdown isOpen={this.state.yearDropdownOpen} toggle={this.toggleYearDropdown}>
                                <DropdownToggle className="lansoft-success" caret>
                                    <span className="mr-2">{isNaN(this.state.year) ? "Év" : this.state.year}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {years.sort().map((year, index) =>
                                        <DropdownItem active={this.state.year === year} onClick={() => this.handleYearChange(year)} key={`year-dropdown-item-${index}`}>{year}</DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="form-group form-inline ml-3 my-2">
                            <Dropdown isOpen={this.state.monthDropdownOpen} toggle={this.toggleMonthDropdown}>
                                <DropdownToggle className="lansoft-success w-120-px" caret>
                                    <span className="mr-2">{isNaN(this.state.month) ? "Hónap" : getMonthNameFromMonthNumber(this.state.month)}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {months.sort().map((month, index) =>
                                        <DropdownItem active={this.state.month === month} onClick={() => this.handleMonthChange(month)} key={`month-dropdown-item-${index}`}>{getMonthNameFromMonthNumber(month)}</DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className={(this.newTicketTabNavLinkRef != null && this.newTicketTabNavLinkRef.classList.contains("active")) ? "invisible" : ""}>
                        <div className="form-check mt-3">
                            <input type="checkbox" className="form-check-input" id="inputShowAll" checked={this.state.showAll} onChange={this.handleShowAllChange} />
                            <label className="form-check-label" htmlFor="inputShowAll">Az összes technikus hibajegyeinek listázása</label>
                        </div>
                    </div>
                </div>

                <div className="tabbable full-width-tabs tickets-tabs">
                    <ul className="nav nav-tabs mb-3 d-flex justify-content-start align-items-center" id="tickets-tab-list" role="tablist">
                        <li className="d-flex">
                            <a onClick={() => this.forceUpdate()} className="nav-link active" id="active-tickets-tab" data-toggle="pill" href="#active-tickets" role="tab" aria-controls="active" aria-selected="true">Aktív ({activeTickets.length})</a>
                        </li>
                        <li className="d-flex">
                            <a onClick={() => this.forceUpdate()} className="nav-link" id="scheduled-tickets-tab" data-toggle="pill" href="#scheduled-tickets" role="tab" aria-controls="scheduled" aria-selected="false">Ütemezett ({scheduledTickets.length})</a>
                        </li>
                        <li className="d-flex">
                            <a onClick={() => this.forceUpdate()} className="nav-link" id="inactive-tickets-tab" data-toggle="pill" href="#inactive-tickets" role="tab" aria-controls="inactive" aria-selected="true">Inaktív ({inactiveTickets.length})</a>
                        </li>
                        <li className="d-flex">
                            <a onClick={() => this.forceUpdate()} ref={node => { this.newTicketTabNavLinkRef = node; }} className="nav-link" id="new-ticket-tab" data-toggle="pill" href="#new-ticket" role="tab" aria-controls="new" aria-selected="false">Új hiba</a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="tickets-tab-content">
                    <div className="tab-pane fade show active" id="active-tickets" role="tabpanel" aria-labelledby="active-tickets-tab">
                        <ul className="list-group mt-3">
                            {this.props.copierContracts.length > 0 && activeTickets.map((ticket, index) =>
                                <li className="list-group-item-container" key={`active-ticket-list-item-${index}-${ticket.id}`}>
                                    <div className={`list-group-item list-margin diagonal-background diagonal-background-${getIndicatorClassFromTicket(ticket)}`}>
                                        <CopierSupportTicketCard
                                            contract={this.props.copierContracts.find(x => x.id === ticket.contractId)!!}
                                            ticket={ticket}
                                            expandCollapse={() => this.handleActiveCardExpandCollapseClick(index)}
                                            isExpanded={this.state.expandedIndexActive === index}
                                            ticketSolutionTypes={this.props.copierTicketSolutionTypes}
                                            handleSolveClick={payload => this.handleSolveSupportTicketClick(ticket.contractId, ticket.id, payload)}
                                        />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="scheduled-tickets" role="tabpanel" aria-labelledby="scheduled-tickets-tab">
                        <div className="list-group mt-3">
                            {this.props.copierContracts.length > 0 && scheduledTickets.map((ticket, index) =>
                                <li className="list-group-item-container" key={`scheduled-ticket-list-item-${index}-${ticket.id}`}>
                                    <div className={`list-group-item list-margin diagonal-background diagonal-background-${getIndicatorClassFromTicket(ticket)}`}>
                                        <ScheduledCopierTicketCard
                                            contract={this.props.copierContracts.find(x => x.id === ticket.contractId)!!}
                                            ticket={ticket}
                                            expandCollapse={() => this.handleScheduledCardExpandCollapseClick(index)}
                                            isExpanded={this.state.expandedIndexScheduled === index}
                                            handleSolveClick={payload => this.handleSolveScheduledSupportTicketClick(ticket.contractId, ticket.id, payload)}
                                        />
                                    </div>
                                </li>
                            )}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="inactive-tickets" role="tabpanel" aria-labelledby="inactive-tickets-tab">
                        <ul className="list-group mt-3">
                            {this.props.copierContracts.length > 0 && inactiveTickets.map((ticket, index) =>
                                <li className="list-group-item-container" key={`inactive-ticket-list-item-${index}-${ticket.id}`}>
                                    <div className={`list-group-item list-margin diagonal-background diagonal-background-${getIndicatorClassFromTicket(ticket)}`}>
                                        <CopierSupportTicketCard
                                            contract={this.props.copierContracts.find(x => x.id === ticket.contractId)!!}
                                            ticket={ticket}
                                            expandCollapse={() => this.handleInactiveCardExpandCollapseClick(index)}
                                            isExpanded={this.state.expandedIndexInactive === index}
                                            ticketSolutionTypes={this.props.copierTicketSolutionTypes}
                                            handleSolveClick={payload => this.handleSolveSupportTicketClick(ticket.contractId, ticket.id, payload)}
                                        />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="new-ticket" role="tabpanel" aria-labelledby="new-ticket-tab">
                        <div className="d-flex justify-content-between">
                            <div className="form-group form-inline">
                                <input type="text" className="form-control ml-2" id="inputSearch" autoComplete="off" placeholder="Keresés" value={this.state.search} onChange={this.handleSearchChange} />
                            </div>
                            <div className="form-check mt-3">
                                <input type="checkbox" className="form-check-input" id="inputShowAllContracts" checked={this.state.showAllContracts} onChange={this.handleShowAllContractsChange} />
                                <label className="form-check-label" htmlFor="inputShowAllContracts">Az összes technikus szerződéseinek listázása</label>
                            </div>
                        </div>
                        <ul className="list-group mt-3">
                            <h5 className="mb-5 mt-2"><FontAwesomeIcon icon={["fas", "file-contract"]} className="lansoft-text-success" /> <span className="text-white">Másolástechnikai szerződések ({this.state.filteredCopierContracts.length})</span></h5>
                            {this.state.filteredCopierContracts.length === 0 &&
                                <div className="d-flex justify-content-center text-white font-italic">
                                    <span>Nem található szerződés.</span>
                                </div>
                            }
                            {this.state.filteredCopierContracts.map((contract, index) =>
                                <li className="list-group-item-container" key={`new-ticket-contract-list-item-${index}-${contract.id}`}>
                                    <div className="list-group-item list-margin diagonal-background diagonal-background-lansoft-primary">
                                        <CopierContractCard
                                            contract={contract}
                                            createStatement={payload => this.props.createCopierCounterStatement(contract.id, payload)}
                                            createSupportTicket={payload => this.props.createCopierSupportTicket(contract.id, payload)}
                                            expandCollapse={() => this.handleCopierContractCardExpandCollapseClick(index)}
                                            isExpanded={this.state.copierContractExpandedIndex === index}
                                            ticketTypes={this.props.copierTicketTypes}
                                        />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>

                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>
        );
    }

    private filterTickets = (tickets: CopierSupportTicket[]) => {
        return tickets.filter(x => {
            const date = new Date(x.createdAt);
            return date.getFullYear() === this.state.year && date.getMonth() + 1 === this.state.month;
        });
    }

    private handleSolveSupportTicketClick = (contractId: number, ticketId: number, payload: CopierSupportTicketSolvePayload) => {
        this.props.solveCopierSupportTicket(contractId, ticketId, payload);
        this.setState({
            expandedIndexActive: NaN,
            expandedIndexInactive: NaN
        });
    }

    private handleSolveScheduledSupportTicketClick = (contractId: number, ticketId: number, payload: CopierScheduledTicketSolvePayload) => {
        this.props.solveScheduledCopierSupportTicket(contractId, ticketId, payload);
        this.setState({
            expandedIndexScheduled: NaN
        });
    }

    private filterCopierContracts = (contracts: CopierContract[], searchString: string, showAll: boolean, technicianId: number): CopierContract[] => {
        return contracts
            .filter(x => {
                return (
                    ((x.client.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                        x.contractNumber.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                        x.device.serialNumber.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                        x.device.model.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
                        x.memo.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)) &&
                    (showAll || x.technician === null || x.technician.id === technicianId)
                );
            });
    }

    private handleSearchChange = (e: any) => {
        const searchString = e.target.value;
        this.setState({
            search: searchString,
            filteredCopierContracts: this.filterCopierContracts(this.props.copierContracts, searchString, this.state.showAllContracts, this.state.currentTechnician!!.id)
        });
    }

    private handleCopierContractCardExpandCollapseClick = (index: number) => {
        this.setState({
            copierContractExpandedIndex: index === this.state.copierContractExpandedIndex ? NaN : index
        });
    }

    private toggleYearDropdown = () => {
        this.setState({
            yearDropdownOpen: !this.state.yearDropdownOpen
        });
    }

    private toggleMonthDropdown = () => {
        this.setState({
            monthDropdownOpen: !this.state.monthDropdownOpen
        });
    }

    private handleYearChange = (year: number) => {
        const allTickets = this.props.activeCopierSupportTickets.concat(this.props.inactiveCopierSupportTickets).concat(this.props.scheduledCopierSupportTickets);
        const months = [...new Set(allTickets.filter(ticket => new Date(ticket.createdAt).getFullYear() === year).map(ticket => new Date(ticket.createdAt).getMonth() + 1))];
        this.setState({
            year: year,
            month: months.length > 0 ? months[0] : this.state.month
        });
    }

    private handleMonthChange = (month: number) => {
        this.setState({
            month: month
        });
    }

    private handleShowAllChange = (e: any) => {
        this.setState({
            showAll: e.target.checked
        }, () => {
            this.props.getActiveCopierSupportTickets(!this.state.showAll);
            this.props.getInactiveCopierSupportTickets(!this.state.showAll);
            this.props.getScheduledCopierSupportTickets(!this.state.showAll);
        });
    }

    private handleShowAllContractsChange = (e: any) => {
        this.setState({
            showAllContracts: e.target.checked,
            filteredCopierContracts: this.filterCopierContracts(this.props.copierContracts, this.state.search, e.target.checked, this.state.currentTechnician!!.id)
        });
    }

    private handleActiveCardExpandCollapseClick = (index: number) => {
        this.setState({
            expandedIndexActive: this.state.expandedIndexActive === index ? NaN : index
        });
    }

    private handleInactiveCardExpandCollapseClick = (index: number) => {
        this.setState({
            expandedIndexInactive: this.state.expandedIndexInactive === index ? NaN : index
        });
    }

    private handleScheduledCardExpandCollapseClick = (index: number) => {
        this.setState({
            expandedIndexScheduled: this.state.expandedIndexScheduled === index ? NaN : index
        });
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(Tickets as any);
