import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from 'reactstrap';
import { CopierCounterStatementCreatePayload, CopierSupportTicketCreatePayload } from "../payloads";
import { CopierContract, CopierContractType, CopierSupportTicket, CopierTicketState, CopierTicketType, HistoryItemType } from "../models";
import { getFriendlyNameFromCopierContractType, getIndicatorClassFromHistoryItemType, getTooltipTextFromHistoryItemType } from "../utils/EnumUtils";
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import Clock from 'react-live-clock';
import CopierSupportTicketModal from "./CopierSupportTicketModal";
import { copierContractPrintHistoryBarChartOptions, getCopierContractHistory, getCopierContractPrintHistoryBarChartData, getNumberInputValue, MAX_DEVICE_NAME_LENGTH_DISPLAY } from '../utils';

enum ExpandedTabs {
    None,
    Tickets,
    History,
    Statements
}

interface CopierContractCardPropsType {
    isExpanded: boolean;
    contract: CopierContract;
    createStatement: (payload: CopierCounterStatementCreatePayload) => void;
    createSupportTicket: (payload: CopierSupportTicketCreatePayload) => void;
    expandCollapse: () => void;
    ticketTypes: CopierTicketType[];
}

interface CopierContractCardState {
    validationErrors: string[];
    validationErrorsCounterStatement: string[];
    blackAndWhiteCounter: number;
    colorCounter: number | null;
    activeTab: ExpandedTabs;
    ticketTypeDropdownOpen: boolean;
    ticketTypeId: number;
    ticketBlackAndWhiteCounter: number;
    ticketColorCounter: number | null;
    messageToTechnician: string;
    contractNumberTooltipOpen: boolean;
    contractTypeTooltipOpen: boolean;
    deviceModelTooltipOpen: boolean;
    deviceSerialNumberTooltipOpen: boolean;
    memoTooltipOpen: boolean;
    historyItemIndicatorTooltipOpen: number;
    ticketHistoryItem: CopierSupportTicket | null;
    ticketHistoryItemModalOpen: boolean;
    copierDeviceModelExpanded: boolean;
}

export class CopierContractCard extends React.PureComponent<CopierContractCardPropsType, CopierContractCardState> {
    constructor(props: CopierContractCardPropsType) {
        super(props);

        this.state = {
            validationErrors: [],
            validationErrorsCounterStatement: [],
            blackAndWhiteCounter: props.contract.suggestedNextBlackAndWhiteCounter,
            colorCounter: props.contract.suggestedNextColorCounter,
            activeTab: ExpandedTabs.None,
            ticketTypeDropdownOpen: false,
            ticketTypeId: 0,
            ticketBlackAndWhiteCounter: props.contract.statements.length === 0 ? props.contract.blackAndWhiteCounter : props.contract.statements[0].blackAndWhiteCounterValue,
            ticketColorCounter: props.contract.statements.length === 0 ? props.contract.colorCounter : props.contract.statements[0].colorCounterValue,
            messageToTechnician: "",
            contractNumberTooltipOpen: false,
            contractTypeTooltipOpen: false,
            deviceModelTooltipOpen: false,
            deviceSerialNumberTooltipOpen: false,
            memoTooltipOpen: false,
            historyItemIndicatorTooltipOpen: NaN,
            ticketHistoryItem: null,
            ticketHistoryItemModalOpen: false,
            copierDeviceModelExpanded: false
        }
    }

    public render() {
        const history = getCopierContractHistory(this.props.contract);
        const deviceNameLong = this.props.contract.device.model.name.length > MAX_DEVICE_NAME_LENGTH_DISPLAY;

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <div className="h4 d-flex justify-content-between mt-4 mb-4 flex-same-width-container pb-5 border-bottom lansoft-border-success">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <span id={`contract-contract-number-${this.props.contract.id}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`contract-contract-number-${this.props.contract.id}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div id={`contract-contract-type-${this.props.contract.id}`} className="d-inline-block">
                                            {getFriendlyNameFromCopierContractType(this.props.contract.contractType)}{this.props.contract.contractType === CopierContractType.Monthly && `/${this.props.contract.maintenanceIntervalMonths}`}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.contractTypeTooltipOpen} target={`contract-contract-type-${this.props.contract.id}`} toggle={this.toggleContractTypeTooltip}>
                                            Szerződés típusa
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div onClick={this.handleCopierDeviceExpandClick} className="cursor-pointer">
                                        <div id={`contract-device-model-${this.props.contract.id}`} className="d-inline-block">
                                            {!deviceNameLong && <React.Fragment>{this.props.contract.device.model.name}</React.Fragment>}
                                            {deviceNameLong && <React.Fragment>
                                                {!this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.substring(0, MAX_DEVICE_NAME_LENGTH_DISPLAY - 2) + "..."}
                                                </React.Fragment>}
                                                {this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.split(" ").map((fragment, index) =>
                                                        <span className="nowrap d-block" key={`device-model-name-fragment-${index}`}>{fragment}</span>)
                                                    }
                                                </React.Fragment>}
                                            </React.Fragment>}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceModelTooltipOpen} target={`contract-device-model-${this.props.contract.id}`} toggle={this.toggleDeviceModelTooltip}>
                                            Gép típusa
                                    </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div id={`contract-device-serial-number-${this.props.contract.id}`} className="d-inline-block">
                                            {this.props.contract.device.serialNumber}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceSerialNumberTooltipOpen} target={`contract-device-serial-number-${this.props.contract.id}`} toggle={this.toggleDeviceSerialNumberTooltip}>
                                            Gép gyári száma
                                    </Tooltip>
                                    </div>
                                </div>
                                <div className="flex-half-width-column">
                                    <div className="d-flex justify-content-end">
                                        <div id={`contract-memo-${this.props.contract.id}`}>
                                            {this.props.contract.memo}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.memoTooltipOpen} target={`contract-memo-${this.props.contract.id}`} toggle={this.toggleMemoTooltip}>
                                            Memo
                                        </Tooltip>
                                        <div className={`cursor-pointer ml-3 ${!this.props.isExpanded ? "invisible" : ""}`} onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>
                                    </div>
                                </div>
                            </div>
                        </CardTitle>

                        <div className="d-flex justify-content-between mt-4 flex-same-width-container pt-4 pb-5 border-bottom lansoft-border-success">
                            <div>
                                <div>
                                    <Button onClick={() => this.setExpandedTab(ExpandedTabs.Tickets)} color="primary" className="lansoft-primary">
                                        <FontAwesomeIcon icon={["fas", "tools"]} className="mr-2" />
                                        Hiba, kellék
                                        <FontAwesomeIcon icon={["fas", this.state.activeTab === ExpandedTabs.Tickets ? "chevron-up" : "chevron-down"]} className="ml-2" />
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Button onClick={() => this.setExpandedTab(ExpandedTabs.History)} color="primary" className="lansoft-primary">
                                        <FontAwesomeIcon icon={["fas", "history"]} className="mr-2" />
                                        Történet
                                        <FontAwesomeIcon icon={["fas", this.state.activeTab === ExpandedTabs.History ? "chevron-up" : "chevron-down"]} className="ml-2" />
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Button onClick={() => this.setExpandedTab(ExpandedTabs.Statements)} color="primary" className="lansoft-primary">
                                        <FontAwesomeIcon icon={["fas", "calculator"]} className="mr-2" />
                                        Számláló
                                        <FontAwesomeIcon icon={["fas", this.state.activeTab === ExpandedTabs.Statements ? "chevron-up" : "chevron-down"]} className="ml-2" />
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <div className="h5">
                                    Technikus: {this.props.contract.technician.name}
                                </div>
                            </div>
                            <div className="flex-half-width-column"></div>
                        </div>

                        {this.props.isExpanded && this.state.activeTab === ExpandedTabs.Tickets && <React.Fragment>
                            <div className="mt-4 flex-same-width-container">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={["fas", "exclamation-circle"]} className="mr-2 lansoft-text-success" />
                                        <div className="h5 lansoft-underline-success pb-1 mb-0 width-fit-content">
                                            Hiba bejelentés:
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="h5">
                                        <Clock format={'YYYY.MM.DD HH:mm'} ticking={true} />
                                    </div>
                                </div>
                                <div></div>
                                <div></div>
                                <div className="flex-half-width-column"></div>
                            </div>
                            <div className="mt-4 flex-same-width-container">
                                <div>
                                    <div className="form-group">
                                        <label>Típus</label>
                                        <Dropdown isOpen={this.state.ticketTypeDropdownOpen} toggle={this.toggleticketTypeDropdown}>
                                            <DropdownToggle className="lansoft-success" caret>
                                                {this.state.ticketTypeId === 0 ? "Válassz" : this.props.ticketTypes.find(x => x.id === this.state.ticketTypeId)!!.name}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {this.props.ticketTypes.map((type, index) =>
                                                    <DropdownItem active={this.state.ticketTypeId === type.id} onClick={() => this.handleTicketTypeChange(type.id)} key={`copier-ticket-type-dropdown-item-${index}`}>{type.name}</DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="inputNewTicketBlackAndWhiteCounter">Fekete számláló</label>
                                        <input type="text" className="form-control numeric-input" id="inputNewTicketBlackAndWhiteCounter" autoComplete="off" placeholder="Fekete számláló" value={getNumberInputValue(this.state.ticketBlackAndWhiteCounter)} onChange={this.handleTicketBlackAndWhiteCounterChange} />
                                    </div>
                                    {this.props.contract.device.model.color && <div className="form-group mt-2">
                                        <label htmlFor="inputNewTicketColorCounter">Színes számláló</label>
                                        <input type="text" className="form-control numeric-input" id="inputNewTicketColorCounter" autoComplete="off" placeholder="Színes számláló" value={getNumberInputValue(this.state.ticketColorCounter || 0)} onChange={this.handleTicketColorCounterChange} />
                                    </div>}
                                </div>
                                <div></div>
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="inputNewTicketMessageToTechnician">Üzenet a technikusnak</label>
                                        <textarea maxLength={250} rows={4} className="form-control message-textarea" id="inputNewTicketMessageToTechnician" autoComplete="off" placeholder="Üzenet" value={this.state.messageToTechnician} onChange={this.handleMessageToTechnicianChange} />
                                    </div>
                                    <div className="d-flex justify-content-end mt-3">
                                        <Button color="primary" className="lansoft-primary" onClick={this.handleCreateSubmitTicketClick} disabled={!this.isValid()}>Elküld</Button>
                                    </div>
                                </div>
                                <div className="flex-half-width-column"></div>
                            </div>
                        </React.Fragment>}

                        {this.props.isExpanded && this.state.activeTab === ExpandedTabs.History && <div className="mt-5 pb-2">
                            {history.length === 0 && <div>Nem található elem.</div>}
                            {history.length > 0 &&
                                <React.Fragment>
                                    <table className="table history-table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Dátum</th>
                                                <th scope="col">Fekete sz.</th>
                                                {this.props.contract.device.model.color && <th scope="col">Színes sz.</th>}
                                                <th scope="col">Ügyfél kérése</th>
                                                <th scope="col">Megoldás</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {history.map((historyItem, index) => {
                                                const blackCounter = historyItem.statement == null ? historyItem.ticket!!.blackAndWhiteCounterValue : historyItem.statement.blackAndWhiteCounterValue;
                                                const colorCounter = historyItem.statement == null ? historyItem.ticket!!.colorCounterValue : historyItem.statement.colorCounterValue;
                                                const messageToTechnician = historyItem.ticket != null ? historyItem.ticket!!.messageToTechnician : "Számláló";
                                                const messageToClient = historyItem.ticket != null ? historyItem.ticket!!.messageToClient : "";

                                                return (
                                                    <tr key={`contract-history-row-${index}`} className={historyItem.ticket == null ? "" : "cursor-pointer"} onClick={() => { if (historyItem.ticket != null) this.onTicketHistoryItemClick(historyItem.ticket); }}>
                                                        <td>
                                                            <div id={`contract-history-item-indicator-${index}`} className={`contract-history-item-indicator bg-${getIndicatorClassFromHistoryItemType(historyItem.statement != null ? HistoryItemType.CounterStatement : (historyItem.ticket!!.isSolved ? (historyItem.ticket!!.state === CopierTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket))}`}></div>
                                                            <Tooltip placement="top" isOpen={this.state.historyItemIndicatorTooltipOpen === index} target={`contract-history-item-indicator-${index}`} toggle={() => this.toggleHistoryItemIndicatorTooltipOpen(index)}>
                                                                {getTooltipTextFromHistoryItemType(historyItem.ticket == null ? HistoryItemType.CounterStatement : (historyItem.ticket.isSolved ? (historyItem.ticket.state === CopierTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket))}
                                                            </Tooltip>
                                                        </td>
                                                        <td>{moment(historyItem.date).format("YYYY.MM.DD HH:mm")}</td>
                                                        <td>{blackCounter}</td>
                                                        {this.props.contract.device.model.color && <td>{colorCounter}</td>}
                                                        <td>{messageToTechnician}</td>
                                                        <td>{messageToClient}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div>
                                        <Button color="primary" className="lansoft-primary">Mentés Excelbe</Button>
                                    </div>
                                </React.Fragment>
                            }
                        </div>}

                        {this.props.isExpanded && this.state.activeTab === ExpandedTabs.Statements && <React.Fragment>
                            <div className="mt-5 d-flex justify-content-between flex-same-width-container pb-5 border-bottom lansoft-border-success">
                                <div>
                                    <div className="d-flex justify-content-start">
                                        <div>Bérleti díj: {this.props.contract.monthlyLeasePrice} Ft + ÁFA</div>
                                    </div>
                                    <div>Fekete nyomatdíj: {this.props.contract.blackAndWhitePrintPrice} Ft + ÁFA</div>
                                    {this.props.contract.device.model.color && <div>Színes nyomatdíj: {this.props.contract.colorPrintPrice} Ft + ÁFA</div>}
                                </div>
                                <div className="flex-double-width-column">
                                    <div>Bérleti díjban foglalt nyomat: {this.props.contract.includedBlackAndWhitePrints}</div>
                                </div>
                                <div></div>
                                <div className="flex-half-width-column"></div>
                            </div>

                            <div className="mt-5 mb-5 d-flex justify-content-between flex-same-width-container">
                                <div className="flex-wider-column">
                                    <div className="d-flex justify-content-start ml-4">
                                        <div className="d-inline-block pr-8 border-right lansoft-border-success width-fit-content h-100">
                                            <div className="mb-4"><strong>Legutóbbi:</strong></div>
                                            <div className="mb-4 form-group line-height-higher">Fekete számláló: <strong>{this.props.contract.statements.length === 0 ? this.props.contract.blackAndWhiteCounter : this.props.contract.statements[0].blackAndWhiteCounterValue}</strong></div>
                                            {this.props.contract.device.model.color && <div className="mb-4 mt-2 form-group line-height-higher">Színes számláló: <strong>{this.props.contract.statements.length === 0 ? this.props.contract.colorCounter : this.props.contract.statements[0].colorCounterValue}</strong></div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-wider-column">
                                    <div className="d-inline-block width-fit-content">
                                        <div className="mb-3"><strong>Jelenlegi - {moment(new Date()).format("YYYY.MM.DD")}:</strong></div>
                                        <div className="form-group form-inline">
                                            <label className="mr-2 label-90" htmlFor="inputNewStatementBlackAndWhiteCounter">Fekete számláló</label>
                                            <input type="text" className="form-control numeric-input" id="inputNewStatementBlackAndWhiteCounter" autoComplete="off" placeholder="Fekete számláló" value={getNumberInputValue(this.state.blackAndWhiteCounter)} onChange={this.handleBlackAndWhiteCounterChange} />
                                        </div>
                                        {this.props.contract.device.model.color && <div className="form-group form-inline">
                                            <label className="mr-2 label-90" htmlFor="inputNewStatementColorCounter">Színes számláló</label>
                                            <input type="text" className="form-control numeric-input" id="inputNewStatementColorCounter" autoComplete="off" placeholder="Színes számláló" value={getNumberInputValue(this.state.colorCounter || 0)} onChange={this.handleColorCounterChange} />
                                        </div>}
                                        <div>
                                            <Button color="primary" className="lansoft-primary" onClick={this.handleCreateStatementClick} disabled={!this.isValidCounterStatement()}>Elküld</Button>
                                        </div>
                                    </div>
                                </div>
                                <div></div>
                                <div></div>
                                <div className="flex-half-width-column"></div>
                            </div>

                            <div className="mt-5 pt-3 border-top lansoft-border-success">
                                <div className="mt-3 p-5">
                                    <Bar data={getCopierContractPrintHistoryBarChartData(this.props.contract)} options={copierContractPrintHistoryBarChartOptions} />
                                </div>

                                <div className="mt-4 pt-3 border-top lansoft-border-success">
                                </div>

                                <div className="mt-3 pt-5">
                                    {this.props.contract.statements.length === 0 && <div>Nem található számláló.</div>}
                                    {this.props.contract.statements.length > 0 &&
                                        <React.Fragment>
                                            <table className="table statements-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Dátum</th>
                                                        <th scope="col">Fekete sz.</th>
                                                        {this.props.contract.device.model.color && <th scope="col">Színes sz.</th>}
                                                        <th scope="col">Fekete nyomat</th>
                                                        {this.props.contract.device.model.color && <th scope="col">Színes nyomat</th>}
                                                        <th scope="col">Fizetendő</th>
                                                        <th scope="col">Számla</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.contract.statements.map((statement, index) => {
                                                        return (
                                                            <tr key={`contract-statement-row-${index}`}>
                                                                <td>{moment(statement.createdAt).format("YYYY.MM.DD")}</td>
                                                                <td>{statement.blackAndWhiteCounterValue}</td>
                                                                {this.props.contract.device.model.color && <td>{statement.colorCounterValue || 0}</td>}
                                                                <td>{statement.blackAndWhitePrints}</td>
                                                                {this.props.contract.device.model.color && <td>{statement.colorPrints}</td>}
                                                                <td>{statement.amountToBePaid}</td>
                                                                <td>{statement.invoice != null && <Button color="primary" className="lansoft-primary" onClick={() => this.handleDownloadInvoiceClick(statement.id)}>Letöltés</Button>}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            <div>
                                                <Button color="primary" className="lansoft-primary">Mentés Excelbe</Button>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </React.Fragment>}
                    </CardBody>
                </Card>

                <CopierSupportTicketModal
                    ticket={this.state.ticketHistoryItem}
                    open={this.state.ticketHistoryItemModalOpen}
                    close={this.closeTicketHistoryItemModal}
                    color={this.props.contract.device.model.color}
                />
            </React.Fragment>

        );
    }

    private handleCopierDeviceExpandClick = () => {
        this.setState({
            copierDeviceModelExpanded: !this.state.copierDeviceModelExpanded
        });
    }

    private handleDownloadInvoiceClick = (statementId: number) => {
        window.location.href = `/api/copier-contract/${this.props.contract.id}/statement/${statementId}/invoice`;
    }

    private onTicketHistoryItemClick = (ticket: CopierSupportTicket) => {
        this.setState({
            ticketHistoryItem: ticket,
            ticketHistoryItemModalOpen: true
        });
    }

    private closeTicketHistoryItemModal = () => {
        this.setState({
            ticketHistoryItemModalOpen: false
        });
    }

    private toggleHistoryItemIndicatorTooltipOpen = (index: number) => {
        this.setState({
            historyItemIndicatorTooltipOpen: this.state.historyItemIndicatorTooltipOpen === index ? NaN : index
        });
    }

    private toggleMemoTooltip = () => {
        this.setState({
            memoTooltipOpen: !this.state.memoTooltipOpen
        });
    }

    private toggleDeviceSerialNumberTooltip = () => {
        this.setState({
            deviceSerialNumberTooltipOpen: !this.state.deviceSerialNumberTooltipOpen
        });
    }

    private toggleDeviceModelTooltip = () => {
        this.setState({
            deviceModelTooltipOpen: !this.state.deviceModelTooltipOpen
        });
    }

    private toggleContractTypeTooltip = () => {
        this.setState({
            contractTypeTooltipOpen: !this.state.contractTypeTooltipOpen
        });
    }

    private toggleContractNumberTooltip = () => {
        this.setState({
            contractNumberTooltipOpen: !this.state.contractNumberTooltipOpen
        });
    }

    private handleTicketBlackAndWhiteCounterChange = (e: any) => {
        this.setState({
            ticketBlackAndWhiteCounter: parseInt(e.target.value.replace(/\D/, ""))
        });
    }

    private handleTicketColorCounterChange = (e: any) => {
        this.setState({
            ticketColorCounter: parseInt(e.target.value.replace(/\D/, ""))
        });
    }

    private handleMessageToTechnicianChange = (e: any) => {
        this.setState({
            messageToTechnician: e.target.value
        });
    }

    private handleTicketTypeChange = (id: number) => {
        this.setState({
            ticketTypeId: id
        }, () => this.validate(true));
    }

    private toggleticketTypeDropdown = () => {
        this.setState({
            ticketTypeDropdownOpen: !this.state.ticketTypeDropdownOpen
        });
    }

    private handleCreateStatementClick = () => {
        const payload: CopierCounterStatementCreatePayload = {
            blackAndWhiteCounterValue: this.state.blackAndWhiteCounter,
            colorCounterValue: this.state.colorCounter
        };

        this.props.createStatement(payload);

        this.resetStatementForm();
    }

    private handleCreateSubmitTicketClick = () => {
        const payload: CopierSupportTicketCreatePayload = {
            blackAndWhiteCounterValue: this.state.ticketBlackAndWhiteCounter,
            colorCounterValue: this.state.ticketColorCounter,
            messageToTechnician: this.state.messageToTechnician,
            ticketTypeId: this.state.ticketTypeId
        };

        this.props.createSupportTicket(payload);

        this.setState({
            blackAndWhiteCounter: this.props.contract.statements.length === 0 ? this.props.contract.blackAndWhiteCounter : this.props.contract.statements[0].blackAndWhiteCounterValue,
            colorCounter: this.props.contract.statements.length === 0 ? this.props.contract.colorCounter : this.props.contract.statements[0].colorCounterValue,
            messageToTechnician: "",
            ticketTypeId: 0
        });
    }

    private handleBlackAndWhiteCounterChange = (e: any) => {
        this.setState({
            blackAndWhiteCounter: parseInt(e.target.value.replace(/\D/, ""))
        }, () => this.validateCounterStatement(true));
    }

    private handleColorCounterChange = (e: any) => {
        this.setState({
            colorCounter: parseInt(e.target.value.replace(/\D/, ""))
        }, () => this.validateCounterStatement(true));
    }

    private setExpandedTab = (tab: ExpandedTabs) => {
        if (!this.props.isExpanded || tab === this.state.activeTab) {
            this.props.expandCollapse();
        }

        this.setState({
            activeTab: tab === this.state.activeTab ? ExpandedTabs.None : tab
        });
    }

    private handleExpandClick = () => {
        if (this.props.isExpanded) {
            this.props.expandCollapse();
            this.setState({
                activeTab: ExpandedTabs.None
            });
        }
    }

    private resetStatementForm = () => {
        this.setState({
            blackAndWhiteCounter: this.props.contract.suggestedNextBlackAndWhiteCounter,
            colorCounter: this.props.contract.suggestedNextColorCounter
        });
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.ticketTypeId === 0) {
            validationErrors.push("A típus kiválasztása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidCounterStatement = () => {
        return this.validateCounterStatement();
    }

    private validateCounterStatement = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        const previousBlackAndWhiteCounter = this.props.contract.statements.length === 0 ? this.props.contract.blackAndWhiteCounter : this.props.contract.statements[0].blackAndWhiteCounterValue;
        const previousColorCounter = this.props.contract.statements.length === 0 ? this.props.contract.colorCounter : this.props.contract.statements[0].colorCounterValue;

        if (isNaN(this.state.blackAndWhiteCounter) || this.state.blackAndWhiteCounter < previousBlackAndWhiteCounter) {
            validationErrors.push("A fekete számláló értéke nem lehet kisebb a legutóbbinál.");
        }

        if (this.props.contract.device.model.color && (this.state.colorCounter == null || isNaN(this.state.colorCounter) || this.state.colorCounter < (previousColorCounter || 0))) {
            validationErrors.push("A színes számláló értéke nem lehet kisebb a legutóbbinál.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrorsCounterStatement: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default CopierContractCard;
