import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as LanSoftStore from '../store/LanSoftStore'
import { Collapse, Navbar, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TechnicianInfo from "./TechnicianInfo";
import { MenuItem } from "../models";
import NavMenuItem from "./NavMenuItem";
import NavMenuItemGroup from "./NavMenuItemGroup";

type NavMenuTechnicianProps = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface NavMenuTechnicianState {
    isOpen: boolean;
    techniciansRequested: boolean;
}

const menuItems: MenuItem[] = [
    {
        icon: "hdd",
        text: "Géptípusok",
        target: "/admin/copier-models"
    }, {
        icon: "desktop",
        customIcon: "icons/printer-white.svg",
        text: "Gépek",
        target: "/admin/copier-devices"
    }, {
        icon: "wrench",
        text: "Technikusok",
        target: "/admin/technicians"
    }, {
        icon: "users",
        text: "Ügyfelek",
        target: "/admin/clients"
    }, {
        icon: "file-contract",
        text: "Szerződések",
        target: "/admin/contracts"
    }, {
        icon: "exclamation-circle",
        text: "Hibák",
        target: "/admin/tickets"
    }, {
        icon: "calculator",
        text: "Számlálók",
        target: "/admin/copier-counter-statements"
    }
];

const profileMenu: MenuItem = {
    icon: "user",
    text: "Profil"
};

const profileMenuItems: MenuItem[] = [
    {
        icon: "envelope",
        text: "E-mail változtatás",
        target: "/Identity/Account/Manage/Email"
    }, {
        icon: "key",
        text: "Jelszóváltoztatás",
        target: "/Identity/Account/Manage/ChangePassword"
    }, {
        icon: "sign-out-alt",
        text: "Kijelentkezés",
        target: ApplicationPaths.LogOut
    }
];

const pixelOffset = 10;

class NavMenuTechnician extends React.PureComponent<NavMenuTechnicianProps, NavMenuTechnicianState> {
    constructor(props: NavMenuTechnicianProps) {
        super(props);

        this.state = {
            isOpen: false,
            techniciansRequested: false
        }
    }

    componentWillMount() {
        if (!this.state.techniciansRequested && this.props.technicians.length === 0) {
            this.setState({
                techniciansRequested: true
            }, () => {
                    this.props.getAllTechnicians();
            });
        }
    }

    componentWillReceiveProps(nextProps: NavMenuTechnicianProps) {
        if (nextProps.loggedInUser != null && nextProps.technicians.length > 0 && this.props.technicians.length === 0) {
            const technician = nextProps.technicians.find(x => x.user.id === nextProps.loggedInUser!!.id);
            if (technician != null) {
                this.props.getActiveCopierSupportTickets(!technician.displayItemsForAllTechnicians);
                this.props.getScheduledCopierSupportTickets(!technician.displayItemsForAllTechnicians);
            }
        }
    }

    public render() {
        const technician = this.props.loggedInUser == null ? null : this.props.technicians.find(x => x.user.id === this.props.loggedInUser!!.id);
        return (
            <React.Fragment>
                <header>
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 w-100 main-nav-container" light>
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <div className="mx-3 logo-container">
                                <a href="/">
                                    <img className="nav-logo" src="images/logo.png" />
                                </a>
                            </div>
                            <div className="d-flex justify-content-between nav-menu-container" style={{ paddingBottom: `${pixelOffset}px`, paddingTop: `${pixelOffset * 1.5}px` }}>
                                {menuItems.map((menuItem, index) => {
                                    return (
                                        <NavMenuItem
                                            key={`nav-menu-item-${index}`}
                                            menuItem={menuItem}
                                        />
                                    );
                                })}
                                <NavMenuItemGroup
                                    menuItem={profileMenu}
                                    items={profileMenuItems}
                                    right={true}
                                    isLast={true}
                                />
                            </div>
                        </div>
                        {/*<Container>*/}
                        {/*    <NavbarToggler onClick={this.toggle} className="mr-2" />*/}
                        {/*    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} onClick={this.toggle} navbar>*/}
                        {/*    </Collapse>*/}
                        {/*</Container>*/}
                    </Navbar>
                    {/*<Collapse id="navbarNav" className="width h-100" isOpen={this.state.isOpen} onClick={this.toggle}>*/}
                    {/*    <ul className="navbar-nav h-100 d-flex flex-column align-items-center justify-content-around">*/}
                    {/*        <NavItem>*/}
                    {/*            <a className="text-dark nav-link" href="/admin/copier-models"><FontAwesomeIcon icon={["fas", "hdd"]} className="fa-fw mr-1" /> Géptípusok</a>*/}
                    {/*            <a className="text-dark nav-link" href="/admin/copier-devices"><FontAwesomeIcon icon={["fas", "desktop"]} className="fa-fw mr-1" /> Gépek</a>*/}
                    {/*            <a className="text-dark nav-link" href="/admin/technicians"><FontAwesomeIcon icon={["fas", "wrench"]} className="fa-fw mr-1" /> Technikusok</a>*/}
                    {/*            <a className="text-dark nav-link" href="/admin/clients"><FontAwesomeIcon icon={["fas", "users"]} className="fa-fw mr-1" /> Ügyfelek</a>*/}
                    {/*            <a className="text-dark nav-link" href="/admin/contracts"><FontAwesomeIcon icon={["fas", "file-contract"]} className="fa-fw mr-1" /> Szerződések</a>*/}
                    {/*            <a className="text-dark nav-link" href="/admin/tickets"><FontAwesomeIcon icon={["fas", "exclamation-circle"]} className="fa-fw mr-1" /> Hibák</a>*/}
                    {/*            <a className="text-dark nav-link" href="/Identity/Account/Manage/Email"><FontAwesomeIcon icon={["fas", "envelope"]} className="fa-fw mr-1" /> E-mail</a>*/}
                    {/*            <a className="text-dark mb-2 nav-link" href="/Identity/Account/Manage/ChangePassword"><FontAwesomeIcon icon={["fas", "key"]} className="fa-fw mr-1" /> Jelszóváltoztatás</a>*/}
                    {/*            <NavLink tag={Link} className="text-dark" to={ApplicationPaths.LogOut}><FontAwesomeIcon icon={["fas", "sign-out-alt"]} className="fa-fw mr-1" /> Kijelentkezés</NavLink>*/}
                    {/*        </NavItem>*/}
                    {/*    </ul>*/}
                    {/*</Collapse>*/}
                </header>
                {technician != null && <TechnicianInfo
                    technician={technician}
                    systemMessageCount={7}
                    activeTicketCount={this.props.activeCopierSupportTickets.length}
                    scheduledTicketCount={this.props.scheduledCopierSupportTickets.length}
                />}
            </React.Fragment>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(NavMenuTechnician as any);
