import { ProtectedRestApi } from "./";
import { CopierTicketType, ServiceResult } from "../models";
import { CopierTicketTypeCreatePayload, CopierTicketTypeUpdatePayload } from "../payloads";

export class CopierTicketTypeApi extends ProtectedRestApi {
    private static baseUrl = "/api/copier-ticket-type";

    public getAll(): Promise<ServiceResult<CopierTicketType[]>> {
        return this.httpGet(`${CopierTicketTypeApi.baseUrl}/all`) as Promise<ServiceResult<CopierTicketType[]>>;
    }

    public create(payload: CopierTicketTypeCreatePayload): Promise<ServiceResult<CopierTicketType>> {
        return this.httpPut(`${CopierTicketTypeApi.baseUrl}`, payload) as Promise<ServiceResult<CopierTicketType>>;
    }

    public update(id: number, payload: CopierTicketTypeUpdatePayload): Promise<ServiceResult<CopierTicketType>> {
        return this.httpPost(`${CopierTicketTypeApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<CopierTicketType>>;
    }

    public delete(id: number): Promise<ServiceResult<number>> {
        return this.httpDelete(`${CopierTicketTypeApi.baseUrl}/${id}`) as Promise<ServiceResult<number>>;
    }
}
