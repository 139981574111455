import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopierModel } from "../models";
import { Button } from "reactstrap";

interface CopierModelItemItemProps {
    className?: string;
    model: CopierModel;
    editCopierModel?: () => void;
    deleteCopierModel?: () => void;
}

export const CopierModelItemItem = (props: CopierModelItemItemProps) => (
    <div className={`d-flex align-items-center justify-content-between flex-same-width-container ${(props.className != null ? props.className : "")}`}>
        <div>
            <div className="h5 mb-0">{props.model.name}</div>
        </div>
        <div>
            <div className="h5 mb-0 d-flex justify-content-center">{props.model.color ? "Színes" : "Fekete"}</div>
        </div>
        <div>
            <div className="d-flex justify-content-end">
                {props.editCopierModel != null && <Button color="primary" className="lansoft-primary" onClick={props.editCopierModel}><FontAwesomeIcon icon={["fas", "pencil-alt"]} /></Button>}
                {props.deleteCopierModel != null && <Button color="danger" className="ml-3 lansoft-danger" onClick={props.deleteCopierModel}><FontAwesomeIcon icon={["fas", "trash-alt"]} /></Button>}
            </div>
        </div>
    </div>
);

export default CopierModelItemItem;
