import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, Tooltip } from 'reactstrap';
import { CopierContract, CopierContractType, CopierCounterStatement, CopierCounterStatementTab, CopierSupportTicket } from "../models";
import { getFriendlyNameFromCopierContractType, getIndicatorClassFromStatement, getTooltipTextFromStatement } from "../utils/EnumUtils";
import moment from 'moment';
import CopierSupportTicketModal from "./CopierSupportTicketModal";
import { Bar } from 'react-chartjs-2';
import { CopierCounterStatementCreatePayload } from "../payloads";
import { copierContractPrintHistoryBarChartOptions, getCopierContractPrintHistoryBarChartData, getNumberInputValue, MAX_DEVICE_NAME_LENGTH_DISPLAY } from '../utils';

interface CopierCounterStatementCardPropsType {
    isExpanded: boolean;
    statement: CopierCounterStatement;
    contract: CopierContract;
    createInvoice: (file: File) => void;
    createStatement: (payload: CopierCounterStatementCreatePayload) => void;
    expandCollapse: () => void;
    tab: CopierCounterStatementTab;
}

interface CopierCounterStatementCardState {
    validationErrors: string[];
    validationErrorsCounterStatement: string[];
    ticketIndicatorTooltipOpen: boolean;
    contractNumberTooltipOpen: boolean;
    contractTypeTooltipOpen: boolean;
    deviceModelTooltipOpen: boolean;
    deviceSerialNumberTooltipOpen: boolean;
    memoTooltipOpen: boolean;
    sendEmailButtonTooltipOpen: boolean;
    sendStatementButtonTooltipOpen: boolean;
    ticketHistoryItem: CopierSupportTicket | null;
    ticketHistoryItemModalOpen: boolean;
    invoiceModalStatementId: number;
    invoiceFile: File | null;
    blackAndWhiteCounter: number;
    colorCounter: number | null;
    copierDeviceModelExpanded: boolean;
}

export class CopierCounterStatementCard extends React.PureComponent<CopierCounterStatementCardPropsType, CopierCounterStatementCardState> {
    constructor(props: CopierCounterStatementCardPropsType) {
        super(props);

        this.state = {
            validationErrors: [],
            validationErrorsCounterStatement: [],
            ticketIndicatorTooltipOpen: false,
            contractNumberTooltipOpen: false,
            contractTypeTooltipOpen: false,
            deviceModelTooltipOpen: false,
            deviceSerialNumberTooltipOpen: false,
            memoTooltipOpen: false,
            sendEmailButtonTooltipOpen: false,
            sendStatementButtonTooltipOpen: false,
            ticketHistoryItem: null,
            ticketHistoryItemModalOpen: false,
            invoiceModalStatementId: NaN,
            invoiceFile: null,
            blackAndWhiteCounter: props.contract.suggestedNextBlackAndWhiteCounter,
            colorCounter: props.contract.suggestedNextColorCounter,
            copierDeviceModelExpanded: false
        }
    }

    public render() {
        const deviceNameLong = this.props.contract.device.model.name.length > MAX_DEVICE_NAME_LENGTH_DISPLAY;
        const previousBlackAndWhiteCounterValue = this.props.contract.statements.length > 0 ? this.props.contract.statements[0].blackAndWhiteCounterValue : this.props.contract.blackAndWhiteCounter;
        const previousColorCounterValue = this.props.contract.statements.length > 0 ? this.props.contract.statements[0].colorCounterValue : this.props.contract.colorCounter;

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <div className="h4 d-flex justify-content-between mt-4 mb-4 flex-same-width-container pb-5 border-bottom lansoft-border-success">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <div id={`counter-statement-ticket-indicator-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromStatement(this.props.statement)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`counter-statement-ticket-indicator-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromStatement(this.props.statement)}
                                        </Tooltip>
                                        <span id={`counter-statement-contract-number-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`counter-statement-contract-number-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div id={`counter-statement-contract-type-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} className="d-inline-block">
                                            {getFriendlyNameFromCopierContractType(this.props.contract.contractType)}{this.props.contract.contractType === CopierContractType.Monthly && `/${this.props.contract.maintenanceIntervalMonths}`}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.contractTypeTooltipOpen} target={`counter-statement-contract-type-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleContractTypeTooltip}>
                                            Szerződés típusa
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div onClick={this.handleCopierDeviceExpandClick} className="cursor-pointer">
                                        <div id={`counter-statement-device-model-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} className="d-inline-block">
                                            {!deviceNameLong && <React.Fragment>{this.props.contract.device.model.name}</React.Fragment>}
                                            {deviceNameLong && <React.Fragment>
                                                {!this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.substring(0, MAX_DEVICE_NAME_LENGTH_DISPLAY - 2) + "..."}
                                                </React.Fragment>}
                                                {this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.split(" ").map((fragment, index) =>
                                                        <span className="nowrap d-block" key={`device-model-name-fragment-${index}`}>{fragment}</span>)
                                                    }
                                                </React.Fragment>}
                                            </React.Fragment>}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceModelTooltipOpen} target={`counter-statement-device-model-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleDeviceModelTooltip}>
                                            Gép típusa
                                    </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div id={`counter-statement-device-serial-number-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} className="d-inline-block">
                                            {this.props.contract.device.serialNumber}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceSerialNumberTooltipOpen} target={`counter-statement-device-serial-number-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleDeviceSerialNumberTooltip}>
                                            Gép gyári száma
                                    </Tooltip>
                                    </div>
                                </div>
                                <div className="flex-half-width-column">
                                    <div className="d-flex justify-content-end">
                                        <div id={`counter-statement-memo-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`}>
                                            {this.props.contract.memo}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.memoTooltipOpen} target={`counter-statement-memo-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleMemoTooltip}>
                                            Memo
                                        </Tooltip>
                                        <div className={`cursor-pointer ml-3 ${this.props.statement.id === 0 ? "invisible" : ""}`} onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>
                                    </div>
                                </div>
                            </div>
                            {this.props.statement.id > 0 && <React.Fragment>
                                <div className="flex-same-width-container mb-4">
                                    <div>
                                        <div className="d-inline-block py-2 pr-5 border-right lansoft-border-success width-fit-content">
                                            <div>Fekete számláló: <strong>{this.props.statement.blackAndWhiteCounterValue}</strong></div>
                                            {this.props.contract.device.model.color && <div className="mt-3">Színes számláló: <strong>{this.props.statement.colorCounterValue}</strong></div>}
                                        </div>
                                    </div>
                                    <div className="flex-double-width-column">
                                        <div className="py-2">
                                            <div>Fekete nyomat: <strong>{this.props.statement.blackAndWhitePrints}</strong></div>
                                            {this.props.contract.device.model.color && <div className="mt-3">Színes nyomat: <strong>{this.props.statement.colorPrints}</strong></div>}
                                        </div>
                                    </div>
                                    <div className="h5">Technikus: {this.props.contract.technician.name}</div>
                                    <div className="flex-half-width-column"></div>
                                </div>
                                <div className="mt-4 pt-3 border-top lansoft-border-success">
                                </div>
                                <div className="flex-same-width-container mt-4 pt-5">
                                    <div>
                                        <div>
                                            <Button color="primary" className="lansoft-primary" onClick={() => this.handleExpandClick()}>Részletek <FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="ml-1" /></Button>
                                        </div>
                                    </div>
                                    <div className="flex-double-width-column">
                                        <div>
                                            <Button color="primary" className="lansoft-primary" onClick={() => this.toggleInvoiceModal(this.props.statement.id)}>Számla {this.props.statement.invoice == null ? "feltöltés" : "csere"}</Button>
                                        </div>
                                    </div>
                                    <div className="h5">
                                        <div className="mb-3">Bejelentve: {moment(this.props.statement.createdAt).format("YYYY.MM.DD HH:mm")}</div>
                                        <div>
                                            Fizetendő: <strong>{this.props.statement.amountToBePaid} Ft + ÁFA</strong>
                                        </div>
                                    </div>
                                    <div className="flex-half-width-column"></div>
                                </div>
                            </React.Fragment>
                            }
                            {this.props.statement.id === 0 &&
                                <React.Fragment>
                                    <div className="flex-same-width-container mb-4">
                                        <div className="flex-wider-column">
                                            <div className="d-flex justify-content-start ml-5">
                                                <div className="d-inline-block pr-8 border-right lansoft-border-success width-fit-content h-100">
                                                    <div className="mb-4"><strong>Legutóbbi:</strong></div>
                                                    <div className="mb-4 form-group line-height-higher">Fekete számláló: <strong>{previousBlackAndWhiteCounterValue}</strong></div>
                                                    {this.props.contract.device.model.color && <div className="mb-4 mt-2 form-group line-height-higher">Színes számláló: <strong>{previousColorCounterValue}</strong></div>}
                                                    <div className="mt-4-5">
                                                        <Button id={`send-email-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} color="primary" className="lansoft-primary">E-mail küldése</Button>
                                                        <Tooltip placement="top" isOpen={this.state.sendEmailButtonTooltipOpen} target={`send-email-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleSendEmailButtonTooltip}>
                                                            Felszólító e-mail küldése az ügyfélnek az esedékes számláló bejelentésről
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-wider-column">
                                            <div className="d-inline-block width-fit-content ml-negative-6 mr-5">
                                                <div className="mb-3"><strong>Jelenlegi - {moment(new Date()).format("YYYY.MM.DD")}:</strong></div>
                                                <div className={`form-group form-inline ${!this.props.contract.device.model.color && "mb-4"}`}>
                                                    <label className="mr-2 label-90" htmlFor="inputNewStatementBlackAndWhiteCounter">Fekete számláló</label>
                                                    <input type="text" className="form-control numeric-input" id="inputNewStatementBlackAndWhiteCounter" autoComplete="off" placeholder="Fekete számláló" value={getNumberInputValue(this.state.blackAndWhiteCounter)} onChange={this.handleBlackAndWhiteCounterChange} />
                                                </div>
                                                {this.props.contract.device.model.color && <div className="form-group form-inline">
                                                    <label className="mr-2 label-90" htmlFor="inputNewStatementColorCounter">Színes számláló</label>
                                                    <input type="text" className="form-control numeric-input" id="inputNewStatementColorCounter" autoComplete="off" placeholder="Színes számláló" value={getNumberInputValue(this.state.colorCounter || 0)} onChange={this.handleColorCounterChange} />
                                                </div>}
                                                <div>
                                                    <Button id={`send-statement-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} color="primary" className="lansoft-primary" onClick={this.handleCreateStatementClick} disabled={!this.isValidCounterStatement()}>Elküld</Button>
                                                    <Tooltip placement="top" isOpen={this.state.sendStatementButtonTooltipOpen} target={`send-statement-button-${this.props.statement.id}-${this.props.contract.id}-${this.props.tab}`} toggle={this.toggleSendStatementButtonTooltip}>
                                                        Az ügyfél jelenlegi mérőállásának rögzítése
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="h5">Technikus: {this.props.contract.technician.name}</div>
                                        <div className="flex-half-width-column"></div>
                                    </div>
                                </React.Fragment>
                            }
                        </CardTitle>

                        {this.props.statement.id > 0 && this.props.isExpanded && <div className="mt-4 border-top lansoft-border-success">
                            <div className="mt-3 p-5">
                                <Bar data={getCopierContractPrintHistoryBarChartData(this.props.contract)} options={copierContractPrintHistoryBarChartOptions} />
                            </div>

                            <div className="mt-4 pt-3 border-top lansoft-border-success">
                            </div>

                            <div className="mt-3 pt-5">
                                {this.props.contract.statements.length === 0 && <div>Nem található számláló.</div>}
                                {this.props.contract.statements.length > 0 &&
                                    <React.Fragment>
                                        <table className="table statements-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Dátum</th>
                                                    <th scope="col">Fekete sz.</th>
                                                    {this.props.contract.device.model.color && <th scope="col">Színes sz.</th>}
                                                    <th scope="col">Fekete nyomat</th>
                                                    {this.props.contract.device.model.color && <th scope="col">Színes nyomat</th>}
                                                    <th scope="col">Fizetendő</th>
                                                    <th scope="col">Számla</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.contract.statements.map((statement, index) => {
                                                    return (
                                                        <tr key={`contract-statement-row-${index}`}>
                                                            <td>{moment(statement.createdAt).format("YYYY.MM.DD")}</td>
                                                            <td>{statement.blackAndWhiteCounterValue}</td>
                                                            {this.props.contract.device.model.color && <td>{statement.colorCounterValue || 0}</td>}
                                                            <td>{statement.blackAndWhitePrints}</td>
                                                            {this.props.contract.device.model.color && <td>{statement.colorPrints}</td>}
                                                            <td>{statement.amountToBePaid}</td>
                                                            <td>{statement.invoice != null && <React.Fragment>
                                                                <Button color="primary" className="lansoft-primary" onClick={() => this.handleDownloadInvoiceClick(statement.id)}>Megnéz</Button>
                                                                <Button color="warning" className="lansoft-warning ml-2" onClick={() => this.toggleInvoiceModal(statement.id)}>Cserél</Button>
                                                            </React.Fragment>}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        <div>
                                            <Button color="primary" className="lansoft-primary">Mentés Excelbe</Button>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>}
                    </CardBody>
                </Card>

                {this.getInvoiceModal()}

                <CopierSupportTicketModal
                    ticket={this.state.ticketHistoryItem}
                    open={this.state.ticketHistoryItemModalOpen}
                    close={this.closeTicketHistoryItemModal}
                    color={this.props.contract.device.model.color}
                />
            </React.Fragment>
        );
    }

    private getInvoiceModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.invoiceModalStatementId)} toggle={() => this.toggleInvoiceModal()} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={() => this.toggleInvoiceModal()}>Számla feltöltése</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputInvoice">Számla</label>
                        <input type="file" className="form-control" id="inputInvoice" onChange={this.handleInvoiceFileChange} />
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`copierModel-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleUploadInvoiceClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Feltöltés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelInvoiceClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleCopierDeviceExpandClick = () => {
        this.setState({
            copierDeviceModelExpanded: !this.state.copierDeviceModelExpanded
        });
    }

    private handleCreateStatementClick = () => {
        const payload: CopierCounterStatementCreatePayload = {
            blackAndWhiteCounterValue: this.state.blackAndWhiteCounter,
            colorCounterValue: this.state.colorCounter
        };

        this.props.createStatement(payload);

        this.resetStatementForm();
    }

    private handleBlackAndWhiteCounterChange = (e: any) => {
        this.setState({
            blackAndWhiteCounter: parseInt(e.target.value.replace(/\D/, ""))
        }, () => this.validateCounterStatement(true));
    }

    private handleColorCounterChange = (e: any) => {
        this.setState({
            colorCounter: parseInt(e.target.value.replace(/\D/, ""))
        }, () => this.validateCounterStatement(true));
    }

    private handleDownloadInvoiceClick = (statementId: number) => {
        window.location.href = `/api/copier-contract/${this.props.contract.id}/statement/${statementId}/invoice`;
    }

    private resetInvoiceForm = () => {
        this.setState({
            invoiceFile: null
        });
    }

    private resetStatementForm = () => {
        this.setState({
            blackAndWhiteCounter: this.props.contract.suggestedNextBlackAndWhiteCounter,
            colorCounter: this.props.contract.suggestedNextColorCounter
        });
    }

    private handleCancelInvoiceClick = () => {
        this.resetInvoiceForm();
        this.setState({
            invoiceModalStatementId: NaN
        });
    }

    private handleUploadInvoiceClick = () => {
        this.props.createInvoice(this.state.invoiceFile!!);
        this.toggleInvoiceModal();
        this.resetInvoiceForm();
    }

    private handleInvoiceFileChange = (e: any) => {
        this.setState({
            invoiceFile: e.target.files[0]
        }, () => this.validate(true));
    }

    private toggleInvoiceModal = (statementId: number = NaN) => {
        this.setState({
            invoiceModalStatementId: statementId,
            validationErrors: []
        });
    }

    private closeTicketHistoryItemModal = () => {
        this.setState({
            ticketHistoryItemModalOpen: false
        });
    }

    private toggleMemoTooltip = () => {
        this.setState({
            memoTooltipOpen: !this.state.memoTooltipOpen
        });
    }

    private toggleSendEmailButtonTooltip = () => {
        this.setState({
            sendEmailButtonTooltipOpen: !this.state.sendEmailButtonTooltipOpen
        });
    }

    private toggleSendStatementButtonTooltip = () => {
        this.setState({
            sendStatementButtonTooltipOpen: !this.state.sendStatementButtonTooltipOpen
        });
    }

    private toggleDeviceSerialNumberTooltip = () => {
        this.setState({
            deviceSerialNumberTooltipOpen: !this.state.deviceSerialNumberTooltipOpen
        });
    }

    private toggleDeviceModelTooltip = () => {
        this.setState({
            deviceModelTooltipOpen: !this.state.deviceModelTooltipOpen
        });
    }

    private toggleContractTypeTooltip = () => {
        this.setState({
            contractTypeTooltipOpen: !this.state.contractTypeTooltipOpen
        });
    }

    private toggleContractNumberTooltip = () => {
        this.setState({
            contractNumberTooltipOpen: !this.state.contractNumberTooltipOpen
        });
    }

    private toggleTicketIndicatorTooltip = () => {
        this.setState({
            ticketIndicatorTooltipOpen: !this.state.ticketIndicatorTooltipOpen
        });
    }

    private handleExpandClick = (value?: boolean) => {
        if (this.props.isExpanded !== value) {
            this.props.expandCollapse();
        }
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.invoiceFile == null) {
            validationErrors.push("A fájl megadása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidCounterStatement = () => {
        return this.validateCounterStatement();
    }

    private validateCounterStatement = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        const previousBlackAndWhiteCounter = this.props.contract.statements.length === 0 ? this.props.contract.blackAndWhiteCounter : this.props.contract.statements[0].blackAndWhiteCounterValue;
        const previousColorCounter = this.props.contract.statements.length === 0 ? this.props.contract.colorCounter : this.props.contract.statements[0].colorCounterValue;

        if (isNaN(this.state.blackAndWhiteCounter) || this.state.blackAndWhiteCounter < previousBlackAndWhiteCounter) {
            validationErrors.push("A fekete számláló értéke nem lehet kisebb a legutóbbinál.");
        }

        if (this.props.contract.device.model.color && (this.state.colorCounter == null || this.state.colorCounter < previousColorCounter!! || isNaN(this.state.colorCounter))) {
            validationErrors.push("A színes számláló értéke nem lehet kisebb a legutóbbinál.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrorsCounterStatement: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default CopierCounterStatementCard;
