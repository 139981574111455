import { ProtectedRestApi } from "./";
import { LanSoftUser, ServiceResult } from "../models";

export class AuthApi extends ProtectedRestApi {
    private static baseUrl = "/api/auth";

    public getLoggedInUser(): Promise<ServiceResult<LanSoftUser>> {
        return this.httpGet(AuthApi.baseUrl) as Promise<ServiceResult<LanSoftUser>>;
    }
}
