import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LanSoftStore from '../store/LanSoftStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { LoadingIndicator, ToastContainer } from "./common";
import { CopierContractType } from "../models";
import { getEnumValues, getFriendlyNameFromCopierContractType, getNumberInputValue } from "../utils";
import { CopierContractCreatePayload, CopierContractUpdatePayload, CopierCounterStatementCreatePayload } from "../payloads";
import CopierContractAdminItem from "./CopierContractAdminItem";
import Datetime from 'react-datetime';
import moment from 'moment';

type ContractsAdminPropsType = LanSoftStore.LanSoftState
    & typeof LanSoftStore.actionCreators
    & RouteComponentProps<{}>;

interface ContractsAdminState {
    result: boolean | null;
    toastOpen: boolean;
    createCopierContractModalOpen: boolean;
    editCopierContractIndex: number;
    closeCopierContractIndex: number;
    deleteCopierContractIndex: number;
    copierContractClientDropdownOpen: boolean;
    copierContractTechnicianDropdownOpen: boolean;
    copierContractDeviceDropdownOpen: boolean;
    copierContractContractTypeDropdownOpen: boolean;
    copierContractMaintenanceIntervalMonthsDropdownOpen: boolean;
    indefiniteEndDate: boolean;
    copierContractCreatePayload: CopierContractCreatePayload;
    copierContractUpdatePayload: CopierContractUpdatePayload;
    validationErrors: string[];
    expandedIndex: number;
    blackAndWhitePrintPriceRaw: string;
    colorPrintPriceRaw: string;
}

class ContractsAdmin extends React.PureComponent<ContractsAdminPropsType, ContractsAdminState> {
    constructor(props: ContractsAdminPropsType) {
        super(props);

        this.state = {
            result: null,
            toastOpen: false,
            createCopierContractModalOpen: false,
            editCopierContractIndex: NaN,
            closeCopierContractIndex: NaN,
            deleteCopierContractIndex: NaN,
            validationErrors: [],
            copierContractClientDropdownOpen: false,
            copierContractTechnicianDropdownOpen: false,
            copierContractDeviceDropdownOpen: false,
            copierContractContractTypeDropdownOpen: false,
            copierContractMaintenanceIntervalMonthsDropdownOpen: false,
            indefiniteEndDate: false,
            copierContractCreatePayload: {
                contractNumber: "",
                clientId: 0,
                technicianId: 0,
                deviceId: 0,
                memo: "",
                contractType: CopierContractType.None,
                blackAndWhiteCounter: 0,
                colorCounter: null,
                maintenanceIntervalMonths: null,
                monthlyLeasePrice: 0,
                includedBlackAndWhitePrints: 0,
                blackAndWhitePrintPrice: 0,
                colorPrintPrice: 0,
                startDate: moment.utc(new Date()).startOf('day').toDate(),
                endDate: null
            },
            copierContractUpdatePayload: {
                contractNumber: "",
                technicianId: 0,
                memo: ""
            },
            expandedIndex: NaN,
            blackAndWhitePrintPriceRaw: "0",
            colorPrintPriceRaw: "0"
        }
    }

    componentWillMount() {
        this.props.getAllCopierContracts();
        this.props.getAllClients();
        this.props.getAllCopierDevices();
        this.props.getAllCopierTicketTypes();
    }

    componentWillReceiveProps(nextProps: ContractsAdminPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <LoadingIndicator show={this.props.loading > 0} />
                <h4 className="mb-3"><FontAwesomeIcon icon={["fas", "file-contract"]} className="lansoft-text-success" /> <span className="lansoft-underline-success">Másolástechnikai szerződések {`(${this.props.copierContracts.length})`}</span></h4>

                <div className="d-flex justify-content-end">
                    <Button onClick={this.handleCreateCopierContractClick} color="success" className="lansoft-success">
                        <FontAwesomeIcon icon={["fas", "plus"]} /> Új másolástechnikai szerződés
                    </Button>
                </div>

                <ul className="list-group mt-3 diagonal-background-flat diagonal-background-lansoft-primary position-relative no-left-border-radius">
                    {this.props.copierContracts.map((contract, index) =>
                        <li className="list-group-item" key={`copier-contract-list-item-${index}`}>
                            <CopierContractAdminItem
                                contract={contract}
                                editContract={() => this.handleEditCopierContractClick(index)}
                                closeContract={() => this.handleCloseCopierContractClick(index)}
                                deleteContract={() => this.handleDeleteCopierContractClick(index)}
                            />
                        </li>
                    )}
                </ul>

                {this.getCreateCopierContractModal()}
                {this.getEditCopierContractModal()}
                {this.getCloseCopierContractModal()}
                {this.getDeleteCopierContractModal()}
                <ToastContainer open={this.state.toastOpen} color={this.state.result ? "success" : "danger"} success={this.state.result === true} />
            </React.Fragment>

        );
    }

    private getCreateCopierContractModal = () => {
        return (
            <Modal isOpen={!this.props.loading && this.state.createCopierContractModalOpen} toggle={this.handleCancelCreateCopierContractClick} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.handleCancelCreateCopierContractClick}>Másolástechnikai szerződés létrehozása</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputNewCopierContractContractNumber">Szerződésszám</label>
                        <input type="text" className="form-control" id="inputNewCopierContractContractNumber" autoComplete="off" placeholder="Szerződésszám" value={this.state.copierContractCreatePayload.contractNumber} onChange={this.handleNewCopierContractContractNumberChange} />
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Ügyfél</label>
                        <Dropdown isOpen={this.state.copierContractClientDropdownOpen} toggle={this.toggleCopierContractClientDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.copierContractCreatePayload.clientId === 0 ? "Válassz" : this.props.clients.find(x => x.id === this.state.copierContractCreatePayload.clientId)!!.name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {this.props.clients.map((client, index) =>
                                    <DropdownItem active={this.state.copierContractCreatePayload.clientId === client.id} onClick={() => this.handleNewCopierContractClientChange(client.id)} key={`new-copier-contract-client-dropdown-item-${index}`}>{client.name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Technikus</label>
                        <Dropdown isOpen={this.state.copierContractTechnicianDropdownOpen} toggle={this.toggleCopierContractTechnicianDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.copierContractCreatePayload.technicianId === 0 ? "Válassz" : this.props.technicians.find(x => x.id === this.state.copierContractCreatePayload.technicianId)!!.name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {this.props.technicians.map((technician, index) =>
                                    <DropdownItem active={this.state.copierContractCreatePayload.technicianId === technician.id} onClick={() => this.handleNewCopierContractTechnicianChange(technician.id)} key={`new-copier-contract-technician-dropdown-item-${index}`}>{technician.name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Gép</label>
                        <Dropdown isOpen={this.state.copierContractDeviceDropdownOpen} toggle={this.toggleCopierContractDeviceDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.copierContractCreatePayload.deviceId === 0 ? "Válassz" : `${this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.serialNumber} (${this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.model.name})`}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {this.props.copierDevices.map((device, index) =>
                                    <DropdownItem active={this.state.copierContractCreatePayload.deviceId === device.id} onClick={() => this.handleNewCopierContractDeviceChange(device.id)} key={`new-copier-contract-device-dropdown-item-${index}`}>{`${device.serialNumber} (${device.model.name})`}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputNewCopierContractMemo">Memo</label>
                        <input type="text" className="form-control" id="inputNewCopierContractMemo" autoComplete="off" placeholder="Memo" value={this.state.copierContractCreatePayload.memo} onChange={this.handleNewCopierContractMemoChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label>Típus</label>
                        <Dropdown isOpen={this.state.copierContractContractTypeDropdownOpen} toggle={this.toggleCopierContractContractTypeDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.copierContractCreatePayload.contractType === CopierContractType.None ? "Válassz" : `${getFriendlyNameFromCopierContractType(this.state.copierContractCreatePayload.contractType)}`}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {getEnumValues(CopierContractType).filter(x => x !== CopierContractType.None).map((contractType, index) =>
                                    <DropdownItem active={this.state.copierContractCreatePayload.contractType === contractType} onClick={() => this.handleNewCopierContractTypeChange(contractType)} key={`new-copier-contract-type-dropdown-item-${index}`}>{getFriendlyNameFromCopierContractType(contractType)}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputNewCopierContractBlackAndWhiteCounter">Fekete-fehér számláló</label>
                        <input type="text" className="form-control" id="inputNewCopierContractBlackAndWhiteCounter" autoComplete="off" placeholder="Fekete-fehér számláló" value={getNumberInputValue(this.state.copierContractCreatePayload.blackAndWhiteCounter)} onChange={this.handleNewCopierContractBlackAndWhiteCounterChange} />
                    </div>

                    {this.state.copierContractCreatePayload.deviceId !== 0 && this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.model.color &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputNewCopierContractColorCounter">Színes számláló</label>
                            <input type="text" className="form-control" id="inputNewCopierContractColorCounter" autoComplete="off" placeholder="Színes számláló" value={getNumberInputValue(this.state.copierContractCreatePayload.colorCounter || 0)} onChange={this.handleNewCopierContractColorCounterChange} />
                        </div>
                    }

                    {this.state.copierContractCreatePayload.contractType === CopierContractType.Monthly &&
                        <div className="form-group mt-3">
                            <label>Megjelenés gyakorisága</label>
                            <Dropdown isOpen={this.state.copierContractMaintenanceIntervalMonthsDropdownOpen} toggle={this.toggleCopierContractMaintenanceIntervalMonthsDropdown}>
                                <DropdownToggle className="w-100 lansoft-success lansoft-success" caret>
                                    {this.state.copierContractCreatePayload.maintenanceIntervalMonths == null || this.state.copierContractCreatePayload.maintenanceIntervalMonths === 0 ? "Válassz" : `${this.state.copierContractCreatePayload.maintenanceIntervalMonths} hónap (G/${this.state.copierContractCreatePayload.maintenanceIntervalMonths})`}
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {Array.from({ length: 12 }, (_, i) => i + 1).map((months, index) =>
                                        <DropdownItem active={this.state.copierContractCreatePayload.maintenanceIntervalMonths === months} onClick={() => this.handleNewCopierContractMaintenanceIntervalMonthsChange(months)} key={`new-copier-contract-maintenance-interval-months-dropdown-item-${index}`}>{`${months} hónap (G/${months})`}</DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    }

                    {(this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.Monthly) &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputNewCopierContractMonthlyLeasePrice">Bérleti díj</label>
                            <input type="text" className="form-control" id="inputNewCopierContractMonthlyLeasePrice" autoComplete="off" placeholder="Bérleti díj" value={getNumberInputValue(this.state.copierContractCreatePayload.monthlyLeasePrice)} onChange={this.handleNewCopierContractMonthlyLeasePriceChange} />
                        </div>
                    }

                    {this.state.copierContractCreatePayload.contractType === CopierContractType.Lease &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputNewCopierContractIncludedBlackAndWhitePrints">Benne foglalt fekete-fehér nyomat</label>
                            <input type="text" className="form-control" id="inputNewCopierContractIncludedBlackAndWhitePrints" autoComplete="off" placeholder="Benne foglalt fekete-fehér nyomat" value={getNumberInputValue(this.state.copierContractCreatePayload.includedBlackAndWhitePrints)} onChange={this.handleNewCopierContractIncludedBlackAndWhitePrintsChange} />
                        </div>
                    }

                    {(this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.AllRoundOperation) &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputNewCopierContractBlackAndWhitePrintPrice">Fekete-fehér nyomatdíj</label>
                            <input type="text" className="form-control" id="inputNewCopierContractBlackAndWhitePrintPrice" autoComplete="off" placeholder="Fekete-fehér nyomatdíj" value={this.state.blackAndWhitePrintPriceRaw} onChange={this.handleNewCopierContractBlackAndWhitePrintPriceChange} />
                        </div>
                    }

                    {this.state.copierContractCreatePayload.deviceId !== 0 && this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.model.color && (this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.AllRoundOperation) &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputNewCopierContractColorPrintPrice">Színes nyomatdíj</label>
                            <input type="text" className="form-control" id="inputNewCopierContractColorPrintPrice" autoComplete="off" placeholder="Színes nyomatdíj" value={this.state.colorPrintPriceRaw} onChange={this.handleNewCopierContractColorPrintPriceChange} />
                        </div>
                    }

                    <div className="form-group mt-3">
                        <label htmlFor="inputStartDate">Kezdő dátum</label>
                        <Datetime
                            closeOnClickOutside={true}
                            closeOnSelect={true}
                            dateFormat="YYYY.MM.DD"
                            timeFormat={false}
                            utc={true}
                            value={this.state.copierContractCreatePayload.startDate}
                            onChange={this.handleNewCopierContractStartDateChange} />
                    </div>

                    <div className="form-check mt-3">
                        <input type="checkbox" className="form-check-input" id="inputIndefiniteEndDate" checked={this.state.indefiniteEndDate} onChange={this.handleIndefiniteEndDateChange} />
                        <label className="form-check-label" htmlFor="inputIndefiniteEndDate">Határozatlan idejű szerződés</label>
                    </div>

                    {!this.state.indefiniteEndDate &&
                        <div className="form-group mt-3">
                            <label htmlFor="inputEndDate">Lejárat</label>
                            <Datetime
                                closeOnClickOutside={true}
                                closeOnSelect={true}
                                dateFormat="YYYY.MM.DD"
                                timeFormat={false}
                                utc={true}
                                value={this.state.copierContractCreatePayload.endDate || new Date()}
                                onChange={this.handleNewCopierContractEndDateChange} />
                        </div>
                    }

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`technician-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveNewCopierContractClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValidCreate()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelCreateCopierContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getEditCopierContractModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editCopierContractIndex) && !this.props.loading && this.props.copierContracts.length > this.state.editCopierContractIndex} toggle={this.handleCancelEditCopierContractClick} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeEditCopierContractModal}>Másolástechnikai szerződés módosítása</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputEditCopierContractContractNumber">Szerződésszám</label>
                        <input type="text" className="form-control" id="inputEditCopierContractContractNumber" autoComplete="off" placeholder="Szerződésszám" value={this.state.copierContractUpdatePayload.contractNumber} onChange={this.handleEditCopierContractContractNumberChange} />
                    </div>

                    <div className="form-group mt-3 mb-1">
                        <label>Technikus</label>
                        <Dropdown isOpen={this.state.copierContractTechnicianDropdownOpen} toggle={this.toggleCopierContractTechnicianDropdown}>
                            <DropdownToggle className="w-100 lansoft-success" caret>
                                {this.state.copierContractUpdatePayload.technicianId === 0 ? "Válassz" : this.props.technicians.find(x => x.id === this.state.copierContractUpdatePayload.technicianId)!!.name}
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {this.props.technicians.map((technician, index) =>
                                    <DropdownItem active={this.state.copierContractUpdatePayload.technicianId === technician.id} onClick={() => this.handleEditCopierContractTechnicianChange(technician.id)} key={`edit-copier-contract-technician-dropdown-item-${index}`}>{technician.name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputEditCopierContractMemo">Memo</label>
                        <input type="text" className="form-control" id="inputEditCopierContractMemo" autoComplete="off" placeholder="Memo" value={this.state.copierContractUpdatePayload.memo} onChange={this.handleEditCopierContractMemoChange} />
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger lansoft-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`technician-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleUpdateCopierContractClick} type="button" className="btn btn-primary lansoft-primary" disabled={!this.isValidEdit()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelEditCopierContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getCloseCopierContractModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.closeCopierContractIndex) && !this.props.loading} toggle={this.closeCloseCopierContractModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeCloseCopierContractModal}>Másolástechnikai szerződés lezárása</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan le szeretnéd zárni az alábbi másolástechnikai szerződést?
                        </p>
                        <p>{!isNaN(this.state.closeCopierContractIndex) && <strong>{this.props.copierContracts[this.state.closeCopierContractIndex].contractNumber}</strong>}</p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmCloseContractClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Lezárás
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelCloseCopierContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getDeleteCopierContractModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteCopierContractIndex) && !this.props.loading} toggle={this.closeDeleteCopierContractModal} className="modal-dialog-centered">
                <ModalHeader className="lansoft-success" toggle={this.closeDeleteCopierContractModal}>Másolástechnikai szerződés törlése</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan törölni szeretnéd az alábbi másolástechnikai szerződést?
                        </p>
                        <p>{!isNaN(this.state.deleteCopierContractIndex) && <strong>{this.props.copierContracts[this.state.deleteCopierContractIndex].contractNumber}</strong>}</p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmDeleteCopierContractClick} type="button" className="btn btn-danger lansoft-danger">
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteCopierContractClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleNewCopierContractClientChange = (id: number) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                clientId: id
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewCopierContractTechnicianChange = (id: number) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                technicianId: id
            }
        }, () => { this.validateCreate(true); });
    }

    private handleEditCopierContractTechnicianChange = (id: number) => {
        this.setState({
            copierContractUpdatePayload: {
                ...this.state.copierContractUpdatePayload,
                technicianId: id
            }
        }, () => { this.validateEdit(true); });
    }

    private handleNewCopierContractDeviceChange = (id: number) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                deviceId: id
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewCopierContractTypeChange = (contractType: CopierContractType) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                contractType: contractType
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewCopierContractBlackAndWhiteCounterChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    blackAndWhiteCounter: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractColorCounterChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    colorCounter: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractMaintenanceIntervalMonthsChange = (months: number) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                maintenanceIntervalMonths: months
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewCopierContractMonthlyLeasePriceChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    monthlyLeasePrice: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractIncludedBlackAndWhitePrintsChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    includedBlackAndWhitePrints: parseInt(e.target.value.replace(/\D/, ""))
                }
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractBlackAndWhitePrintPriceChange = (e: any) => {
        const raw = e.target.value;
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    blackAndWhitePrintPrice: parseFloat(e.target.value)
                },
                blackAndWhitePrintPriceRaw: raw
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractColorPrintPriceChange = (e: any) => {
        const raw = e.target.value;
        if (!isNaN(e.target.value)) {
            this.setState({
                copierContractCreatePayload: {
                    ...this.state.copierContractCreatePayload,
                    colorPrintPrice: parseFloat(e.target.value)
                },
                colorPrintPriceRaw: raw
            }, () => { this.validateCreate(true); });
        }
    }

    private handleNewCopierContractStartDateChange = (date: any) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                startDate: moment.utc(date).startOf('day').toDate()
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewCopierContractEndDateChange = (date: any) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                endDate: moment.utc(date).startOf('day').toDate()
            }
        }, () => { this.validateCreate(true); });
    }

    private handleIndefiniteEndDateChange = (e: any) => {
        this.setState({
            indefiniteEndDate: e.target.checked
        }, () => { this.validateCreate(true); });
    }

    private toggleCopierContractClientDropdown = () => {
        this.setState({
            copierContractClientDropdownOpen: !this.state.copierContractClientDropdownOpen
        });
    }

    private toggleCopierContractTechnicianDropdown = () => {
        this.setState({
            copierContractTechnicianDropdownOpen: !this.state.copierContractTechnicianDropdownOpen
        });
    }

    private toggleCopierContractDeviceDropdown = () => {
        this.setState({
            copierContractDeviceDropdownOpen: !this.state.copierContractDeviceDropdownOpen
        });
    }

    private toggleCopierContractContractTypeDropdown = () => {
        this.setState({
            copierContractContractTypeDropdownOpen: !this.state.copierContractContractTypeDropdownOpen
        });
    }

    private toggleCopierContractMaintenanceIntervalMonthsDropdown = () => {
        this.setState({
            copierContractMaintenanceIntervalMonthsDropdownOpen: !this.state.copierContractMaintenanceIntervalMonthsDropdownOpen
        });
    }

    private resetCreateCopierContractForm = () => {
        this.setState({
            createCopierContractModalOpen: false,
            copierContractCreatePayload: {
                contractNumber: "",
                clientId: 0,
                technicianId: 0,
                deviceId: 0,
                memo: "",
                contractType: CopierContractType.None,
                blackAndWhiteCounter: 0,
                colorCounter: null,
                maintenanceIntervalMonths: null,
                monthlyLeasePrice: 0,
                includedBlackAndWhitePrints: 0,
                blackAndWhitePrintPrice: 0,
                colorPrintPrice: 0,
                startDate: moment.utc(new Date()).startOf('day').toDate(),
                endDate: null
            },
            indefiniteEndDate: false,
            validationErrors: []
        });
    }

    private resetEditCopierContractForm = () => {
        this.setState({
            copierContractUpdatePayload: {
                contractNumber: "",
                technicianId: 0,
                memo: "",
            },
            editCopierContractIndex: NaN,
            validationErrors: []
        }, () => { this.validateEdit(true); });
    }

    private handleCancelCreateCopierContractClick = () => {
        this.resetCreateCopierContractForm();
        this.setState({
            createCopierContractModalOpen: false
        });
    }

    private handleCancelEditCopierContractClick = () => {
        this.resetEditCopierContractForm();
        this.setState({
            editCopierContractIndex: NaN
        });
    }

    private handleCancelCloseCopierContractClick = () => {
        this.setState({
            closeCopierContractIndex: NaN
        });
    }

    private handleCancelDeleteCopierContractClick = () => {
        this.setState({
            deleteCopierContractIndex: NaN
        });
    }

    private handleSaveNewCopierContractClick = () => {
        this.props.createCopierContract(this.state.copierContractCreatePayload);
        this.resetCreateCopierContractForm();
    }

    private handleUpdateCopierContractClick = () => {
        const copierContract = this.props.copierContracts[this.state.editCopierContractIndex];
        this.props.updateCopierContract(copierContract.id, this.state.copierContractUpdatePayload);
        this.resetEditCopierContractForm();
    }

    private handleConfirmCloseContractClick = () => {
        const copierContract = this.props.copierContracts[this.state.closeCopierContractIndex];
        this.props.closeCopierContract(copierContract.id);
        this.setState({
            closeCopierContractIndex: NaN
        });
    }

    private handleConfirmDeleteCopierContractClick = () => {
        const copierContract = this.props.copierContracts[this.state.deleteCopierContractIndex];
        this.props.deleteCopierContract(copierContract.id);
        this.setState({
            deleteCopierContractIndex: NaN
        });
    }

    private handleNewCopierContractContractNumberChange = (e: any) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                contractNumber: e.target.value
            }
        }, () => { this.validateCreate(true); });
    }

    private handleNewCopierContractMemoChange = (e: any) => {
        this.setState({
            copierContractCreatePayload: {
                ...this.state.copierContractCreatePayload,
                memo: e.target.value
            }
        }, () => { this.validateCreate(true); });
    }

    private handleEditCopierContractContractNumberChange = (e: any) => {
        this.setState({
            copierContractUpdatePayload: {
                ...this.state.copierContractUpdatePayload,
                contractNumber: e.target.value
            }
        }, () => { this.validateEdit(true); });
    }

    private handleEditCopierContractMemoChange = (e: any) => {
        this.setState({
            copierContractUpdatePayload: {
                ...this.state.copierContractUpdatePayload,
                memo: e.target.value
            }
        }, () => { this.validateEdit(true); });
    }

    private closeEditCopierContractModal = () => {
        this.setState({
            editCopierContractIndex: NaN
        });
    }

    private closeCloseCopierContractModal = () => {
        this.setState({
            closeCopierContractIndex: NaN
        });
    }

    private closeDeleteCopierContractModal = () => {
        this.setState({
            deleteCopierContractIndex: NaN
        });
    }

    private handleCreateCopierContractClick = () => {
        this.resetCreateCopierContractForm();

        this.setState({
            createCopierContractModalOpen: true
        });
    }

    private handleEditCopierContractClick = (index: number) => {
        this.resetEditCopierContractForm();

        const contract = this.props.copierContracts[index];

        this.setState({
            editCopierContractIndex: index,
            copierContractUpdatePayload: {
                contractNumber: contract.contractNumber,
                technicianId: contract.technician.id,
                memo: contract.memo
            }
        });
    }

    private handleCloseCopierContractClick = (index: number) => {
        this.setState({
            closeCopierContractIndex: index
        });
    }

    private handleDeleteCopierContractClick = (index: number) => {
        this.setState({
            deleteCopierContractIndex: index
        });
    }

    private isValidCreate = () => {
        return this.validateCreate();
    }

    private validateCreate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.copierContractCreatePayload.contractNumber.length === 0) {
            validationErrors.push("A szerződésszám megadása kötelező.");
        }

        if (this.state.copierContractCreatePayload.clientId === 0) {
            validationErrors.push("Az ügyfél kiválasztása kötelező.");
        }

        if (this.state.copierContractCreatePayload.technicianId === 0) {
            validationErrors.push("A technikus kiválasztása kötelező.");
        }

        if (this.state.copierContractCreatePayload.deviceId === 0) {
            validationErrors.push("A gép kiválasztása kötelező.");
        }

        if (this.state.copierContractCreatePayload.contractType === CopierContractType.None) {
            validationErrors.push("A típus kiválasztása kötelező.");
        }

        if (this.state.copierContractCreatePayload.blackAndWhiteCounter === 0) {
            validationErrors.push("A fekete-fehér számláló kitöltése kötelező.");
        }

        if (this.state.copierContractCreatePayload.deviceId !== 0 && this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.model.color && this.state.copierContractCreatePayload.colorCounter == null) {
            validationErrors.push("A színes számláló kitöltése kötelező.");
        }

        if (this.state.copierContractCreatePayload.contractType === CopierContractType.Monthly && this.state.copierContractCreatePayload.maintenanceIntervalMonths == null) {
            validationErrors.push("A megjelenés gyakoriságának kiválasztása kötelező.");
        }

        if (this.state.copierContractCreatePayload.contractType === CopierContractType.Monthly && this.state.copierContractCreatePayload.maintenanceIntervalMonths == null) {
            validationErrors.push("A megjelenés gyakoriságának kiválasztása kötelező.");
        }

        if ((this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.Monthly) && this.state.copierContractCreatePayload.monthlyLeasePrice === 0) {
            validationErrors.push("A bérleti díj kitöltése kötelező.");
        }

        if (this.state.copierContractCreatePayload.contractType === CopierContractType.Lease && isNaN(this.state.copierContractCreatePayload.includedBlackAndWhitePrints)) {
            validationErrors.push("A benne foglalt fekete nyomat kitöltése kötelező.");
        }

        if ((this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.AllRoundOperation) && isNaN(this.state.copierContractCreatePayload.blackAndWhitePrintPrice)) {
            validationErrors.push("A fekete-fehér nyomatdíj kitöltése kötelező.");
        }

        if (this.state.copierContractCreatePayload.deviceId !== 0 && this.props.copierDevices.find(x => x.id === this.state.copierContractCreatePayload.deviceId)!!.model.color && (this.state.copierContractCreatePayload.contractType === CopierContractType.Lease || this.state.copierContractCreatePayload.contractType === CopierContractType.AllRoundOperation) && isNaN(this.state.copierContractCreatePayload.colorPrintPrice)) {
            validationErrors.push("A színes nyomatdíj kitöltése kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidEdit = () => {
        return this.validateEdit();
    }

    private validateEdit = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.copierContractUpdatePayload.contractNumber.length === 0) {
            validationErrors.push("A szerződésszám megadása kötelező.");
        }

        if (this.state.copierContractUpdatePayload.technicianId === 0) {
            validationErrors.push("A technikus kiválasztása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.lanSoft,
    LanSoftStore.actionCreators
)(ContractsAdmin as any);
