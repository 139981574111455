import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Client } from "../models";
import { Button } from "reactstrap";

interface ClientItemProps {
    className?: string;
    client: Client;
    editClient?: () => void;
    deleteClient?: () => void;
}

export const ClientItem = (props: ClientItemProps) => (
    <div className={`d-flex align-items-center justify-content-between flex-same-width-container ${(props.className != null ? props.className : "")}`}>
        <div>
            <div className="h5 mb-0">{props.client.name}</div>
        </div>
        <div>
            <div className="h5 mb-0">{props.client.address}</div>
        </div>
        <div>
            <div className="d-flex justify-content-end">
                {props.editClient != null && <Button color="primary" className="lansoft-primary" onClick={props.editClient}><FontAwesomeIcon icon={["fas", "pencil-alt"]} /></Button>}
                {props.deleteClient != null && <Button color="danger" className="ml-3 lansoft-danger" onClick={props.deleteClient}><FontAwesomeIcon icon={["fas", "trash-alt"]} /></Button>}
            </div>
        </div>
    </div>
);

export default ClientItem;
