import * as React from 'react';
import { MenuItem } from "../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";

interface NavMenuItemProps {
    menuItem: MenuItem;
    isLast?: boolean;
    className?: string;
    backgroundColor?: string;
}

export const NavMenuItem = (props: NavMenuItemProps) => (
    <React.Fragment>
        <div className={props.className == null ? "" : props.className} style={{ backgroundColor: props.backgroundColor == null ? "transparent" : props.backgroundColor }}>
            <Link className="nav-link nav-menu-item-link d-flex flex-column align-items-center text-white" to={props.menuItem.target || ""}>
                {props.menuItem.customIcon == null && <FontAwesomeIcon icon={["fas", props.menuItem.icon]} className="fa-fw nav-menu-item-icon" />}
                {props.menuItem.customIcon != null && <img src={props.menuItem.customIcon} className="nav-menu-custom-icon" />}
                <span>{props.menuItem.text}</span>
            </Link>
        </div>
        {!props.isLast && <span className="navbar-vertical-divider" />}
    </React.Fragment>
);

export default NavMenuItem;
