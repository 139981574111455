import * as React from 'react';

interface LoadingIndicatorProps {
    show: boolean;
}

export const LoadingIndicator = ({ show }: LoadingIndicatorProps) => (
    <div className={`loader ${show ? "show" : ""}`}>
        <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);
