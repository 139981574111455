import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardTitle, Dropdown, DropdownToggle, Tooltip } from 'reactstrap';
import { CopierScheduledTicketSolvePayload } from "../payloads";
import { CopierContract, CopierContractType, CopierCounterStatement, CopierSupportTicket, CopierTicketKind, CopierTicketState, HistoryItemType } from "../models";
import { getFriendlyNameFromCopierContractType, getIndicatorClassFromHistoryItemType, getIndicatorClassFromTicket, getTooltipTextFromHistoryItemType } from "../utils/EnumUtils";
import moment from 'moment';
import { getNumberInputValue, MAX_DEVICE_NAME_LENGTH_DISPLAY } from '../utils';

interface ScheduledCopierTicketCardPropsType {
    isExpanded: boolean;
    ticket: CopierSupportTicket;
    contract: CopierContract;
    expandCollapse: () => void;
    handleSolveClick: (payload: CopierScheduledTicketSolvePayload) => void;
}

interface ScheduledCopierTicketCardState {
    blackAndWhiteCounter: number;
    colorCounter: number | null;
    signedByClient: boolean;
    note: string;
    validationErrors: string[];
    ticketIndicatorTooltipOpen: boolean;
    contractNumberTooltipOpen: boolean;
    contractTypeTooltipOpen: boolean;
    deviceModelTooltipOpen: boolean;
    deviceSerialNumberTooltipOpen: boolean;
    memoTooltipOpen: boolean;
    historyItemIndicatorTooltipOpen: number;
    copierDeviceModelExpanded: boolean;
}

export class ScheduledCopierTicketCard extends React.PureComponent<ScheduledCopierTicketCardPropsType, ScheduledCopierTicketCardState> {
    constructor(props: ScheduledCopierTicketCardPropsType) {
        super(props);

        this.state = {
            blackAndWhiteCounter: 0,
            colorCounter: null,
            signedByClient: false,
            note: "",
            validationErrors: [],
            ticketIndicatorTooltipOpen: false,
            contractNumberTooltipOpen: false,
            contractTypeTooltipOpen: false,
            deviceModelTooltipOpen: false,
            deviceSerialNumberTooltipOpen: false,
            memoTooltipOpen: false,
            historyItemIndicatorTooltipOpen: NaN,
            copierDeviceModelExpanded: false
        }
    }

    public render() {
        const tickets = this.props.contract.tickets.filter(x => x.kind !== CopierTicketKind.Scheduled || x.isSolved).map(ticket => {
            return {
                date: new Date(ticket.createdAt),
                ticket: ticket,
                statement: null
            };
        });

        const statements = this.props.contract.statements.map(statement => {
            return {
                date: new Date(statement.createdAt),
                ticket: null,
                statement: statement
            }
        });

        let history: { date: Date, ticket: CopierSupportTicket | null, statement: CopierCounterStatement | null }[] = [];
        history = history.concat(tickets).concat(statements);
        history.sort((a, b) => b.date.valueOf() - a.date.valueOf());

        const isSolved = this.props.ticket.isSolved;

        const deviceNameLong = this.props.contract.device.model.name.length > MAX_DEVICE_NAME_LENGTH_DISPLAY;

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-0">
                            <div className="h4 d-flex justify-content-between border-bottom lansoft-border-success pb-5 mt-4 flex-same-width-container">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <div id={`scheduled-scheduled-ticket-indicator-${this.props.ticket.id}`} className={`mr-1 ticket-indicator bg-${getIndicatorClassFromTicket(this.props.ticket)}`}></div>
                                        <Tooltip placement="top" isOpen={this.state.ticketIndicatorTooltipOpen} target={`scheduled-scheduled-ticket-indicator-${this.props.ticket.id}`} toggle={this.toggleTicketIndicatorTooltip}>
                                            {getTooltipTextFromHistoryItemType(this.props.ticket.isSolved ? (this.props.ticket.state === CopierTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket)}
                                        </Tooltip>
                                        <span id={`scheduled-contract-number-${this.props.ticket.id}`}>{this.props.contract.contractNumber}</span>
                                        <Tooltip placement="top" isOpen={this.state.contractNumberTooltipOpen} target={`scheduled-contract-number-${this.props.ticket.id}`} toggle={this.toggleContractNumberTooltip}>
                                            Szerződésszám
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div id={`scheduled-contract-type-${this.props.ticket.id}`} className="d-inline-block">
                                            {getFriendlyNameFromCopierContractType(this.props.contract.contractType)}{this.props.contract.contractType === CopierContractType.Monthly && `/${this.props.contract.maintenanceIntervalMonths}`}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.contractTypeTooltipOpen} target={`scheduled-contract-type-${this.props.ticket.id}`} toggle={this.toggleContractTypeTooltip}>
                                            Szerződés típusa
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div onClick={this.handleCopierDeviceExpandClick} className="cursor-pointer">
                                        <div id={`scheduled-device-model-${this.props.ticket.id}`} className="d-inline-block">
                                            {!deviceNameLong && <React.Fragment>{this.props.contract.device.model.name}</React.Fragment>}
                                            {deviceNameLong && <React.Fragment>
                                                {!this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.substring(0, MAX_DEVICE_NAME_LENGTH_DISPLAY - 2) + "..."}
                                                </React.Fragment>}
                                                {this.state.copierDeviceModelExpanded && <React.Fragment>
                                                    {this.props.contract.device.model.name.split(" ").map((fragment, index) =>
                                                        <span className="nowrap d-block" key={`device-model-name-fragment-${index}`}>{fragment}</span>)
                                                    }
                                                </React.Fragment>}
                                            </React.Fragment>}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceModelTooltipOpen} target={`scheduled-device-model-${this.props.ticket.id}`} toggle={this.toggleDeviceModelTooltip}>
                                            Gép típusa
                                    </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div id={`scheduled-device-serial-number-${this.props.ticket.id}`} className="d-inline-block">
                                            {this.props.contract.device.serialNumber}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.deviceSerialNumberTooltipOpen} target={`scheduled-device-serial-number-${this.props.ticket.id}`} toggle={this.toggleDeviceSerialNumberTooltip}>
                                            Gép gyári száma
                                    </Tooltip>
                                    </div>
                                </div>
                                <div className="flex-half-width-column">
                                    <div className="d-flex justify-content-end">
                                        <div id={`scheduled-memo-${this.props.ticket.id}`}>
                                            {this.props.contract.memo}
                                        </div>
                                        <Tooltip placement="top" isOpen={this.state.memoTooltipOpen} target={`scheduled-memo-${this.props.ticket.id}`} toggle={this.toggleMemoTooltip}>
                                            Memo
                                        </Tooltip>
                                        <div className="cursor-pointer ml-3" onClick={() => this.handleExpandClick()}><FontAwesomeIcon icon={["fas", this.props.isExpanded ? "chevron-up" : "chevron-down"]} className="mr-1" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 d-flex justify-content-between flex-same-width-container">
                                <div>
                                    <div>
                                        <Button className={isSolved ? "lansoft-success" : "lansoft-danger"} disabled={isSolved} onClick={() => this.handleExpandClick(true)}>
                                            {isSolved ? moment(this.props.ticket.solvedAt).format("YYYY.MM.DD") : "Megoldás"}
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex justify-content-start">
                                        <div>Bérleti díj: {this.props.contract.monthlyLeasePrice} Ft + ÁFA</div>
                                    </div>
                                    <div>Fekete nyomatdíj: {this.props.contract.blackAndWhitePrintPrice} Ft + ÁFA</div>
                                    {this.props.contract.device.model.color && <div>Színes nyomatdíj: {this.props.contract.colorPrintPrice} Ft + ÁFA</div>}
                                </div>
                                <div>
                                    <div>Bérleti díjban foglalt nyomat: {this.props.contract.includedBlackAndWhitePrints}</div>
                                </div>
                                <div className="h5">Technikus: {this.props.contract.technician.name}</div>
                                <div className="flex-half-width-column"></div>
                            </div>
                        </CardTitle>

                        {this.props.isExpanded && <div className="mt-5 pt-5 border-top lansoft-border-success pb-5">
                            <div className="d-flex justify-content-between mt-3 flex-same-width-container">
                                <div className="form-group">
                                    <label>Megoldás</label>
                                    <Dropdown disabled>
                                        <DropdownToggle disabled>
                                            Szerződés szerinti karbantartás
                                        </DropdownToggle>
                                    </Dropdown>
                                </div>
                                <div className="flex-double-width-column">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="form-group">
                                            <label htmlFor="inputNewStatementBlackAndWhiteCounter">Fekete számláló</label>
                                            <input type="text" className="form-control numeric-input" id="inputNewStatementBlackAndWhiteCounter" autoComplete="off" placeholder="Fekete számláló" value={getNumberInputValue(this.state.blackAndWhiteCounter)} onChange={this.handleBlackAndWhiteCounterChange} />
                                        </div>
                                        <div className={`form-group ml-5 pr-5 ${this.props.contract.device.model.color ? "" : "invisible"}`}>
                                            <label htmlFor="inputNewStatementColorCounter">Színes számláló</label>
                                            <input type="text" className="form-control numeric-input" id="inputNewStatementColorCounter" autoComplete="off" placeholder="Színes számláló" value={getNumberInputValue(this.state.colorCounter || 0)} onChange={this.handleColorCounterChange} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="inputNote">Munkalapon megjegyzés</label>
                                        <textarea maxLength={250} rows={4} className="form-control message-textarea border" id="inputNote" autoComplete="off" placeholder="Megjegyzés" value={this.state.note} onChange={this.handleNoteChange} />
                                    </div>
                                    <div className="form-check mt-3">
                                        <input type="checkbox" className="form-check-input" id="inputSignedByClient" checked={this.state.signedByClient} onChange={this.handleSignedByClientChange} />
                                        <label className="form-check-label" htmlFor="inputSignedByClient">Ügyfél által aláírva</label>
                                        <div className="mt-4 d-flex justify-content-end">
                                            <Button color="primary" className="lansoft-primary" onClick={this.handleSolveClick} disabled={!this.isValid()}>Elküld</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-half-width-column"></div>
                            </div>
                        </div>}

                        {this.props.isExpanded && <div className="pt-5 border-top lansoft-border-success">
                            <div className="h5">Előzmények</div>
                            {history.length === 0 && <div>Nem található elem.</div>}
                            {history.length > 0 &&
                                <React.Fragment>
                                    <table className="table history-table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Dátum</th>
                                                <th scope="col">Fekete sz.</th>
                                                {this.props.contract.device.model.color && <th scope="col">Színes sz.</th>}
                                                <th scope="col">Ügyfél kérése</th>
                                                <th scope="col">Megoldás</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {history.map((historyItem, index) => {
                                                const blackCounter = historyItem.statement == null ? historyItem.ticket!!.blackAndWhiteCounterValue : historyItem.statement.blackAndWhiteCounterValue;
                                                const colorCounter = historyItem.statement == null ? historyItem.ticket!!.colorCounterValue : historyItem.statement.colorCounterValue;
                                                const messageToTechnician = historyItem.ticket != null ? historyItem.ticket!!.messageToTechnician : "Számláló";
                                                const messageToClient = historyItem.ticket != null ? historyItem.ticket!!.messageToClient : "";

                                                return (
                                                    <tr key={`contract-history-row-${index}`}>
                                                        <td>
                                                            <div id={`scheduled-contract-history-item-indicator-${index}`} className={`contract-history-item-indicator bg-${getIndicatorClassFromHistoryItemType(historyItem.statement != null ? HistoryItemType.CounterStatement : (historyItem.ticket!!.isSolved ? (historyItem.ticket!!.state === CopierTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket))}`}></div>
                                                            <Tooltip placement="top" isOpen={this.state.historyItemIndicatorTooltipOpen === index} target={`scheduled-contract-history-item-indicator-${index}`} toggle={() => this.toggleHistoryItemIndicatorTooltipOpen(index)}>
                                                                {getTooltipTextFromHistoryItemType(historyItem.ticket == null ? HistoryItemType.CounterStatement : (historyItem.ticket.isSolved ? (historyItem.ticket.state === CopierTicketState.Solved ? HistoryItemType.SolvedTicket : HistoryItemType.SuspendedTicket) : HistoryItemType.OpenTicket))}
                                                            </Tooltip>
                                                        </td>
                                                        <td>{moment(historyItem.date).format("YYYY.MM.DD HH:mm")}</td>
                                                        <td>{blackCounter}</td>
                                                        {this.props.contract.device.model.color && <td>{colorCounter}</td>}
                                                        <td>{messageToTechnician}</td>
                                                        <td>{messageToClient}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div>
                                        <Button color="primary" className="lansoft-primary">Mentés Excelbe</Button>
                                    </div>
                                </React.Fragment>
                            }
                        </div>}
                    </CardBody>
                </Card>
            </React.Fragment>

        );
    }

    private handleCopierDeviceExpandClick = () => {
        this.setState({
            copierDeviceModelExpanded: !this.state.copierDeviceModelExpanded
        });
    }

    private handleSignedByClientChange = (e: any) => {
        this.setState({
            signedByClient: e.target.checked
        }, () => this.validate(true));
    }

    private handleBlackAndWhiteCounterChange = (e: any) => {
        this.setState({
            blackAndWhiteCounter: parseInt(e.target.value.replace(/\D/, ""))
        });
    }

    private handleColorCounterChange = (e: any) => {
        this.setState({
            colorCounter: parseInt(e.target.value.replace(/\D/, ""))
        });
    }

    private handleNoteChange = (e: any) => {
        this.setState({
            note: e.target.value
        });
    }

    private handleExpandClick = (value?: boolean) => {
        if (value == null || value != this.props.isExpanded) {
            this.props.expandCollapse();
        }
    }

    private handleSolveClick = () => {
        const payload = {
            blackAndWhiteCounter: this.state.blackAndWhiteCounter,
            colorCounter: this.state.colorCounter,
            messageToClient: this.state.note
        };

        this.props.handleSolveClick(payload);
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (!this.state.signedByClient) {
            validationErrors.push("Csak ügyfél által aláírt karbantartás küldhető be.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private toggleHistoryItemIndicatorTooltipOpen = (index: number) => {
        this.setState({
            historyItemIndicatorTooltipOpen: this.state.historyItemIndicatorTooltipOpen === index ? NaN : index
        });
    }

    private toggleMemoTooltip = () => {
        this.setState({
            memoTooltipOpen: !this.state.memoTooltipOpen
        });
    }

    private toggleDeviceSerialNumberTooltip = () => {
        this.setState({
            deviceSerialNumberTooltipOpen: !this.state.deviceSerialNumberTooltipOpen
        });
    }

    private toggleDeviceModelTooltip = () => {
        this.setState({
            deviceModelTooltipOpen: !this.state.deviceModelTooltipOpen
        });
    }

    private toggleContractTypeTooltip = () => {
        this.setState({
            contractTypeTooltipOpen: !this.state.contractTypeTooltipOpen
        });
    }

    private toggleContractNumberTooltip = () => {
        this.setState({
            contractNumberTooltipOpen: !this.state.contractNumberTooltipOpen
        });
    }

    private toggleTicketIndicatorTooltip = () => {
        this.setState({
            ticketIndicatorTooltipOpen: !this.state.ticketIndicatorTooltipOpen
        });
    }
}

export default ScheduledCopierTicketCard;
